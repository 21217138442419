import {
  Button,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import RadialBarGraph from "./graphs/RadialBarGraph";
import TrainingBarGraph from "./graphs/TrainingBarGraph";
import { GET_TRAINING_SUMMARY } from "../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import logo from "../assets/SolverEDGE.svg";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { format } from "date-fns";

const TrainingReport = ({ openFn, student_id, training_module, name, totalTraining, totalAssessment }) => {
  const [open, setOpen] = useState(true);
  const [result, setResult] = useState({});
  const score2 = Math.floor(Math.random() * 31);
  const data1 = [
    { name: 'L1', value: (100 * score2)/30, score: score2, max: 30 }
  ];

  const score1 = Math.floor(Math.random() * (31 - score2));
  const data = [
    { name: 'L1', value: (100 * score1)/(30 - score2), score: score1, max: 30 - score2 }
  ];

  const score7 = Math.floor(Math.random() * 11 + 20);
  const data7 = [
    { name: 'L1', value: (100 * score7)/30, score: score7, max: 30 }
  ];

  const data2 = [
    { name: "Average", s1: Math.floor(Math.random() * 1200001) + 2400000, s2: Math.floor(Math.random() * 1200001) + 1200000},
    { name: "1st", s1: Math.floor(Math.random() * 1200001) + 2400000, s2: Math.floor(Math.random() * 1200001) + 1200000},
  ];

  const times = [
    { name: "Average", time: Math.floor(Math.random() * 1200001) + 600000 },
    { name: "1st", time: Math.floor(Math.random() * 1200001) + 600000 },
  ]

  const circleSize = 230;

  const [getTrainingSummary, { reportLoading, reportError, reportData }] = useLazyQuery(GET_TRAINING_SUMMARY, {
    onCompleted: (report) => {
      setResult(report.getTrainingSummary);
    }
  });

  function getTrainingTimeTaken(params) {
    let time = "";
    let remaining = params.row.trainingTime;
    const hours = Math.floor(remaining / (1000 * 60 * 60));
    if (hours > 0) {
      time += `${hours} hour${hours > 1 ? 's' : ''}`;
      remaining -= hours * (1000 * 60 * 60);
    }
    const minutes = Math.floor(remaining / (1000 * 60));
    if (hours > 0) {
      time += ` ${minutes} minute${minutes !== 1 ? 's' : ''}`;
      remaining -= minutes * (1000 * 60);
    } else {
      if (minutes > 0) {
        time += `${minutes} minute${minutes > 1 ? 's' : ''}`;
        remaining -= minutes * (1000 * 60);
      }
    }
    const seconds = Math.round(remaining / 1000);
    if (hours > 0 || minutes > 0) {
      time += ` ${seconds} second${seconds !== 1 ? 's' : ''}`;
    } else {
      time += `${seconds} second${seconds > 1 ? 's' : ''}`;
    }
    return time;
  };

  function getAssessmentTimeTaken(params) {
    let time = "";
    let remaining = params.row.assessmentTime;
    const hours = Math.floor(remaining / (1000 * 60 * 60));
    if (hours > 0) {
      time += `${hours} hour${hours > 1 ? 's' : ''}`;
      remaining -= hours * (1000 * 60 * 60);
    }
    const minutes = Math.floor(remaining / (1000 * 60));
    if (hours > 0) {
      time += ` ${minutes} minute${minutes !== 1 ? 's' : ''}`;
      remaining -= minutes * (1000 * 60);
    } else {
      if (minutes > 0) {
        time += `${minutes} minute${minutes > 1 ? 's' : ''}`;
        remaining -= minutes * (1000 * 60);
      }
    }
    const seconds = Math.round(remaining / 1000);
    if (hours > 0 || minutes > 0) {
      time += ` ${seconds} second${seconds !== 1 ? 's' : ''}`;
    } else {
      time += `${seconds} second${seconds > 1 ? 's' : ''}`;
    }
    return time;
  };

  function getMedianTimeTaken(params) {
    let time = "";
    let remaining = params.row.medianAssessmentTime;
    const hours = Math.floor(remaining / (1000 * 60 * 60));
    if (hours > 0) {
      time += `${hours} hour${hours > 1 ? 's' : ''}`;
      remaining -= hours * (1000 * 60 * 60);
    }
    const minutes = Math.floor(remaining / (1000 * 60));
    if (hours > 0) {
      time += ` ${minutes} minute${minutes !== 1 ? 's' : ''}`;
      remaining -= minutes * (1000 * 60);
    } else {
      if (minutes > 0) {
        time += `${minutes} minute${minutes > 1 ? 's' : ''}`;
        remaining -= minutes * (1000 * 60);
      }
    }
    const seconds = Math.round(remaining / 1000);
    if (hours > 0 || minutes > 0) {
      time += ` ${seconds} second${seconds !== 1 ? 's' : ''}`;
    } else {
      time += `${seconds} second${seconds > 1 ? 's' : ''}`;
    }
    return time;
  };

  useEffect(() => {
    getTrainingSummary({
      variables: {
        student_id,
        training_module
      }
    })
  }, []);

  const trainingColumns = useMemo(
    () => [
      {
        field: "attempt",
        headerName: "Attempt",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "trainingTime",
        headerName: "Time Taken",
        headerAlign: "center",
        align: "center",
        flex: 1,
        valueGetter: getTrainingTimeTaken,
      },
      {
        field: "trainingDate",
        headerName: "Date Complete",
        flex: 1,
        headerAlign: "center",
        type: "dateTime",
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
      }
    ],
    [result]
  );

  const assessmentColumns = useMemo(
    () => [
      {
        field: "attempt",
        headerName: "Attempt",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "assessmentScore",
        headerName: "Score",
        flex: 1,
        headerAlign: "center",
        align: "center",
        filterable: false,
      },
      {
        field: "assessmentTime",
        headerName: "Time Taken",
        headerAlign: "center",
        align: "center",
        flex: 1,
        valueGetter: getAssessmentTimeTaken,
      },
      {
        field: "medianAssessmentTime",
        headerName: "Median Time Taken",
        headerAlign: "center",
        align: "center",
        flex: 1,
        valueGetter: getMedianTimeTaken,
      },
      {
        field: "assessmentDate",
        headerName: "Date Complete",
        flex: 1,
        headerAlign: "center",
        type: "dateTime",
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
      }
    ],
    [result]
  );

  return (
    <Dialog open={open} fullScreen>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => openFn(false)}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={logo}
            alt="NSCC Logo"
            style={{ marginRight: "32px", height: "60px", maxWidth: "100px" }}
          />
        </Toolbar>
      </AppBar>
      <Box sx={{ margin: 3, mt: 1 }} >
        <Stack direction="row" justifyContent="space-between">
          <Box />
          <Typography variant="h4" component="div">
            { name }
          </Typography>
        </Stack>
        <Typography variant="h4" component="div">
          Training Section ({totalTraining} questions)
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Box />
          { result?.training && <TrainingBarGraph data={result.training} /> }
          <Box />
        </Stack>
        {
          result?.attempts?.filter(a => a.trainingScore).length > 0 && (
            <Stack direction="row" justifyContent="space-between">
              <Box />
              <DataGridPro
                rows={result.attempts.filter(a => a.trainingScore)}
                columns={trainingColumns}
                disableSelectionOnClick
                autoHeight
                loading={reportLoading}
                pagination
                getRowId={(row) => row.attempt}
                sx={{ width: "50%" }}
              />
              <Box />
            </Stack>
          )
        }
        <br />
        {
          totalAssessment && (
            <>
              <Typography variant="h4" component="div">
                Assessment Section ({totalAssessment} questions)
              </Typography>
              {/* <Stack direction="row" justifyContent="space-between">
                <Box />
                {result?.times && <TrainingBarGraph data={result.times} />}
                <Box />
              </Stack> */}
              <Stack direction="row" justifyContent="space-evenly" sx={{ mt: 10 }}>
                {result?.assessment && (
                  <>
                    <RadialBarGraph data={result.assessment.first} fill="#C10015" circleSize={circleSize} type="First Attempt" />
                    <RadialBarGraph data={result.assessment.median} fill="#C10015" circleSize={circleSize} type="Median of All Students" />
                  </>
                )}
                {result?.assessment?.second && <RadialBarGraph data={result.assessment.second} fill="#82ca9d" circleSize={circleSize} type="Second Attempt of Incorrect Questions" />}
              </Stack>
              {
                result?.attempts?.filter(a => a.assessmentScore).length > 0 && (
                  <Stack direction="row" justifyContent="space-between">
                    <Box />
                    <DataGridPro
                      rows={result.attempts.filter(a => a.assessmentScore)}
                      columns={assessmentColumns}
                      disableSelectionOnClick
                      autoHeight
                      loading={reportLoading}
                      pagination
                      getRowId={(row) => row.attempt}
                      sx={{ width: "50%" }}
                    />
                    <Box />
                  </Stack>
                )
              }
            </>
          )
        }
      </Box>
    </Dialog>
  )
};

export default TrainingReport;