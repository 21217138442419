import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSnackbar } from "notistack";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { GET_ALLOCATED_TRAININGS_BY_COURSE, GET_TRAININGS_BY_QUERY } from "../graphql/queries";
import { ALLOCATE_TRAININGS_TO_COURSE, DEALLOCATE_TRAINING } from "../graphql/mutations";
import { AuthenticatedContext } from "../App";

const SelectTraining = ({ course_id }) => {
  const { userRole } = useContext(AuthenticatedContext);
  const { enqueueSnackbar } = useSnackbar();
  const [trainingName, setTrainingName] = useState("");
  const [trainingID, setTrainingID] = useState("");
  const [unselectedTrainings, setUnselectedTrainings] = useState([]);
  const [selectedTrainings, setSelectedTrainings] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteTrainingID, setDeleteTrainingID] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);

  const [getAllocatedTrainingsByCourse, { data: selectedTrainingsData }] = useLazyQuery(GET_ALLOCATED_TRAININGS_BY_COURSE, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAllocatedTrainingsByCourse }) => {
      setSelectedTrainings(getAllocatedTrainingsByCourse)
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [getTrainingsByQuery, { loading: getTrainingsByQueryLoading }] = useLazyQuery(GET_TRAININGS_BY_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getTrainingsByQuery }) => {
      if (getTrainingsByQuery.length !== 0) {
        if (selectedTrainingsData) {
          const trainingsIDs = selectedTrainingsData?.getAllocatedTrainingsByCourse?.map(t => t.id);
          const filteredTrainings = getTrainingsByQuery.filter(t => {
            return !trainingsIDs.includes(t.id) && t.isActive;
          })

          if (filteredTrainings?.length !== 0) {
            setUnselectedTrainings(filteredTrainings);
          } else {
            enqueueSnackbar("No trainings found", { variant: "warning" });
            clearSearchData();
          }
        } else {
          setUnselectedTrainings(getTrainingsByQuery);
        }
      } else {
        enqueueSnackbar("No trainings found", { variant: "warning" });
        clearSearchData();
      }
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    }
  });

  const [deallocateTraining] = useMutation(DEALLOCATE_TRAINING, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ deallocateTraining }) => {
      if (deallocateTraining) {
        enqueueSnackbar("Training has been successfully deallocated. ", { variant: "success" })
        await getTrainingsByQuery({ variables: {
          ...(trainingName ? { training_name: trainingName } : {}),
          ...(trainingID ? { training_id: Number(trainingID) } : {}),
        }});
        setDeleteDialog(false);
      }
    },
    refetchQueries: [{ query: GET_ALLOCATED_TRAININGS_BY_COURSE, variables: { course_id: Number(course_id) }}, 'getAllocatedTrainingsByCourse'],
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  })

  const [allocateTrainingsToCourse] = useMutation(ALLOCATE_TRAININGS_TO_COURSE, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ allocateTrainingsToCourse }) => {
      if (allocateTrainingsToCourse) {
        enqueueSnackbar(`${selectionModel?.length} Trainings(s) has been successfully allocated.`, { variant: "success" })
        if (selectionModel) {
          setUnselectedTrainings(unselectedTrainings?.filter(t => !selectionModel.includes(t.id)))
        }
      }
    },
    refetchQueries: [
      { query: GET_ALLOCATED_TRAININGS_BY_COURSE, variables: { course_id: Number(course_id) }}, 'getAllocatedTrainingsByCourse',
    ],
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  })

  useEffect(() => {
    if (course_id) getAllocatedTrainingsByCourse({ variables: { course_id: Number(course_id) }})
  }, [course_id]);

  const clearSearchData = () => {
    setUnselectedTrainings([]);
    setTrainingID("");
    setTrainingName("");
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    getTrainingsByQuery({ variables: {
      ...(trainingName ? { training_name: trainingName } : {}),
      ...(trainingID ? { training_id: Number(trainingID) } : {}),
    }})
  }

  const removeTraining = useCallback(
    (params) => () => 
      {
        setDeleteTrainingID(params.id)
        setDeleteDialog(true)
      },
    []
  )

  const handleDeallocateTraining = () => {
    deallocateTraining({ variables: { course_id: Number(course_id), training_id: Number(deleteTrainingID) }})
  }

  const handleAddTrainingsToCourse = () => {
    allocateTrainingsToCourse({ variables: { course_id: Number(course_id), training_ids: selectionModel }})
  }

  const unselectedTrainingColumns = [
    {
      field: "id",
      headerName: "Training ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Training Name",
      flex: 5,
      headerAlign: "center",
      align: "center",
    },
  ]

  const selectedTrainingColumns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true,
        headerName: "Actions",
        flex: 1,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        hide: !["Admin", "Education Consultant"].includes(userRole),
        getActions: (params) => {
          if (["Admin", "Education Consultant"].includes(userRole)) {
            return [
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Remove"
                color="primary"
                onClick={removeTraining(params)}
              />,
            ]
          } else {
            return [];
          }
        },
      },
      {
        field: "id",
        headerName: "Training ID",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "name",
        headerName: "Training Name",
        flex: 5,
        headerAlign: "center",
        align: "center",
      },
    ],
    [removeTraining]
  )
  
  return (
      <Paper elevation={0} sx={{ p: 1 }} >
        <Dialog open={deleteDialog}>
          <DialogTitle>Deselect Training</DialogTitle>
          <DialogContent>
            Are you sure you want to deselect this training?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
            <Button onClick={handleDeallocateTraining}>Confirm</Button>
          </DialogActions>
        </Dialog>
        { ["Admin", "Education Consultant"].includes(userRole) && (
          <>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSearchSubmit}
              sx={{ mb: 3 }}
            >
              <Grid container spacing={4}>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    id="trainingName"
                    label="Training Name"
                    value={trainingName}
                    size="small"
                    onChange={e => setTrainingName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    id="trainingID"
                    type="number"
                    label="Training ID"
                    value={trainingID}
                    size="small"
                    onChange={e => setTrainingID(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<SearchIcon />}
                    type="submit"
                  >
                    Search Training(s)
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ mb: 3 }} />
          </>
        )}
        
        <Grid container sx={{ background: "#F8F8F8", border: "1px solid lightgray", p: 2, borderRadius: 1.2 }}>
          { userRole === "Admin" && (
            <>
              <Grid item xs={5.9}>
                <Typography color="primary" sx={{ mb: 2, fontWeight: 500 }}>
                  Unselected Trainings
                </Typography>
                <DataGridPro
                  rows={unselectedTrainings}
                  columns={unselectedTrainingColumns}
                  // componentsProps={{
                  //   toolbar: {
                  //     showQuickFilter: true,
                  //     quickFilterProps: { debounceMs: 500 },
                  //   }
                  // }}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  disableSelectionOnClick
                  checkboxSelection={true}
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                  autoHeight
                  loading={getTrainingsByQueryLoading}
                  pagination={true}
                  pageSize={pageSize}
                  onPageSizeChange={handlePageSizeChange}
                  density="compact"
                />
              </Grid>
              <Grid item xs={0.2} />
            </>
          )}
          
          <Grid item xs={userRole === "Admin" ? 5.9 : 12}>
            <Typography color="primary" sx={{ mb: 2, fontWeight: 500 }}>
              Selected Trainings
            </Typography>
            <DataGridPro
              rows={selectedTrainings}
              columns={selectedTrainingColumns}
              density="compact"
              // componentsProps={{
              //   toolbar: {
              //     showQuickFilter: true,
              //     quickFilterProps: { debounceMs: 500 },
              //   }
              // }}
              rowsPerPageOptions={[10, 25, 50, 100]}
              disableSelectionOnClick
              autoHeight
              // loading={getStudentsByQueryLoading}
              pagination={true}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
            />
          </Grid>
          { ["Admin", "Education Consultant"].includes(userRole) && (
            <Grid item xs={2} sx={{ mt: 2 }}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                disabled={selectionModel && selectionModel?.length === 0}
                onClick={handleAddTrainingsToCourse}
                startIcon={<AddCircleIcon />}
              >
                Add Training(s)
              </Button>
            </Grid>
          )}
          
        </Grid>
        
      </Paper>
  )
}

export default SelectTraining;