import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { format } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import jwtDecode from "jwt-decode";
import { AuthenticatedContext } from "../../App";
import * as yup from "yup";
import { GET_MY_STAFF_DETAILS } from "../../graphql/queries";
import { UPDATE_STAFF } from "../../graphql/mutations";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  LabelList,
  ReferenceLine,
  Label
} from "recharts";

const ScatterGraph = ({ passageData, student_id, isDefault, student_name }) => {
  let xmax = 0;
  let ymax = 0;
  passageData?.sittingData.map(s => {
    if (Math.ceil(Math.abs(s.speed)) > xmax) xmax = Math.ceil(Math.abs(s.speed));
    if (Math.ceil(Math.abs(s.correct)) > ymax) ymax = Math.ceil(Math.abs(s.correct));
  });

  const CustomXAxisTick = (value) => {
    if (value === xmax - 0.5) return "(faster)";
    if (value === (xmax * -1) + 0.5) return "(slower)";
    if (value === 0) return "Avg";
    return "";
  }

  const CustomYAxisTick = (value) => {
    if (value === 0) return "Avg";
    return "";
  }

  const renderCustomizedLabel = (props) => {
    const {
      x, y, width, height, value
    } = props;
    let offset;
    if ((x > 0 && x < 200) || (x > 270 && x < 450)) {
      offset = 0;
    } else {
      offset = 40;
    }
    let yoffset = 0;
    if (y >= 240 && y <= 250) yoffset = 10;
    const display = value === student_id ? "You" : "";
    return (
      <text x={x + width - offset} y={y + height - yoffset} fill="#000000">
        {display}
      </text>
    )
  }

  const CustomizedDot = ({ cx, cy, studentId }) => {
    if (studentId === student_id) {
      return <circle cx={cx} cy={cy} r={8} fill="#c10015" />;
    } else {
      return <circle cx={cx} cy={cy} r={3} fill="lightsteelblue" />;
    }
  }

  const renderLegend = (props) => {
    const { payload } = props;
    console.log(props);
    return null;
    // return (
    //   <ul>
    //     {
    //       payload.map((entry, index) => (
    //         <li key={`item-${index}`}>{entry.value}</li>
    //       ))
    //     }
    //   </ul>
    // );
  }

  return (passageData && student_id) && (
    <>
      <ScatterChart 
        width={500} 
        height={500}
        margin={{
          top: 30,
          right: 0,
          left: 20,
          bottom: 20,
        }}
      >
        <text x={560 / 2} y={10} fill="black" textAnchor="middle" dominantBaseline="central">
            <tspan fontSize={20} fontWeight="bold">{passageData?.name}</tspan>
        </text>
        <XAxis type="number" dataKey="speed" domain={[-1 * xmax, xmax]} tickSize={0} tickCount={(xmax * 4) + 1} allowDecimals={true} tickFormatter={CustomXAxisTick} strokeWidth={4} stroke="black">
          <Label value="Speed" position="insideBottom" offset={-10} fill="black" fontSize={20} fontWeight="bold" />
        </XAxis>
        <YAxis type="number" dataKey="correct" name="Questions Correct" domain={[-1 * ymax, ymax]} tickSize={0} tickCount={(ymax * 4) + 1} tickFormatter={CustomYAxisTick} allowDecimals={true} strokeWidth={4} stroke="black">
          <Label value="Performance" angle={-90} fill="black" fontSize={20} fontWeight="bold" position="insideLeft" />
        </YAxis>
        <Legend
          payload={
            [
              { id: "ID01", value: "You", type: "circle", color: "#c10015" },
              { id: "ID02", value: "Other Students", type: "circle", color: "lightsteelblue"}
            ]
          }
          wrapperStyle={{ position: "relative" }}
        />
        <ReferenceLine x={0} stroke="grey" isFront={false} strokeDasharray="5 3" />
        <ReferenceLine y={0} stroke="grey" isFront={false} strokeDasharray="5 3" />
        <ReferenceLine x={xmax} stroke="black" isFront={false} />
        <ReferenceLine y={ymax} stroke="black" isFront={false} />
        <Scatter data={passageData?.sittingData} shape={<CustomizedDot />} >
          {/* {
            passageData?.sittingData.map((entry, i) => {
              return (
                <Cell key={`cell-${i}`} fill={entry.studentId === student_id ? "transparent" : "#8884d8"} stroke={entry.studentId === student_id ? "#c10015" : "#8884d8"} />
              )
            })
          }
          <LabelList
            dataKey="studentId"
            fill="#000000"
            position="right"
            content={renderCustomizedLabel}
            style={{
              fontSize: 12
            }}
          /> */}
        </Scatter>
      </ScatterChart>
      {isDefault && (<><br /><Typography>There was not enough data to determine {student_name}'s {passageData?.name} ability. As a result their ability was assumed to be average.</Typography></>)}
    </>
  )
};

export default ScatterGraph;
