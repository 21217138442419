import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid-pro";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { GET_ALL_COURSES } from "../graphql/queries";
import { CREATE_COURSE, DELETE_COURSE, DELETE_TEST, REGISTER_MOCK_STUDENTS } from "../graphql/mutations";
import { AuthenticatedContext, PreferenceContext } from "../App";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DateTimePicker } from "@mui/x-date-pickers-pro";

const CourseManagement = () => {
  const { preferences, setPreferences, defaults } = React.useContext(PreferenceContext);
  const { userRole } = React.useContext(AuthenticatedContext);
  let coursePref = {...preferences.courseMgmt};
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(coursePref.pageSize || 10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogParams, setDeleteDialogParams] = useState({});
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);

  const schema = yup
  .object({
    name: yup.string().max(100).required("Required"),
    availableFrom: yup.date().nullable().transform(v => (v instanceof Date && !isNaN(v) ? v: null)),
    availableTo: yup.date().nullable().transform(v => (v instanceof Date && !isNaN(v) ? v: null)),
  })
  .required();
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      availableFrom: null,
      availableTo: null,
    },
  });

  const [getAllCourses, { coursesLoading, coursesError, coursesData }] = useLazyQuery(GET_ALL_COURSES, {
    fetchPolicy: "no-cache",
    onCompleted: (courses) => {
      if (courses.getAllCourses?.courses.length >= 0) {
        setRows(courses.getAllCourses.courses);
      }
      setRowCount(courses.getAllCourses.total);
    }
  });

  const [deleteCourse] = useMutation(DELETE_COURSE, {
    onError: (error) => {
      console.log(error);
      setDeleteDialogOpen(false);
    },
    onCompleted: ({deleteCourse}) => {
      if (deleteCourse === true) {
        setRows(rows.filter((row) => row.id !== deleteDialogParams.id));
        enqueueSnackbar(
          `Training Course (id: ${deleteDialogParams.id}) has been successfully deleted`,
          {
            variant: "success",
          }
        );
      } else if (deleteCourse === false) {
        enqueueSnackbar("Training Course could not be found", {
          variant: "error",
        });
      }
      setDeleteDialogOpen(false);
    },
    fetchPolicy: "network-only",
  });

  const [createCourse] = useMutation(CREATE_COURSE, {
    onError: (error) => {
      console.log(error);
      enqueueSnackbar(
        `A Training Course with the same name already exists.`,
        {
          variant: "error",
        }
      );
      reset();
      setOpen(false);
    },
    onCompleted: ({ createCourse }) => {
      if (createCourse) {
        if (page === 0) {
          getAllCourses({
            variables: {
              skip: page * pageSize,
              take: pageSize,
              filters: coursePref?.filters || defaults.courseMgmt.filters,
              sort: coursePref?.sort || defaults.courseMgmt.sort,
            }
          });
        } else {
          setPage(0);
        }
        enqueueSnackbar(
          "Training Course has been successfully created",
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(
          `Failed to create Training Course.`,
          {
            variant: "error",
          }
        );
      }
      reset();
      setOpen(false);
    }
  });

  useEffect(() => {
    getAllCourses({
      variables: {
        skip: page * pageSize,
        take: pageSize,
        filters: coursePref?.filters || defaults.courseMgmt.filters,
        sort: coursePref?.sort || defaults.courseMgmt.sort,
      }
    });
  }, [page]);

  const handleDeleteCourse = (params) => {
    deleteCourse({ variables: { id: params.id }})
  }  

  const deleteCourseFunction = useCallback((params) => () => {
    setDeleteDialogOpen(true);
    setDeleteDialogParams(params.row);
  });

  const viewCourse = useCallback(
    (params) => () => {
      history.push(`/coursemanagement/${params.row.id}`, { data: params.row })},
    []
  );

  const handlePageSizeChange = (newPageSize) => {
    coursePref.pageSize = newPageSize;
    setPageSize(newPageSize);
    setPreferences({
      ...preferences,
      courseMgmt: {
        ...coursePref
      }
    });
    if (page === 0 && defaults) {
      getAllCourses({
        variables: {
          skip: page * newPageSize,
          take: newPageSize,
          filters: coursePref.filters,
          sort: coursePref.sort,
        }
      });
    } else {
      setPage(0);
    }
  };

  const handleColumnWidthChange = (params) => {
    coursePref.widths[params.colDef.field] = params.width;
    setPreferences({
      ...preferences,
      courseMgmt: {
        ...coursePref
      }
    });
  };

  const handleColumnOrderChange = (params) => {
    coursePref.columns.splice(params.oldIndex, 1);
    coursePref.columns.splice(params.targetIndex, 0, params.field);
    setPreferences({
      ...preferences,
      courseMgmt: {
        ...coursePref
      }
    });
  };

  const handleColumnVisibilityModelChange = (model) => {
    coursePref.visible = {...model};
    setPreferences({
      ...preferences,
      courseMgmt: {
        ...coursePref
      }
    });
  }

  const handleSortModelChange = (model) => {
    coursePref.sort = [...model];

    setPreferences({
      ...preferences,
      courseMgmt: {
        ...coursePref
      }
    });
    if (page === 0 && defaults) {
      getAllCourses({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filters: coursePref.filters,
          sort: [...model],
        }
      });
    } else {
      setPage(0);
    }
  };

  const handleFilterModelChange = (model) => {
    coursePref.filters = {...model};

    
    setPreferences({
      ...preferences,
      courseMgmt: {
        ...coursePref
      }
    });
    if (page === 0) {
      getAllCourses({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filters: {...model},
          sort: coursePref.sort,
        }
      });
    } else {
      setPage(0);
    }
  };

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        width: coursePref?.widths?.actions,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => {
          let actions = [
            <GridActionsCellItem
              icon={<Tooltip title="View Course"><VisibilityIcon /></Tooltip>}
              label="View"
              color="primary"
              onClick={viewCourse(params)}
            />
          ];
          if (["Admin", "Education Consultant"].includes(userRole)) {
            actions.push(
              <GridActionsCellItem
                icon={<Tooltip title="Delete Course"><DeleteIcon /></Tooltip>}
                label="Delete"
                color="primary"
                onClick={deleteCourseFunction(params)}
              />
            );
          }
          return actions; 
        }
      },
      {
        field: "id",
        headerName: "ID",
        width: coursePref?.widths?.id,
        headerAlign: "center",
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
        hide: !coursePref?.visible?.id || !["Admin", "Education Consultant"].includes(userRole),
      },
      {
        field: "name",
        headerName: "Course Name",
        width: coursePref?.widths?.name,
        headerAlign: "center",
        headerAlign: "center",
        align: "center",
        hide: !coursePref?.visible?.name,
        getApplyQuickFilterFn: getApplyFilterFnName,
      },
      // {
      //   field: "availableFrom",
      //   headerName: "Available From",
      //   width: coursePref?.widths?.availableFrom,
      //   headerAlign: "center",
      //   type: "dateTime",
      //   hide: !coursePref?.visible?.availableFrom,
      //   getApplyQuickFilterFn: undefined,
      //   valueFormatter: ({ value }) => {
      //     if (value) {
      //       return format(value, "dd/MM/yyyy hh:mm");
      //     }
      //   },
      //   align: "center",
      //   filterable: false,
      // },
      // {
      //   field: "availableTo",
      //   headerName: "Available To",
      //   width: coursePref?.widths?.availableTo,
      //   headerAlign: "center",
      //   type: "dateTime",
      //   hide: !coursePref?.visible?.availableTo,
      //   getApplyQuickFilterFn: undefined,
      //   valueFormatter: ({ value }) => {
      //     if (value) {
      //       return format(value, "dd/MM/yyyy hh:mm");
      //     }
      //   },
      //   align: "center",
      //   filterable: false,
      // },
      {
        field: "createdAt",
        headerName: "Created",
        width: coursePref?.widths?.createdAt,
        headerAlign: "center",
        type: "dateTime",
        hide: !coursePref?.visible?.createdAt,
        getApplyQuickFilterFn: undefined,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
        filterable: false,
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: coursePref?.widths?.updatedAt,
        headerAlign: "center",
        type: "dateTime",
        hide: !coursePref?.visible?.updatedAt,
        getApplyQuickFilterFn: undefined,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
        filterable: false,
      }
    ],
    [viewCourse, coursePref]
  );

  const onSubmit = ({
    name,
    // availableFrom,
    // availableTo
  }) => {
    createCourse({
      variables: {
        name,
        // availableFrom,
        // availableTo
      },
    });
  };

  return (
    <>
      <Dialog open={open} fullWidth sx={{padding: 10}}>
        <DialogTitle>Create Training Course</DialogTitle>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ pl: 2, pr: 2, pb: 2 }}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={errors.name ? true : false}
                sx={{ mb: 3 }}
              >
                <InputLabel>Name</InputLabel>
                <OutlinedInput {...field} label="Name" />
                <FormHelperText sx={{ color: "primary.main" }}>
                  {errors.name?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          {/* <Stack space={2} direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
            <Controller
              name="availableFrom"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="outlined"
                  required
                  error={errors.availableFrom ? true : false}
                >
                  <DateTimePicker 
                    renderInput={(params) => <TextField {...params} />} 
                    label="Available From"
                    inputFormat="dd/MM/yyyy hh:mm"
                    {...field}
                  />
                  <FormHelperText sx={{ color: "primary.main" }}>
                    {errors.availableFrom?.message}
                  </FormHelperText>
                </FormControl>
              )} />
              <Controller
              name="availableTo"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="outlined"
                  required
                  error={errors.availableTo ? true : false}
                >
                  <DateTimePicker 
                    renderInput={(params) => <TextField {...params} />} 
                    label="Available To"
                    inputFormat="dd/MM/yyyy hh:mm"
                    {...field}
                  />
                  <FormHelperText sx={{ color: "primary.main" }}>
                    {errors.availableTo?.message}
                  </FormHelperText>
                </FormControl>
              )} />
            </Stack> */}
          <DialogActions>
            <Stack space={2} direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => { setOpen(false); reset() }}
              >
                Cancel
              </Button>
              <Button 
                type="submit"
                variant="contained"
              >
                Create
              </Button>
            </Stack>
          </DialogActions>
        </Box>
        
      </Dialog>
      <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
        <Paper elevation={0} sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              mb: 3,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
              Course Management
            </Typography>
          </Box>
          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
          >
            <DialogTitle>
              {`Are you sure you want to delete this training course?`}
            </DialogTitle>
            <DialogActions sx={{ mx: 1.6, my: 1 }}>
              <Button
                onClick={() => handleDeleteCourse(deleteDialogParams)}
                color="primary"
                variant="contained"
                startIcon={<CheckCircleIcon />}
                autoFocus
              >
                Confirm
              </Button>
              <Button
                color="secondary"
                variant="contained"
                startIcon={<CancelIcon />}
                onClick={() => setDeleteDialogOpen(false)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          { ["Admin", "Education Consultant"].includes(userRole)  && (
            <>
              <Stack space={2} direction="row" justifyContent="space-between">
                <Box />
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => setOpen(true)}
                >
                  Create Course
                </Button>
              </Stack>
              <br />
            </>
          )}
          
          <DataGridPro
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            pageSize={coursePref.pageSize}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableSelectionOnClick
            autoHeight
            loading={coursesLoading}
            onColumnWidthChange={handleColumnWidthChange}
            onColumnOrderChange={handleColumnOrderChange}
            pagination
            pinnedColumns={{ left: ["actions"] }}
            onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            filterModel={coursePref.filters}
            sortModel={coursePref.sort}
            page={page}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            rowCount={rowCount}
            density="compact"
          />
        </Paper>
      </Container>
    </>
  );
};

export default CourseManagement;
