import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
  Autocomplete,
  TextField,
  Grid,
  DialogTitle,
  FormControl,
  MenuItem,
  FormHelperText,
  InputLabel,
  Select,
  Stack,
  OutlinedInput
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import { GET_ALL_COURSES, GET_ALL_TEACHERS, GET_CENTRES, GET_REGIONS, GET_TRAINING_CLASSES } from "../graphql/queries";
import { CREATE_TRAINING_CLASS, DELETE_TRAINING_CLASS, REALLOCATE_STUDENTS } from "../graphql/mutations";
import { AuthenticatedContext, PreferenceContext } from "../App";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  name: yup.string().max(100).required(),
  course: yup.object().required(),
  regionId: yup.number().required(),
  centre: yup.object().nullable(),
});

const ClassManagement = () => {
  const { preferences } = useContext(PreferenceContext);
  const { userRole } = useContext(AuthenticatedContext);
  let classMgmtPref = { ...preferences.classManagement };
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [reallocateDialogOpen, setReallocateDialogOpen] = useState(false);
  const [newTeacher, setNewTeacher] = useState(null)
  const [selectedTeacherID, setSelectedTeacherID] = useState(null)
  const [pageSize, setPageSize] = useState(10);
  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);
  const [chosenClass, setChosenClass] = useState(null);
  const [openDel, setOpenDel] = useState(false);
  const [openClass, setOpenClass] = useState(false);
  const [courses, setCourses] = useState([]);
  const [centres, setCentres] = useState([]);

  const { data: regionData, loading: regionsLoading } = useQuery(GET_REGIONS, {
    fetchPolicy: 'network-only',
  });

  const { data: classData, loading: classesLoading, refetch } = useQuery(GET_TRAINING_CLASSES, {
    fetchPolicy: 'network-only',
  });

  const [getAllCourses, { loading: coursesLoading }] = useLazyQuery(GET_ALL_COURSES, {
    onCompleted: ({ getAllCourses }) => {
      setCourses([...getAllCourses.courses]);
    }
  });

  const [getCentres, { loading: centresLoading }] = useLazyQuery(GET_CENTRES, {
    onCompleted: ({ getCentres }) => {
      setCentres(getCentres);
    }
  });

  const [createTrainingClass] = useMutation(CREATE_TRAINING_CLASS, {
    onCompleted: async({ createTrainingClass }) => {
      if (createTrainingClass) {
        enqueueSnackbar("New class created successfully", { variant: "success" });
        refetch();
      }
    }
  });

  const [deleteTrainingClass] = useMutation(DELETE_TRAINING_CLASS, {
    onCompleted: (trainingClass) => {
      if (trainingClass.deleteTrainingClass === true) {
        enqueueSnackbar(
          `Class deleted sucessfully`,
          {
            variant: "success",
          }
        );
        refetch();
      } else {
        enqueueSnackbar(
          `Error deleting Training Class`,
          {
            variant: "error",
          }
        );
      }
    },
    fetchPolicy: 'network-only',
  });

  // const [getAllTeachers, { loading: getAllTeachersLoading}] = useLazyQuery(GET_ALL_TEACHERS, {
  //   onCompleted: ({ getAllTeachers }) => {
  //     const newRows = getAllTeachers.map(i => {
  //       return {
  //         id: i.id,
  //         name: i.givenname + " " + i.surname,
  //         isAllocated: Boolean(i.Students && i.Students?.length !== 0),
  //         numOfStudents: i.Students ? i.Students?.length : ""
  //       }
  //     })
  //     setRows(newRows)
  //   },
  //   fetchPolicy: "no-cache",
  // });
  
  const [reallocateStudents] = useMutation(REALLOCATE_STUDENTS, {
    onCompleted: async ({ reallocateStudents }) => {
      if (reallocateStudents) {
        enqueueSnackbar(`All students are successfully reallocated to teacher: ${newTeacher?.name}`, {
          variant: "success",
        });
      }
    },
    refetchQueries: [{ query: GET_ALL_TEACHERS }, 'getAllTeachers'],
    onError: (error) => {
      enqueueSnackbar(`Reallocate Student Error occured: ${error}`, {
        variant: "error",
      });
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getAllCourses();
  }, []);

  const viewTeacher = useCallback(
    (params) => () => 
      history.push(
        `/classmanagement/${params.row.id}`, { data: params.row }
      ),
    []
  )

  const handleDelete = useCallback(
    (params) => () => {
      setChosenClass(params);
      setOpenDel(true);
      }, []
  )

  const handleConfirm = () => {
    if (newTeacher && selectedTeacherID) {
      reallocateStudents({ variables: { original_teacher_id: selectedTeacherID, new_teacher_id: newTeacher?.id }})
    }
    setReallocateDialogOpen(false)
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      regionId: null,
      centre: null,
      course: null
    },
  });

  function getCourse(params) {
    return params.row.Course?.name;
  };

  function getRegion(params) {
    return params.row.Region?.name;
  };

  function getCentre(params) {
    return params.row.Centre?.name;
  };

  function getNumStudents(params) {
    return params.row.Students?.length;
  };

  function getCreatedName(params) {
    return params.row.CreatedByStaff?.givenname + " " + params.row.CreatedByStaff?.surname;
  };

  function getUpdatedName(params) {
    return params.row.UpdatedByStaff?.givenname + " " + params.row.UpdatedByStaff?.surname;
  };

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true,
        headerName: "Actions",
        width: classMgmtPref?.widths?.actions,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getActions: (params) => {
          let actions = [
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="View"
              color="primary"
              onClick={viewTeacher(params)}
            />
          ];
          if (["Admin", "Education Consultant"].includes(userRole)) {
            actions.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete Class"
                color="primary"
                onClick={handleDelete(params)}
                disabled={params.row.Students?.length !== 0}
              />
            )
          }
          return actions;
        }
      },
      {
        field: "id",
        headerName: "Class ID",
        width: classMgmtPref?.widths?.id,
        headerAlign: "center",
        align: "center",
        hide: true,
        type: "number"
      },
      {
        field: "name",
        headerName: "Class Name",
        width: classMgmtPref?.widths?.name,
        headerAlign: "center",
        align: "center",
        hide: !classMgmtPref?.visible?.name,
      },
      {
        field: "course",
        headerName: "Course",
        width: classMgmtPref?.widths?.course,
        headerAlign: "center",
        align: "center",
        hide: !classMgmtPref?.visible?.course,
        valueGetter: getCourse
      },
      {
        field: "region",
        headerName: "Region",
        width: classMgmtPref?.widths?.region,
        headerAlign: "center",
        align: "center",
        hide: !classMgmtPref?.visible?.region,
        valueGetter: getRegion
      },
      {
        field: "centre",
        headerName: "Campus",
        width: classMgmtPref?.widths?.centre,
        headerAlign: "center",
        align: "center",
        hide: !classMgmtPref?.visible?.centre,
        valueGetter: getCentre
      },
      {
        field: "numOfStudents",
        headerName: "# of Students",
        width: classMgmtPref?.widths?.numOfStudents,
        headerAlign: "center",
        align: "center",
        hide: !classMgmtPref?.visible?.numOfStudents,
        type: "number",
        valueGetter: getNumStudents
      },
      {
        field: "createdBy",
        headerName: "Created By",
        width: classMgmtPref?.widths?.createdBy,
        headerAlign: "center",
        align: "center",
        hide: !classMgmtPref?.visible?.createdBy || !["Admin", "Education Consultant"].includes(userRole),
        valueGetter: getCreatedName
      },
      {
        field: "updatedBy",
        headerName: "Updated By",
        width: classMgmtPref?.widths?.updatedBy,
        headerAlign: "center",
        align: "center",
        hide: !classMgmtPref?.visible?.updatedBy || !["Admin", "Education Consultant"].includes(userRole),
        valueGetter: getUpdatedName
      },
    ],
    [classData]
  );

  const onSubmit = async ({
    name,
    course,
    regionId,
    centre
  }) => {
    await createTrainingClass({
      variables: {
        name,
        courseId: course.id,
        regionId,
        ...(centre?.id) ? { centreId: centre.id } : {}
      }
    });
    reset({
      name: "",
      course: null,
      regionId: null,
      centre: null
    });
    setOpenClass(false);
  };

  useEffect(async () => {
    if (watch("regionId")) {
      await getCentres({
        variables: {
          regionId: watch("regionId")
        }
      });
    }
  }, [watch("regionId")]);

  const deleteClass = () => {
    deleteTrainingClass({ variables: { classId: chosenClass?.row.id }});
    setOpenDel(false);
  }

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
            Class Management
          </Typography>
        </Box>
        <Stack direction="row" justifyContent="space-between" sx={{ pb: 3 }}>
            <Box />
            {
              ["Admin", "Education Consultant"].includes(userRole) && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpenClass(true)}
                >
                  Create Class
                </Button>
              )
            }
          </Stack>
        <Dialog open={openDel} onClose={() => { setChosenClass(null); setOpenDel(false) }}>
          <DialogTitle>Delete Training Class</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this class?
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button onClick={() => setOpenDel(false)}>Cancel</Button>
            <Button onClick={() => deleteClass()}>Delete</Button>
          </DialogActions>
        </Dialog>
        <Dialog onClose={() => setOpenClass(false)} open={openClass} maxWidth="xl" fullWidth>
          <DialogTitle>Add New Registration</DialogTitle>
          <DialogContent>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
              sx={{ pt: 1, pb: 1 }}
            >
              <Grid container spacing={0.1} columnSpacing={1} alignItems="center" >
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          variant="outlined"
                          fullWidth
                          required
                          error={errors.name ? true : false}
                        >
                          <InputLabel>Name</InputLabel>
                          <OutlinedInput {...field} label="Name" />
                          <FormHelperText sx={{ color: "primary.main" }}>
                            {errors.name?.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <Controller
                      name="course"
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          variant="outlined"
                          fullWidth
                          required
                          error={errors.course ? true : false}
                        >
                          <Autocomplete
                            options={courses}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Course" margin="normal" />}
                            disabled={courses.length === 0}
                            onChange={(event, newValue) => setValue("course", newValue)}
                          />
                          <FormHelperText sx={{ color: "primary.main" }}>
                            {errors.course?.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="regionId"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        variant="outlined"
                        fullWidth
                        required
                        error={errors.regionId ? true : false}
                      >
                        <InputLabel>Region</InputLabel>
                        <Select label="Region" {...field} defaultValue="">
                          {regionData?.getRegions.map(({ id, name }) => (
                            <MenuItem value={id} key={id}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText sx={{ color: "primary.main" }}>
                          {errors.regionId?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  { watch("regionId") === 8 ? (
                    <Controller
                      name="centreId"
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          variant="outlined"
                          fullWidth
                          required
                          error={errors.centre ? true : false}
                        >
                          <Autocomplete
                            options={centres}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Centre" margin="normal" />}
                            disabled={centres.length === 0}
                            onChange={(event, newValue) => setValue("centre", newValue)}
                          />
                          <FormHelperText sx={{ color: "primary.main" }}>
                            {errors.centre?.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  ):<></>}
                </Grid>
                <Grid item xs={10} />
                <Grid item xs={2}>
                  <Box>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      //disabled={}
                      sx={{ mr: 2 }}
                    >
                      Submit
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      //disabled={}
                      onClick={() => setOpenClass(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
        <DataGridPro
          rows={classData?.getTrainingClasses || []}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          loading={classesLoading}
          pagination={true}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          // pinnedColumns={{ left: ["actions"] }}
        />
        {/* <Dialog maxWidth="sm" open={reallocateDialogOpen} onClose={() => setReallocateDialogOpen(false)}>
          <DialogContent>
            <Typography color="primary" variant="h6" sx={{ mb: 2 }}>
              Reallocate students to a new Teacher:
            </Typography>
            {rows && <Autocomplete
              name="newTeacher"
              value={newTeacher}
              options={rows}
              filterOptions={options => options.filter(item => item.id !== selectedTeacherID)}
              onChange={(e, options) => setNewTeacher(options)}
              getOptionLabel={(option) => 
                option.name ? option.name : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Select New Teacher"
                  placeholder="New Teacher"
                />
              )}
            />}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setReallocateDialogOpen(false)}>Cancel</Button>
            <Button disabled={!newTeacher} onClick={handleConfirm}>Confirm</Button>
          </DialogActions>
        </Dialog>
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          loading={getAllTeachersLoading}
          pagination={true}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          pinnedColumns={{ left: ["actions"] }}
        /> */}
      </Paper>
    </Container>
  );
};

export default ClassManagement;