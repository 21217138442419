import React, { useContext, useEffect, useState } from "react";
import { Split } from "@geoffcox/react-splitter";
import { Box } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import parse from "html-react-parser";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import SaveIcon from "@mui/icons-material/Save";
import { ModuleContext } from "../../App";
import { CREATE_TRAINING_INTERACTION } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import moment from "moment";

const EmbedType = ({
  currentQuestion: {
    id,
    content,
    hint
  },
}) => {
  const { moduleSettings } = useContext(ModuleContext);
  const options = {
    replace: ({ attribs }) => {
      if (!attribs) {
        return;
      }

      if (attribs["data-value"]) {
        const parsedHtml = attribs["data-value"];
        return <Latex>{`$${parsedHtml}$`}</Latex>;
      }
    },
  };

  const [createTrainingInteraction] = useMutation(CREATE_TRAINING_INTERACTION, {
    onError: (error) => {
      console.error(`${error}`);
    },
    fetchPolicy: "network-only",
  });

  const [splitHint, setSplitHint] = useState([]);
  const [hintPressed, setHintPressed] = useState([]);

  useEffect(() => {
    hint && setSplitHint(hint?.split("<p>[split]</p>"));
  }, [hint]);

  useEffect(() => {
    if (splitHint.length > 0) {
      setHintPressed(splitHint.map(h => false));
    }
  }, [splitHint]);

  const setInteraction = (i) => {
    if (!hintPressed[i-1]) {
      createTrainingInteraction({
        variables: {
          student_training_sitting_id: moduleSettings.currentSitting.id,
          question_number: moduleSettings.currentQuestionNumber,
          event: `Hint ${i} Opened`,
          time_taken: moment() - moment(moduleSettings.startTime)
        }
      });
      let newHintPressed = [...hintPressed];
      newHintPressed[i-1] = true;
      setHintPressed(newHintPressed);
    }
  }

  // const handleInputChange = ({ target }) => {
  //   const re = /^[A-Za-z0-9\.]+$/;
  //   //const re = /^[0-9\b]+$/;
  //   if (target.value == "" || re.test(target.value)) {
  //     setInputValue(target.value.toLowerCase());
  //   }
  // };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Box />
        <Box sx={{ pb: 2 }}>
          {content && (
            <>{parse(content, options)}</>
          )}
        </Box>
        <Box />
      </Stack>
      <Box sx={{ pb: 2 }}>
        { (splitHint.length > 0) && (
          <>
            { splitHint.map((h, i) => {
              return (
                <Accordion onClick={() => setInteraction(i + 1)}>
                  <AccordionSummary>
                    Hint {i + 1}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Alert
                      severity="info"
                      iconMapping={{
                        info: <WbIncandescentIcon fontSize="inherit" />,
                      }}
                      sx={{ ml: 2, mb: 2 }}
                    >
                      <strong>
                        Hint {i + 1}: <br></br>
                      </strong>
                      {parse(h, options)}
                    </Alert>
                  </AccordionDetails>
                </Accordion>
                
              )
            })}
          </>
        )}
      </Box>
    </>
  );
};

export default EmbedType;
