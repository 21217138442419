import { Button, Container, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { Box } from "@mui/system";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddUser from "../components/AddUser";
// import DeleteUser from "../components/DeleteUser";
import { GET_REGIONS, GET_STAFFS } from "../graphql/queries";
import { format } from "date-fns";
import EditUser from "../components/EditUser";

const UserManagement = () => {
  const [getStaffs, { loading: usersLoading }] = useLazyQuery(GET_STAFFS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setRows(data.getStaffs);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { data: regions, loading: regionsLoading } = useQuery(GET_REGIONS, {
    fetchPolicy: "cache-and-network",
  });

  const [rows, setRows] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);

  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getStaffs();
  }, []);

  function getFullName(params) {
    return `${params.row.givenname || ''} ${params.row.surname || ''}`;
  }

  function getCreatedBy(params) {
    return `${params.row.CreatedByStaff?.givenname || ''} ${params.row.CreatedByStaff?.surname || ''}`;
  }

  function getUpdatedBy(params) {
    return `${params.row.UpdatedByStaff?.givenname || ''} ${params.row.UpdatedByStaff?.surname || ''}`;
  }

  function getRole(params) {
    return `${params.row.Role?.name || ''}`;
  }

  function getRegion(params) {
    return `${params.row.Region?.name || ''}`;
  }

  function getCentres(params) {
    const names = params.row.Centres?.map(c => c.Centre?.name);
    if (names?.length > 0) {
      return names.join(', ');
    } else {
      return '';
    }
  }

  const columns = [
    // {
    //   field: "",
    //   sortable: false,
    //   filterable: false,
    //   disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
    //   headerName: "Actions",
    //   width: 150,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => (
    //     <>
    //       <EditUser
    //         params={params}
    //         regions={regions?.getRegions}
    //         roles={roles}
    //         getStaffs={getStaffs}
    //       />
    //       {/* <DeleteUser params={params} getStaffs={getStaffs} /> */}
    //     </>
    //   ),
    // },
    { 
      field: "id", 
      headerName: "Staff ID", 
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "number"
    },
    { 
      field: "name", 
      headerName: "Name", 
      flex: 3,
      valueGetter: getFullName,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "easyEmail",
      headerName: "Email",
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "role", 
      headerName: "Role", 
      flex: 2,
      valueGetter: getRole,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "region", 
      headerName: "Region", 
      flex: 1,
      valueGetter: getRegion,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "campuses", 
      headerName: "Centres", 
      flex: 3,
      valueGetter: getCentres,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 2,
      headerAlign: "center",
      type: "dateTime",
      getApplyQuickFilterFn: undefined,
      filterable: false,
      valueFormatter: ({ value }) => {
        if (value) {
          return format(value, "dd/MM/yyyy HH:mm:ss");
        }
      },
      align: "center",
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 2,
      headerAlign: "center",
      type: "dateTime",
      getApplyQuickFilterFn: undefined,
      filterable: false,
      valueFormatter: ({ value }) => {
        if (value) {
          return format(value, "dd/MM/yyyy HH:mm:ss");
        }
      },
      align: "center",
    },
    // {
    //   field: "createdBy", 
    //   headerName: "Created By", 
    //   flex: 3,
    //   valueGetter: getCreatedBy,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "updatedBy", 
    //   headerName: "Updated By", 
    //   flex: 3,
    //   valueGetter: getUpdatedBy,
    //   headerAlign: "center",
    //   align: "center",
    // },
  ];

  const roles = [
    {
      name: "Admin",
      roleId: 1,
      authorize: [1],
    },
    {
      name: "Education Consultant",
      roleId: 5,
      authorize: [1, 5],
    },
    {
      name: "Teacher",
      roleId: 6,
      authorize: [1, 5, 9]
    },
    {
      name: "Business Management",
      roleId: 9,
      authorize: [1]
    },
    {
      name: "Campus Director",
      roleId: 4,
      authorize: [1, 5, 4]
    }
  ];

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
            User Management
          </Typography>

          {/* <Button
            onClick={handleClickOpen}
            color="primary"
            variant="contained"
            startIcon={<AddCircleIcon />}
          >
            New User
          </Button> */}
        </Box>

        <AddUser
          open={open}
          handleClose={handleClose}
          regions={regions?.getRegions}
          roles={roles}
          getStaffs={getStaffs}
        />
        {rows && (
          <DataGridPro
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableSelectionOnClick
            autoHeight
            loading={usersLoading || regionsLoading}
          />
        )}
      </Paper>
    </Container>
  );
};

export default UserManagement;