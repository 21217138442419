import React from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { ModuleContext } from "../App";
import { useHistory, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { Box } from "@mui/system";
import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import AppsIcon from "@mui/icons-material/Apps";
import FlagIcon from "@mui/icons-material/Flag";

const Instructions = () => {
  const { moduleSettings, createSitting, exitSitting } =
    React.useContext(ModuleContext);
  const history = useHistory();
  const location = useLocation();
  const [instructionStep, setInstructionStep] = useState(2)

  async function startTest() {
    await createSitting();

    if (
      moduleSettings.moduleType === "Placement" ||
      moduleSettings.moduleType === "Diagnostic"
    ) {
      history.push("/placement-question");
    } else if (moduleSettings.moduleType === "Training" || moduleSettings.moduleType === "Interactive") {
      history.push("/training-question");
    }
  }

  function goToDashboard() {
    exitSitting();
    if(location.from === "/studenttraining" || location.from === "/training-question") {
      return history.push("/studenttraining")
    }
    history.push("/dashboard");
  }

  const handlePrevStep = () => {
    setInstructionStep(prev => prev - 1)
  }

  const handleNextStep = () => {
    setInstructionStep(prev => prev + 1)
  }

  return (
    <Grid container spacing={2}>
      { moduleSettings.moduleType === "Placement" && (
        <>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant="h4">
              {moduleSettings.selectedModule.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            {moduleSettings.moduleType === "Placement" && (
              <Typography variant="subtitle1">
                Duration: {moduleSettings.selectedModule.duration} minutes
              </Typography>
            )}
            <Typography variant="subtitle1">
              Total Questions: {moduleSettings.selectedModule.totalQuestions}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Instructions for SolverEdge Problem Solving Assessment
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Please read the Instructions carefully before starting the test.
            </Typography>
            <ol>
              <li>
                <Typography variant="body1">
                  After you have selected an answer, click Next button to proceed to the following question.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Questions may be attempted in any order, and you may skip up to 4 questions at a time. Click Next to skip the question.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  You can change the answer anytime within the allocated test time.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  The colour-coded boxes labelled with each test question number, located on the review page, indicates the status of each test question. Please see the key below:
                </Typography>
                <br />
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      ml: 1,
                      color: "black",
                      backgroundColor: "#9e9e9e",
                      textAlign: "center",
                    }}
                  >
                    Grey
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1">
                      Not answered / Skipped question
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid
                    item
                    xs={2}
                    sx={{
                      ml: 1,
                      color: "black",
                      backgroundColor: "#fdd835",
                      textAlign: "center",
                    }}
                  >
                    Yellow
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1">Answered question</Typography>
                  </Grid>
                </Grid>
                <br />
              </li>
              <li>
                <Typography variant="body1">
                  To skip and/or flag the question to return to, press the <FlagIcon sx={{ color: "#C10015" }} />
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  To review your answers, press <AppsIcon sx={{ color: "#C10015"}} />
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Do not submit unless you have reviewed all the answers. You will not be permitted to change your answer once you submit your test.
                </Typography>
              </li>
            </ol>
          </Grid>
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent="center">
              <Button
                variant="contained"
                onClick={goToDashboard}
                sx={{ mr: 4 }}
              >
                Return to Dashboard
              </Button>
              <Button
                variant="contained"
                onClick={startTest}
              >
                Start Test
              </Button>
            </Box>
          </Grid>
        </>
      )}
      {(
        moduleSettings.moduleType === "Training" || moduleSettings.moduleType === "Interactive") && (
        <>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant="h4">
              {moduleSettings.selectedModule.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            {moduleSettings.moduleType === "Placement" && (
              <Typography variant="subtitle1">
                Duration: {moduleSettings.selectedModule.duration} minutes
              </Typography>
            )}
            <Typography variant="subtitle1">
              Total Questions: {moduleSettings.selectedModule.totalQuestions}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {moduleSettings.moduleType !== "Training" && 
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Instructions for SolverEdge Problem Solving Training
            </Typography>}
          </Grid>
          {moduleSettings.moduleType !== "Training" && (
            <><Grid item xs={12}>
              <Typography variant="body1" component={"span"}>
                {parse(moduleSettings.selectedModule.rules)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component={"span"}>
                {parse(moduleSettings.selectedModule.outcome)}
              </Typography>
            </Grid></>)}
          {moduleSettings.moduleType === "Training" && instructionStep === 1 && 
            <Grid item xs={12}>
              <Typography variant="body1" component={"span"}>
                {parse(moduleSettings.selectedModule.rules)}
              </Typography>
            </Grid>}
          {moduleSettings.moduleType === "Training" && instructionStep === 2 &&
            <Grid item xs={12}>
              <Typography variant="body1" component={"span"}>
                {parse(moduleSettings.selectedModule.outcome)}
              </Typography>
            </Grid>}
          {moduleSettings.moduleType === "Training" && instructionStep === 3 && moduleSettings.selectedModule.Medias[0]?.Media.source &&
            <>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <Typography variant="body1" component={"span"}>
                  {parse(moduleSettings.selectedModule.Medias[0]?.Media.source)}
                </Typography>
              </Grid>
              <Grid item xs={3} />
            </>
          }
          {moduleSettings.moduleType === "Training" && <Grid item xs={12}>
            <Box display={"flex"} justifyContent="center">
              {instructionStep !== 1 && <Button
                // variant="contained"
                onClick={handlePrevStep}
                sx={{ mr: 4 }}
                startIcon={<ChevronLeft />}
              >
                {instructionStep === 2 ? "Rules" : "Outcome"}
              </Button>}
              {instructionStep !== 3 && <Button
                // variant="contained"
                onClick={handleNextStep}
                endIcon={<ChevronRightIcon />}
              >
                {instructionStep === 1 ? "Outcome" : "Video" }
              </Button>}
            </Box>
          </Grid>}
          <Grid item xs={12}><Divider light="false" sx={{ mt: 1, mb: 1 }} /></Grid>
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent="center">
              {location.from !== "/studenttraining" && location.from !== "/training-question" && <Button
                variant="contained"
                onClick={goToDashboard}
                sx={{ mr: 4 }}
              >
                Return to Dashboard
              </Button>}
              <Button
                variant="contained"
                onClick={
                  location.from === "/training-question"
                    ? () => history.push(location.from)
                    : startTest
                }
              >
                {location.from === "/training-question"
                  ? "return"
                  : `Start ${
                      (moduleSettings.moduleType === "Training" || moduleSettings.moduleType === "Interactive")
                        ? "Training"
                        : "Test"
                    }`}
              </Button>
            </Box>
          </Grid>
        </>
      )}
      {moduleSettings.moduleType === "Diagnostic" && (
        <>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant="h4">
              {moduleSettings.selectedModule.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Instructions for SolverEdge Behavioural Analysis Assessment
            </Typography>
            <br />
            <Typography variant="body1">
              Well done on completing the first part of the Problem Solving Behaviour & Ability test.
            </Typography>
            <Typography variant="body1">
              In the second part of this test, we will present the questions you have just answered in a different way. To help you improve your problem solving ability, we will provide you some hints & tips to help you answer the question correctly and collect useful diagnostic information. Please try your best to answer all the questions. 
            </Typography>
            <Typography variant="body1">
              <strong>You can press the SKIP QUESTION button at any time.</strong>
            </Typography>
            <Typography variant="body1">
              All you need to do is try your best!
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography variant="body1">
              Please read the Instructions carefully before starting the test.
            </Typography>
          </Grid> */}
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent="center">
              <Button
                variant="contained"
                onClick={goToDashboard}
                sx={{ mr: 4 }}
              >
                Return to Dashboard
              </Button>
              <Button variant="contained" onClick={startTest}>
                Start Diagnostic Test
              </Button>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Instructions;
