import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  FormHelperText,
  DialogActions,
  IconButton,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import { Box } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AuthenticatedContext } from "../App";
import EditIcon from "@mui/icons-material/Edit";
import { UPDATE_STAFF } from "../graphql/mutations";

const schema = yup.object({
  givenname: yup.string().max(45).required("Required"),
  surname: yup.string().max(45).required("Required"),
  roleId: yup.number().typeError("Required").required("Required"),
  regionId: yup.number(),
  email: yup.string().max(500).required("Required"),
});

const EditUser = ({ params, roles, regions, getStaffs, staffId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);

  const [updateStaff] = useMutation(UPDATE_STAFF, {
    onCompleted: ({ updateStaff }) => {
      getStaffs();
      enqueueSnackbar(
        `User: ${params?.id} has been successfully updated`,
        {
          variant: "success",
        }
      );
      handleClose();
    },
    onError: (error) => {
      enqueueSnackbar(`Error occured: ${error}`, {
        variant: "error",
      });
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      givenname: "",
      surname: "",
      roleId: 0,
      regionId: 0,
      email: ""
    },
  });

  useEffect(() => {
    if (open) {
      reset({
        givenname: params?.row.givenname || "",
        surname: params?.row.surname || "",
        roleId:
          roles.find((role) => role.name == params?.row.Role.name)["roleId"] || "",
        regionId:
          regions.find((region) => region.name == params?.row.Region?.name)[
            "id"
          ] || "",
        email: params?.row.easyEmail
      });
    }
  }, [open]);

  const onSubmit = ({ surname, givenname, roleId, regionId, email }) => {
    updateStaff({
      variables: {
        id: Number(params.id),
        surname,
        givenname,
        roleId,
        regionId,
        email,
      },
    });
    reset({
      surname: "",
      givenname: "",
      roleId: 0,
      regionId: 0,
      email: ""
    });
  };

  return (
    <>
      <IconButton size="small" color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ pt: 1, pb: 1 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="givenname"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      error={errors.givenname ? true : false}
                    >
                      <InputLabel>Given Name</InputLabel>
                      <OutlinedInput {...field} label="Given Name" />
                      <FormHelperText sx={{ color: "primary.main" }}>
                        {errors.givenname?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="surname"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      error={errors.surname ? true : false}
                    >
                      <InputLabel>Surname</InputLabel>
                      <OutlinedInput {...field} label="Surname" />
                      <FormHelperText sx={{ color: "primary.main" }}>
                        {errors.surname?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      error={errors.email ? true : false}
                    >
                      <InputLabel>Email</InputLabel>
                      <OutlinedInput {...field} label="Email" />
                      <FormHelperText sx={{ color: "primary.main" }}>
                        {errors.email?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="roleId"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors.roleId ? true : false}
                    >
                      <InputLabel>Role</InputLabel>
                      <Select label="Role" {...field} defaultValue="">
                        {roles?.map(({ roleId, name }) => (
                          <MenuItem value={roleId} key={roleId}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ color: "primary.main" }}>
                        {errors.roleId?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="regionId"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth error={errors.regionId ? true : false}>
                      <InputLabel>Region</InputLabel>
                      <Select label="Region" {...field} defaultValue="">
                        {regions?.map(({ id, name }) => (
                          <MenuItem value={id} key={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    //disabled={}
                    sx={{ mr: 2 }}
                  >
                    Submit
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    //disabled={}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditUser;
