import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tabs,
  Tab,
  Box,
  Typography,
  Stack,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Select,
  MenuItem,
  Autocomplete,
  TextField
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { DataGridPro, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { AuthenticatedContext, PreferenceContext } from "../App";
import { useSnackbar } from "notistack";
import { GET_CENTRES, GET_COURSE_CURRICULA, GET_COURSE_REGISTRATIONS, GET_EASY_COURSES, GET_EASY_TERMS, GET_GRADES, GET_NSDC_GRADES, GET_PROGRAM_REGISTRATIONS, GET_REGIONS, GET_SITTINGS, GET_STUDENT_SITTINGS } from "../graphql/queries";
import { CREATE_COURSE_CURRICULUM, CREATE_COURSE_REGISTRATION, CREATE_PROGRAM_REGISTRATION, DELETE_COURSE_CURRICULUM, DELETE_COURSE_REGISTRATION, DELETE_PROGRAM_REGISTRATION, DELETE_SITTING } from "../graphql/mutations";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";

const schema = yup.object({
  grade: yup.object().required(),
  numWeeks: yup.number().required().positive().integer()
});

const CourseCurriculum = ({ course_id }) => {
  const { preferences, setPreferences, defaults } = useContext(PreferenceContext);
  const { userRole } = useContext(AuthenticatedContext);
  // let programPref = {...preferences.progReg};
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [curricula, setCurricula] = useState([]);
  const [openCC, setOpenCC] = useState(false);
  const [grades, setGrades] = useState([]);
  const [openDel, setOpenDel] = useState(false);
  const [chosenCurriculum, setChosenCurriculum] = useState(null);

  const [getCourseCurricula, { regLoading, regError, regData }] = useLazyQuery(GET_COURSE_CURRICULA, {
    onCompleted: (courseCurricula) => {
      setCurricula(courseCurricula.getCourseCurricula);
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (!openCC) {
      getCourseCurricula({
        variables: {
          course_id
        }
      });
    } else {
      getGrades();
    }
  }, [openCC]);

  const [getGrades, { loading: getGradesLoading }] = useLazyQuery(
    GET_GRADES,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ getGrades }) => {
        setGrades(getGrades);
      },
    }
  );

  const [createCourseCurriculum] = useMutation(CREATE_COURSE_CURRICULUM, {
    onCompleted: ({ createCourseCurriculum }) => {
      if (createCourseCurriculum) {
        enqueueSnackbar(
          `Curriculum successfully created`,
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(`There was a problem with creating the curriculum, please try again.`,
          {
            variant: "warning",
          }
        );
      }
    },
    onError: (error) => {
      console.log(error);
    },
    fetchPolicy: "network-only",
  });

  const [deleteCourseCurriculum] = useMutation(DELETE_COURSE_CURRICULUM, {
    onCompleted: (reg) => {
      if (reg.deleteCourseCurriculum === true) {
        enqueueSnackbar(
          `Curriculum deleted sucessfully`,
          {
            variant: "success",
          }
        );
        getCourseCurricula({
          variables: {
            course_id
          }
        });
      } else {
        enqueueSnackbar(
          `Error deleting Course Curriculum`,
          {
            variant: "error",
          }
        );
      }
    },
    fetchPolicy: 'network-only',
  });

  function getGradeName(params) {
    return params.row.Grade?.name;
  };

  function getNumTrainings(params) {
    return params.row.Trainings?.length;
  }

  const viewCurriculum = useCallback(
    (params) => () =>
      history.push(`/curriculum/${params.row.id}`, { data: params.row }),
    []
  );

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        flex: 1,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => {
          let actions = [
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="View"
              color="primary"
              onClick={viewCurriculum(params)}
            />
          ];
          if (["Admin", "Education Consultant"].includes(userRole)) {
            actions.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete Curriculum"
                color="primary"
                onClick={() => { setOpenDel(true); setChosenCurriculum(params) }}
              />
            );
          }
          return actions;
        }
      },
      {
        field: "id",
        headerName: "Map ID",
        flex: 1,
        headerAlign: "center",
        align: "center",
        hide: true
      },
      {
        field: "grade",
        headerName: "Grade",
        flex: 1,
        headerAlign: "center",
        align: "center",
        valueGetter: getGradeName,
      },
      {
        field: "trainings",
        headerName: "# of Trainings",
        flex: 1,
        headerAlign: "center",
        align: "center",
        type: "number",
        valueGetter: getNumTrainings
      },
      {
        field: "numWeeks",
        headerName: "# of Weeks",
        flex: 1,
        headerAlign: "center",
        align: "center",
        type: "number",
      },
    ],
    [viewCurriculum]
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      grade: null,
      numWeeks: 0
    },
  });

  const onSubmit = async ({
    grade,
    numWeeks
  }) => {
    await createCourseCurriculum({
      variables: {
        courseId: course_id,
        gradeId: grade.id,
        numWeeks
      },
    });
    reset({
      grade: null,
    });
    setOpenCC(false);
  };

  const deleteCC = () => {
    deleteCourseCurriculum({ variables: { curriculumId: chosenCurriculum?.row.id }});
    setOpenDel(false);
  }

  return (
    <>
      <Dialog open={openDel} onClose={() => { setChosenCurriculum(null); setOpenDel(false) }}>
        <DialogTitle>Delete Course Curriculum</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this curriculum?{chosenCurriculum?.Trainings?.length > 0 ? ` There are currently ${chosenCurriculum?.Trainings?.length} Training modules mapped to this curriculum.` : ""}
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button onClick={() => setOpenDel(false)}>Cancel</Button>
          <Button onClick={() => deleteCC()}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={() => setOpenCC(false)} open={openCC} maxWidth="sm" fullWidth>
        <DialogTitle>Add New Curriculum</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ pt: 1, pb: 1 }}
          >
            <Grid container spacing={0.1} columnSpacing={1}>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <Controller
                    name="grade"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        variant="outlined"
                        fullWidth
                        required
                        error={errors.grade ? true : false}
                      >
                        <Autocomplete
                          options={grades}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} label="Grade" margin="normal" />}
                          disabled={grades.length === 0}
                          onChange={(event, newValue) => setValue("grade", newValue)}
                        />
                        <FormHelperText sx={{ color: "primary.main" }}>
                          {errors.grade?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <Controller
                      name="numWeeks"
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          variant="outlined"
                          fullWidth
                          required
                          error={errors.numWeeks ? true : false}
                          sx={{ pt: 2 }}
                        >
                          <InputLabel sx={{ pt: 3 }}># of Weeks</InputLabel>
                          <OutlinedInput {...field} label="# of Weeks" />
                          <FormHelperText sx={{ color: "primary.main" }}>
                            {errors.numWeeks?.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Box>
                </Grid>
              <Grid item xs={7} />
              <Grid item xs={5}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  //disabled={}
                  sx={{ mr: 2 }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  //disabled={}
                  onClick={() => setOpenCC(false)}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
        <Paper elevation={0} sx={{ p: 3, minHeight: "100%" }}>
          <Stack direction="row" justifyContent="space-between" sx={{ pb: 3 }}>
            <Box />
            {
              ["Admin", "Education Consultant"].includes(userRole) && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpenCC(true)}
                >
                  Add Curriculum
                </Button>
              )
            }
          </Stack>
          <DataGridPro
            rows={curricula}
            columns={columns}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableSelectionOnClick
            autoHeight
            pagination
            density="compact"
          />
        </Paper>
      </Container>
    </>
  );
};

export default CourseCurriculum;
