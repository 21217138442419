import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";
import ReactAudioPlayer from "react-audio-player";

import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { ModuleContext } from "../../App";

const WordExplanationV2 = ({
  currentQuestion: {
    // QuestionID,
    content,
    // Answer1,
    // Answer2,
    // Answer3,
    // Answer4,
    // Answer5,
    // Answer6,
    // Answer7,
    // Answer8,
    // Answer9,
    // Answer10,
    // CorrectAnswer,
    // Hint,
    // VideoSrc,
    // PassageSrc,
    // Solution,
  },
}) => {
  const options = {
    replace: (node) => {
      if (!node.attribs) {
        return;
      }

      if (node.attribs["data-value"]) {
        const parsedHtml = node.attribs["data-value"];
        return <Latex>{`$${parsedHtml}$`}</Latex>;
      }

      if (node.name === "u") {
        return (
          <Button onClick={(e) => handleWordClick(e, node?.children[0]?.data)}>
            {node?.children[0]?.data}
          </Button>
        );
      }
    },
  };
  const moduleValue = React.useContext(ModuleContext);

  const [viewedWords, setViewedWords] = useState([]);
  const [word, setWord] = useState("");
  const [wordList, setWordList] = useState([]);
  const [wordLoading, setWordLoading] = useState(false);

  const handleWordClick = (e, text) => setWord(text);

  useEffect(() => {
    if (!word) return;

    if (viewedWords.indexOf(word) === -1) {
      const newViewedWords = [...viewedWords, word];
      setViewedWords(newViewedWords);
      moduleValue.setModuleSettings({
        ...moduleValue.moduleSettings,
        selectedAnswer: newViewedWords.join(",")
      })
    }
    setWordList([]);
    setWordLoading(true);

    fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`)
      .then((response) => response.json())
      .then((data) => {
        setWordList(data);
      })
      .catch((e) => {
        return setWordList([]);
      })
      .finally(() => {
        setWordLoading(false);
      });
  }, [word]);

  return (
    <Box sx={{ pb: 2 }}>
      {content && (
        <Typography variant="h6">{parse(content, options)}</Typography>
      )}

      {wordLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {wordList.length !== 0 && (
        <Alert severity="info" sx={{ mb: 2 }}>
          <AlertTitle>Explanation:</AlertTitle>
          <h1>{wordList[0]?.word}</h1>
          <h3>
            Phonetics:{" "}
            {wordList[0]?.phonetic
              ? wordList[0]?.phonetic
              : wordList[0]?.phonetics[0]?.text
              ? wordList[0]?.phonetics[0]?.text
              : wordList[0]?.phonetics[1]?.text
              ? wordList[0]?.phonetics[1]?.text
              : wordList[0]?.phonetics[2]?.text}
          </h3>
          {wordList[0]?.phonetics[0]?.audio ? (
            <ReactAudioPlayer src={wordList[0]?.phonetics[0]?.audio} controls />
          ) : (
            <ReactAudioPlayer src={wordList[0]?.phonetics[1]?.audio} controls />
          )}
          <h3>
            Definition:{" "}
            {wordList[0]?.meanings[0]?.definitions[0].definition
              ? wordList[0]?.meanings[0]?.definitions[0].definition
              : wordList[0]?.meanings[0]?.definitions[1].definition
              ? wordList[0]?.meanings[0]?.definitions[1].definition
              : wordList[0]?.meanings[0]?.definitions[2].definition}
          </h3>
          <h3>
            Synonyms:{" "}
            {wordList[0]?.meanings[0]?.synonyms.length !== 0
              ? wordList[0]?.meanings[0]?.synonyms.join(", ")
              : wordList[0]?.meanings[0]?.definitions[0]?.synonyms?.length !== 0
              ? wordList[0]?.meanings[0]?.definitions[0]?.synonyms?.join(", ")
              : wordList[0]?.meanings[0]?.definitions[1]?.synonyms?.length !== 0
              ? wordList[0]?.meanings[0]?.definitions[1]?.synonyms?.join(", ")
              : wordList[0]?.meanings[0]?.definitions[2]?.synonyms?.join(", ")}
          </h3>
          <h3>
            Example:{" "}
            {wordList[0]?.meanings[0]?.definitions[0]?.example
              ? wordList[0]?.meanings[0]?.definitions[0]?.example
              : wordList[0]?.meanings[0]?.definitions[1]?.example
              ? wordList[0]?.meanings[0]?.definitions[1]?.example
              : wordList[0]?.meanings[0]?.definitions[2]?.example}
          </h3>
        </Alert>
      )}
    </Box>
  );
};

export default WordExplanationV2;
