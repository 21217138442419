import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar, GridActionsCellItem, useGridApiRef, gridQuickFilterValuesSelector } from "@mui/x-data-grid-pro";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { format } from "date-fns";
import { AuthenticatedContext, PreferenceContext } from "../App";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET_COURSE_STUDENTS, GET_REPORT_STUDENTS } from "../graphql/queries";
import { CREATE_NOTIFICATIONS, DEACTIVATE_TRAINING, GENERATE_TRAINING_RECOMMENDATIONS } from "../graphql/mutations";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TestReport from "./TestReport";
// import PlacementReport from "./PlacementReport";

const CourseStudents = ({ course_id }) => {
  const { userRole } = useContext(AuthenticatedContext);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [chosenStudent, setChosenStudent] = useState("");
  const [payments, setPayments] = useState([]);

  const [getCourseStudents, { studentsLoading, studentsError, studentsData}] = useLazyQuery(GET_COURSE_STUDENTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getCourseStudents }) => {
      setRows(getCourseStudents.students);
      setPayments(getCourseStudents.payments);
    }
  });

  const [deactivateTraining] = useMutation(DEACTIVATE_TRAINING, {
    onCompleted: () => {
      getCourseStudents({
        variables: {
          course_id
        }
      });
      setOpen(false);
    }
  });
  
  useEffect(() => {
    getCourseStudents({
      variables: {
        course_id
      }
    });
  }, [course_id]);

  const isDisabled = (params) => {
    return !params.row.Trainings[0].isPaid;
  }

  const startDeactivation = () => {
    deactivateTraining({
      variables: {
        student_id: chosenStudent.row.id,
        course_id
      }
    })
  }

  function getStudentName(params) {
    return params.row.givenname + " " + params.row.surname;
  };

  function getGrade(params) {
    return params.row.Grade?.name;
  };

  function getTeacher(params) {
    return params.row.Teacher?.givenname  && params.row.Teacher.givenname + " " + params.row.Teacher?.surname;
  };

  function getClass(params) {
    return params.row.Classes?.length > 0 ? params.row.Classes[0].Class.name : "";
  }

  function getIsActivated(params) {
    // return params.row.Trainings[2].isPaid;
    return !!params.row.Trainings.find(t => t.registrationId);
  };

  function getCampus(params) {
    return params.row.Centre?.name;
  }

  function getDate(params) {
    const payment = payments.find(p => p.studentId === params.row.id);
    return payment?.paymentMadeAt;
  }

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const columns = useMemo(
    () => [
      // {
      //   field: "actions",
      //   type: "actions",
      //   disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
      //   headerName: "Actions",
      //   flex: 1,
      //   headerAlign: "center",
      //   disableReorder: true,
      //   hideable: false,
      //   getApplyQuickFilterFn: undefined,
      //   getActions: (params) => [
      //     <GridActionsCellItem
      //       icon={<DeleteIcon />}
      //       label="Deactivate Training"
      //       color="primary"
      //       onClick={() => { setOpen(true); setChosenStudent(params) }}
      //       disabled={isDisabled(params) || !["Admin", "Education Consultant"].includes(userRole)}
      //     />,
      //   ],
      // },
      {
        field: "login",
        headerName: "Student Number",
        flex: 2,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "studentName",
        headerName: "Student Name",
        flex: 3,
        headerAlign: "center",
        align: "center",
        filterable: false,
        getApplyQuickFilterFn: getApplyFilterFnName,
        valueGetter: getStudentName,
      },
      {
        field: "grade",
        headerName: "Grade",
        headerAlign: "center",
        align: "center",
        flex: 2,
        getApplyQuickFilterFn: undefined,
        valueGetter: getGrade,
      },
      {
        field: "campus",
        headerName: "Campus",
        headerAlign: "center",
        align: "center",
        flex: 2,
        getApplyQuickFilterFn: undefined,
        valueGetter: getCampus,
      },
      {
        field: "class",
        headerName: "Class",
        flex: 3,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: getApplyFilterFnName,
        valueGetter: getClass,
      },
      // {
      //   field: "teacher",
      //   headerName: "Teacher",
      //   flex: 3,
      //   headerAlign: "center",
      //   align: "center",
      //   getApplyQuickFilterFn: getApplyFilterFnName,
      //   valueGetter: getTeacher,
      // },
      {
        field: "isPaid",
        headerName: "Training Activated?",
        flex: 1,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
        valueGetter: getIsActivated,
        type: "boolean"
      },
      // {
      //   field: "datePaid",
      //   headerName: "Date Paid At",
      //   flex: 1,
      //   headerAlign: "center",
      //   type: "dateTime",
      //   getApplyQuickFilterFn: undefined,
      //   filterable: false,
      //   valueFormatter: ({ value }) => {
      //     if (value) {
      //       return format(value, "dd/MM/yyyy");
      //     }
      //   },
      //   valueGetter: getDate,
      //   align: "center",
      // }
    ],
    [rows, payments]
  );

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Deactivate Training</DialogTitle>
        <DialogContent>
          Are you sure you want to deactivate { chosenStudent.row && `${chosenStudent.row.givenname} ${chosenStudent.row.surname}'s (${chosenStudent.row.login}) `} training modules?
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={() => startDeactivation()}>Deactivate Training</Button>
        </DialogActions>
      </Dialog>
      <DataGridPro
        rows={rows}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true
          }
        }}
        disableSelectionOnClick
        autoHeight
        loading={studentsLoading}
        pagination
        getRowId={(row) => row.login}
        pinnedColumns={{left: ["actions"]}}
      />
    </>
  );

  
  // return (
  //   <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
  //     <Dialog open={notifyDialog}>
  //         <DialogTitle>Send Notifications</DialogTitle>
  //         <DialogContent>
  //           Are you sure you want to send notifications to {selectedRows.length > 0 ? selectedRows.length : "ALL" } students?
  //         </DialogContent>
  //         <DialogActions sx={{justifyContent: "space-between"}}>
  //           <Button onClick={() => setNotifyDialog(false)}>Cancel</Button>
  //           <Button onClick={() => sendNotifications()}>Send Notifications</Button>
  //         </DialogActions>
  //     </Dialog>
  //     <Dialog open={reportDialog} fullScreen>
  //       {(rows.length > 0 && !!(rows[0].count)) ? (
  //         // <PlacementReport openFn={setReportDialog} program_id={program_id} student_id={selectedRow.Student?.id} student_name={selectedRow.Student?.givenname + " " + selectedRow.Student?.surname} student_number={selectedRow.Student?.login} />
  //         <Typography>Hi</Typography>
  //       ) : (
  //         <TestReport openFn={setReportDialog} test_id={test_id} student_id={selectedRow.Student?.id} student_name={selectedRow.Student?.givenname + " " + selectedRow.Student?.surname} />
  //       )}
        
  //     </Dialog>
  //     <Paper elevation={0} sx={{ p: 3 }}>
  //       <Box
  //         sx={{
  //           display: "flex",
  //           mb: 3,
  //           alignItems: "center",
  //           justifyContent: "space-between",
  //         }}
  //       >
  //         <Box />
  //         <Button disabled={selectedRows.length === 0} color="primary" gutterBottom variant="contained" sx={{ mb: 0 }} onClick={() => setNotifyDialog(true)}>
  //           Send Reports
  //         </Button>
  //       </Box>
  //       <DataGridPro
  //         rows={rows}
  //         columns={columns}
  //         components={{ Toolbar: GridToolbar }}
  //         componentsProps={{
  //           toolbar: {
  //             showQuickFilter: true
  //           }
  //         }}
  //         disableSelectionOnClick
  //         autoHeight
  //         loading={studentsLoading}
  //         onColumnWidthChange={handleColumnWidthChange}
  //         onColumnOrderChange={handleColumnOrderChange}
  //         pagination
  //         pinnedColumns={{left: ["actions"]}}
  //         onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
  //         getRowId={(row) => row.Student.id}
  //         checkboxSelection={true}
  //         onSelectionModelChange={(newSelectedRows) => {
  //           setSelectedRows(newSelectedRows)
  //         }}
  //         selectionModel={selectedRows}
  //         isRowSelectable={(params) => params.row.Student.Trainings?.length > 0 && params.row.Student.Notifications?.length === 0}
  //       />
  //     </Paper>
  //   </Container>
  // );
  return <></>
};

export default CourseStudents;
