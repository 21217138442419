import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSnackbar } from "notistack";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { DataGridPro, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid-pro";
import renderCellExpand from "../widgets/renderCellExpand";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { GET_ALLOCATED_STUDENTS_BY_TEST, GET_ALL_CAMPUS_NAMES, GET_ALL_TEST_NAMES, GET_ALL_TRAINING_NAMES, GET_GRADES, GET_STUDENTS_BY_QUERY } from "../graphql/queries";
import { DELETE_REGISTRATION, REGISTER_MODULE } from "../graphql/mutations";

const SelectStudent = ({ test_id, training_id }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [studentName, setStudentName] = useState("");
  const [studentID, setStudentID] = useState("");
  const [grade, setGrade] = useState("");
  const [grades, setGrades] = useState([]);
  const [campus, setCampus] = useState("");
  const [campuses, setCampuses] = useState([]);
  const [trainingModule, setTrainingModule] = useState("");
  const [trainingModules, setTrainingModules] = useState([]);
  const [testModule, setTestModule] = useState("");
  const [testModules, setTestModules] = useState([]);
  const [unselectedStudents, setUnselectedStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteStudentID, setDeleteStudentID] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);

  const [getAllTrainingNames] = useLazyQuery(GET_ALL_TRAINING_NAMES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAllTrainingNames }) => {
      if (getAllTrainingNames?.length !== 0) {
        setTrainingModules(getAllTrainingNames);
      }
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [getAllTestNames] = useLazyQuery(GET_ALL_TEST_NAMES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAllTestNames }) => {
      if (getAllTestNames?.length !== 0) {
        setTestModules(getAllTestNames);
      }
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [getAllCampusNames] = useLazyQuery(GET_ALL_CAMPUS_NAMES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAllCampusNames }) => {
      if (getAllCampusNames?.length !== 0) {
        setCampuses(getAllCampusNames);
      }
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [getGrades] = useLazyQuery(GET_GRADES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getGrades }) => {
      if (getGrades?.length !== 0) {
        setGrades(getGrades);
      }
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [getAllocatedStudentsByTest, { data: selectedStudentsData }] = useLazyQuery(GET_ALLOCATED_STUDENTS_BY_TEST, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAllocatedStudentsByTest }) => {
      setSelectedStudents(getAllocatedStudentsByTest);
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [getStudentsByQuery, { loading: getStudentsByQueryLoading }] = useLazyQuery(GET_STUDENTS_BY_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getStudentsByQuery }) => {
      if (getStudentsByQuery.length !== 0) {
        if (selectedStudentsData) {
          const studentsIDs = selectedStudentsData?.getAllocatedStudentsByTest?.map(s => s.id);
          const filteredStudents = getStudentsByQuery.filter(s => {
            return !studentsIDs.includes(s.id);
          })

          if (filteredStudents?.length !== 0) {
            setUnselectedStudents(filteredStudents);
          } else {
            enqueueSnackbar("No student found", { variant: "warning" });
            clearSearchData();
          }
        } else {
          setUnselectedStudents(getStudentsByQuery);
        }
      } else {
        enqueueSnackbar("No student found", { variant: "warning" });
        clearSearchData();
      }
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    }
  });

  const [deleteRegistration] = useMutation(DELETE_REGISTRATION, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ deleteRegistration }) => {
      if (deleteRegistration) {
        enqueueSnackbar("Registration has been successfully deleted. ", { variant: "success" })
        await getStudentsByQuery({ variables: {
          ...(studentName ? { student_name: studentName } : {}),
          ...(studentID ? { student_id: Number(studentID) } : {}),
          ...(trainingModule ? { training_module: Number(trainingModule.id) } : {}),
          ...(testModule ? { test_module: Number(testModule.id) } : {}),
          ...(test_id ? { context: "Test" } : {}),
          ...(training_id ? { context: "Training" } : {}),
          ...(grade ? { grade_id: Number(grade.id) } : {}),
        }});
        setDeleteDialog(false);
      }
    },
    ...(test_id && { refetchQueries: [{ query: GET_ALLOCATED_STUDENTS_BY_TEST, variables: { test_id: Number(test_id) }}, 'getAllocatedStudentsByTest'] }),
    ...(training_id && { refetchQueries: [{ query: GET_ALLOCATED_STUDENTS_BY_TEST, variables: { test_id: Number(test_id) }}, 'getAllocatedStudentsByTest'] }),
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  })

  const [registerModule] = useMutation(REGISTER_MODULE, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ registerModule }) => {
      if (registerModule) {
        enqueueSnackbar(`${selectionModel?.length} Student(s) has been successfully registered.`, { variant: "success" })
        if (selectionModel) {
          setUnselectedStudents(unselectedStudents?.filter(s => !selectionModel.includes(s.id)))
        }
      }
    },
    ...(test_id && { refetchQueries: [{ query: GET_ALLOCATED_STUDENTS_BY_TEST, variables: { test_id: Number(test_id) }}, 'getAllocatedStudentsByTest'] }),
    ...(training_id && { refetchQueries: [{ query: GET_ALLOCATED_STUDENTS_BY_TEST, variables: { test_id: Number(test_id) }}, 'getAllocatedStudentsByTest'] }),
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  })

  // useEffect(() => {
  //   getAllTrainingNames();
  //   if (teacher_id) getAllocatedStudentsByTeacher({ variables: { teacher_id: Number(teacher_id) }})
  // }, [teacher_id]);

  useEffect(() => {
    if (test_id) {
      getAllTestNames();
      getGrades();
      getAllCampusNames();
      getAllocatedStudentsByTest({ variables: { test_id: Number(test_id) } });
    }
  }, [test_id]);

  const clearSearchData = () => {
    setUnselectedStudents([]);
    setStudentID("");
    setStudentName("");
    setTrainingModule("");
    setTestModule("");
    setGrade("");
    setCampus("");
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getStudentsByQuery({ variables: {
      ...(studentName ? { student_name: studentName } : {}),
      ...(studentID ? { student_id: studentID } : {}),
      ...(trainingModule ? { training_module: Number(trainingModule.id) } : {}),
      ...(testModule ? { test_module: Number(testModule.id) } : {}),
      ...(test_id ? { context: "Test" } : {}),
      ...(training_id ? { context: "Training" } : {}),
      ...(grade ? { grade_id: Number(grade.id) } : {}),
      ...(campus ? { centre_id: Number(campus.id) } : {}),
    }});
  };

  const removeStudent = useCallback(
    (params) => () => 
      {
        setDeleteStudentID(params.id);
        setDeleteDialog(true);
      },
    []
  );

  const handleDeallocateStudent = () => {
    deleteRegistration({ 
      variables: { 
        ...(test_id && { module_id: Number(test_id), module_type: "Placement" }),
        ...(training_id && { module_id: Number(training_id), module_type: "Exercise" }),
        student_id: Number(deleteStudentID)
      }
    });
  };

  const handleAddStudentsToTest = () => {
    registerModule({ 
      variables: { 
        module_id: Number(test_id),
        module_type: "Placement",
        student_ids: selectionModel 
      }
    });
  };

  const unselectedStudentColumns = [
    {
      field: "login",
      headerName: "Student Number",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Student Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => params.row.givenname + " " + params.row.surname,
    },
    {
      field: "campus",
      headerName: "Campus",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (params.row.campus && params.row.campus) + " " + (params.row.region && params.row.region)
    },
    {
      field: "grade",
      headerName: "Grade",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ]

  const selectedStudentColumns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true,
        headerName: "Actions",
        flex: 1,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Remove"
            color="primary"
            onClick={removeStudent(params)}
          />,
        ],
      },
      {
        field: "login",
        headerName: "Student Number",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "name",
        headerName: "Student Name",
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => params.row.givenname + " " + params.row.surname,
      },
      {
        field: "module",
        headerName: "Enrolled Module(s)",
        flex: 3,
        headerAlign: "center",
        align: "center",
        hide: !!test_id,
        ...(training_id && { valueGetter: (params) => params.row.Trainings.map(t => t.name).join(", ") }),
        renderCell: renderCellExpand
      },
      {
        field: "campus",
        headerName: "Campus",
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (params.row.campus && params.row.campus) + " " + (params.row.region && params.row.region)
      },
      {
        field: "grade",
        headerName: "Grade",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
    ],
    [removeStudent]
  )
  
  return (
      <Paper elevation={0} sx={{ p: 1 }} >
        <Dialog open={deleteDialog}>
          <DialogTitle>Deselect Student</DialogTitle>
          <DialogContent>
            Are you sure you want to deselect this student?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
            <Button onClick={handleDeallocateStudent}>Confirm</Button>
          </DialogActions>
        </Dialog>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSearchSubmit}
          sx={{ mb: 3 }}
        >
          <Grid container spacing={4}>
            <Grid item xs={2}>
              <TextField
                fullWidth
                id="studentName"
                label="Student Name"
                value={studentName}
                size="small"
                onChange={e => setStudentName(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                id="studentID"
                type="number"
                label="Student Number"
                value={studentID}
                size="small"
                onChange={e => setStudentID(e.target.value)}
              />
            </Grid>
            <Grid item xs={3.5}>
            { grades && (
                <Autocomplete
                  name="grade"
                  value={grade}
                  options={grades}
                  onChange={(e, options) => setGrade(options)}
                  getOptionLabel={(option) => 
                    option.name ? option.name : ""
                  }
                  renderOption={(props, option) => 
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Select Grade"
                      placeholder="Select Grade"
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={3.5}>
              { training_id && (
                <Autocomplete
                  name="trainingModule"
                  value={trainingModule}
                  options={trainingModules}
                  onChange={(e, options) => setTrainingModule(options)}
                  getOptionLabel={(option) => 
                    option.name ? option.name : ""
                  }
                  renderOption={(props, option) => 
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Select Training Module"
                      placeholder="Select Training Module"
                    />
                  )}
                />
              )}
              { campuses && (
                <Autocomplete
                  name="campuses"
                  value={campus}
                  options={campuses}
                  onChange={(e, options) => setCampus(options)}
                  getOptionLabel={(option) => 
                    option.name ? option.name : ""
                  }
                  renderOption={(props, option) => 
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Select Campus"
                      placeholder="Select Campus"
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<SearchIcon />}
                type="submit"
              >
                Search Student(s)
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ mb: 3 }} />
        <Grid container sx={{ background: "#F8F8F8", border: "1px solid lightgray", p: 2, borderRadius: 1.2 }}>
          <Grid item xs={5.9}>
            <Typography color="primary" sx={{ mb: 2, fontWeight: 500 }}>
              Unselected Students
            </Typography>
            <DataGridPro
              rows={unselectedStudents}
              columns={unselectedStudentColumns}
              // componentsProps={{
              //   toolbar: {
              //     showQuickFilter: true,
              //     quickFilterProps: { debounceMs: 500 },
              //   }
              // }}
              rowsPerPageOptions={[10, 25, 50, 100]}
              disableSelectionOnClick
              checkboxSelection={true}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              autoHeight
              loading={getStudentsByQueryLoading}
              pagination={true}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
              density="compact"
            />
          </Grid>
          <Grid item xs={0.2}></Grid>
          <Grid item xs={5.9}>
            <Typography color="primary" sx={{ mb: 2, fontWeight: 500 }}>
              Selected Students
            </Typography>
            <DataGridPro
              rows={selectedStudents}
              columns={selectedStudentColumns}
              components={{ Toolbar: GridToolbar }}
              density="compact"
              // componentsProps={{
              //   toolbar: {
              //     showQuickFilter: true,
              //     quickFilterProps: { debounceMs: 500 },
              //   }
              // }}
              rowsPerPageOptions={[10, 25, 50, 100]}
              disableSelectionOnClick
              autoHeight
              // loading={getStudentsByQueryLoading}
              pagination={true}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
            />
          </Grid>
          <Grid item xs={2} sx={{ mt: 2 }}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={selectionModel && selectionModel?.length === 0}
              onClick={handleAddStudentsToTest}
              startIcon={<AddCircleIcon />}
            >
              Add Student(s)
            </Button>
          </Grid>
        </Grid>
        
      </Paper>
  )
}

export default SelectStudent