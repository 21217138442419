import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid-pro";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import { PreferenceContext } from "../App";
import { GET_ALL_REPORT_TYPES, GET_REPORTS } from "../graphql/queries";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { CREATE_REPORT } from "../graphql/mutations";

const ReportManagement = () => {
  const { preferences, setPreferences } = React.useContext(PreferenceContext);
  let reportMgmtPref = {...preferences.reportManagement};
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(reportMgmtPref.pageSize || 10);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [reportTypes, setReportTypes] = useState([]);
  const schema = yup
  .object({
    name: yup.string().max(100).required("Required"),
    reportType: yup.object().required("Required"),
  })
  .required();
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      staffGivenname: "",
      staffSurname: "",
    },
  });

  const [getReports, { reportsLoading, reportsError, reportsData }] = useLazyQuery(GET_REPORTS, {
    onCompleted: (reports) => {
      if (reports?.getReports.reports.length > 0) {
        setRows(reports.getReports.reports);
      }
      setRowCount(reports.getReports.total);
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [getAllReportTypes, { reportTypesLoading, reportTypesError, reportTypesData }] = useLazyQuery(GET_ALL_REPORT_TYPES, {
    onCompleted: (reportTypes) => {
      if (reportTypes?.getAllReportTypes.length > 0) {
        setReportTypes(reportTypes.getAllReportTypes);
        setOpen(true);
      }
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [createReport] = useMutation(CREATE_REPORT, {
    onError: (error) => {
      console.log(`create report error: ${error}`);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getReports({
      variables: {
        skip: page * pageSize,
        take: pageSize,
        filters: reportMgmtPref.filters,
        sort: reportMgmtPref.sort,
      }
    });
  }, [page]);

  const handlePageSizeChange = (newPageSize) => {
    reportMgmtPref.pageSize = newPageSize;
    setPageSize(newPageSize);
    setPreferences({
      ...preferences,
      reportManagement: {
        ...reportMgmtPref
      }
    });
    if (page === 0) {
      getReports({
        variables: {
          skip: page * newPageSize,
          take: newPageSize,
          filters: reportMgmtPref.filters,
          sort: reportMgmtPref.sort,
        }
      });
    } else {
      setPage(0);
    }
  };

  const handleColumnWidthChange = (params) => {
    reportMgmtPref.widths[params.colDef.field] = params.width;
    setPreferences({
      ...preferences,
      reportManagement: {
        ...reportMgmtPref
      }
    });
  };

  const handleColumnOrderChange = (params) => {
    reportMgmtPref.columns.splice(params.oldIndex, 1);
    reportMgmtPref.columns.splice(params.targetIndex, 0, params.field);
    setPreferences({
      ...preferences,
      reportManagement: {
        ...reportMgmtPref
      }
    });
  };

  const handleColumnVisibilityModelChange = (model) => {
    reportMgmtPref.visible = {...model};
    setPreferences({
      ...preferences,
      reportManagement: {
        ...reportMgmtPref
      }
    });
  }

  const handleSortModelChange = (model) => {
    reportMgmtPref.sort = [...model];

    setPreferences({
      ...preferences,
      reportManagement: {
        ...reportMgmtPref
      }
    });
    if (page === 0) {
      getReports({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filters: reportMgmtPref.filters,
          sort: [...model],
        }
      });
    } else {
      setPage(0);
    }
  };

  const handleFilterModelChange = (model) => {
    reportMgmtPref.filters = {...model};

    
    setPreferences({
      ...preferences,
      reportManagement: {
        ...reportMgmtPref
      }
    });
    if (page === 0) {
      getReports({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filters: {...model},
          sort: reportMgmtPref.sort,
        }
      });
    } else {
      setPage(0);
    }
  };

  const viewReport = useCallback(
    (params) => () =>
      history.push(`/reportmanagement/${params.row.id}`, { data: params.row }),
    []
  );

  function getIsViewable(params) {
    return params.row.isActivated ? "Y" : "N";
  }

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        width: reportMgmtPref?.widths?.actions,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            color="primary"
            onClick={viewReport(params)}
          />,
        ],
      },
      {
        field: "name",
        headerName: "Name",
        width: reportMgmtPref?.widths?.name,
        headerAlign: "center",
        headerAlign: "center",
        align: "center",
        hide: !reportMgmtPref?.visible?.name,
        filterable: false,
        getApplyQuickFilterFn: getApplyFilterFnName,
      },
      {
        field: "reportType",
        headerName: "Report Type",
        width: reportMgmtPref?.widths?.reportType,
        headerAlign: "center",
        align: "center",
        hide: !reportMgmtPref?.visible?.reportType,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "isViewable",
        headerName: "Is Viewable?",
        width: reportMgmtPref?.widths?.isViewable,
        headerAlign: "center",
        align: "center",
        hide: !reportMgmtPref?.visible?.isViewable,
        getApplyQuickFilterFn: undefined,
        valueGetter: getIsViewable,
      },
      {
        field: "createdAt",
        headerName: "Created At",
        width: reportMgmtPref?.widths?.createdAt,
        headerAlign: "center",
        hide: !reportMgmtPref?.visible?.createdAt,
        type: "date",
        getApplyQuickFilterFn: undefined,
        filterable: false,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
      },
      {
        field: "updatedAt",
        headerName: "Updated At",
        width: reportMgmtPref?.widths?.updatedAt,
        headerAlign: "center",
        hide: !reportMgmtPref?.visible?.updatedAt,
        type: "date",
        getApplyQuickFilterFn: undefined,
        filterable: false,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
      }
    ],
    [viewReport]
  );
  columns.sort((a, b) => reportMgmtPref.columns.indexOf(a.field) - reportMgmtPref.columns.indexOf(b.field));

  const onSubmit = ({
    name,
    reportType,
  }) => {
    createReport({
      variables: {
        name: name,
        report_type_id: reportType.id
      },
    });
    getReports({
      variables: {
        skip: page * pageSize,
        take: pageSize,
        filters: reportMgmtPref.filters,
        sort: reportMgmtPref.sort,
      }
    });
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} fullWidth sx={{padding: 10}}>
        <DialogTitle>Create Report</DialogTitle>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ pl: 2, pr: 2, pb: 2 }}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={errors.name ? true : false}
              >
                <InputLabel>Name</InputLabel>
                <OutlinedInput {...field} label="Name" />
                <FormHelperText sx={{ color: "primary.main" }}>
                  {errors.name?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name="reportType"
            control={control}
            render={({ field }) => (
              <FormControl
                fullWidth
                required
                error={errors.reportType ? true : false}
              >
                <InputLabel>Type</InputLabel>
                <Select label="Type" {...field} defaultValue="">
                  {reportTypes.map((item) => (
                    <MenuItem value={item} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ color: "primary.main" }}>
                  {errors.reportType?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          <DialogActions>
            <Stack space={2} direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button 
                type="submit"
                variant="contained"
              >
                Create
              </Button>
            </Stack>
          </DialogActions>
        </Box>
        
      </Dialog>
      <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
        <Paper elevation={0} sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              mb: 3,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
              Report Management
            </Typography>
          </Box>
          <Stack space={2} direction="row" justifyContent="space-between">
            <Box />
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={getAllReportTypes}
            >
              Create Report
            </Button>
          </Stack>
          <br />
          <DataGridPro
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true
              }
            }}
            pageSize={reportMgmtPref.pageSize}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableSelectionOnClick
            autoHeight
            loading={reportsLoading}
            onColumnWidthChange={handleColumnWidthChange}
            onColumnOrderChange={handleColumnOrderChange}
            pagination
            pinnedColumns={{left: ["actions"]}}
            onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            filterModel={reportMgmtPref.filters}
            sortModel={reportMgmtPref.sort}
            page={page}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            rowCount={rowCount}
          />
        </Paper>
      </Container>
    </>
  );
};

export default ReportManagement;
