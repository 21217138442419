import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ClassMonitoring from "../components/ClassDetailManagement/ClassMonitoring";
import SelectStudent from "../components/ClassDetailManagement/SelectStudent";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SelectTeacher from "../components/ClassDetailManagement/SelectTeacher";


const ClassDetailManagement = () => {
  const { id } = useParams();

  return (
    <Box sx={{ p: 3 }}>
      <Accordion sx={{ border: "1px solid lightgray"}} defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="teacher-details-1"
        >
          <Stack direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}>
            <InfoOutlinedIcon sx={{ mr: 1 }} />
            <Typography>Allocate Teacher</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <SelectTeacher />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ border: "1px solid lightgray"}} defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="test-details-1"
        >
          <Stack direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}>
            <InfoOutlinedIcon sx={{ mr: 1 }} />
            <Typography>Allocate Student</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <SelectStudent moduleId={Number(id)} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ClassDetailManagement;