import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PackageUpdateDetails from "../components/PackageUpdateDetails";
import SelectTest from "../components/SelectTest";
import ReportStudents from "../components/ReportStudents";
import { GET_ALLOCATED_TESTS_BY_PROGRAM } from "../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import TrainingAllocation from "../components/TrainingAllocation";
import ProgramRegistrations from "../components/ProgramRegistrations";
import { AuthenticatedContext } from "../App";
import DiagnosticSittings from "../components/DiagnosticSittings";

const ProgramDetailManagement = () => {
  const { id } = useParams();
  const [programTests, setProgramTests] = useState([]);
  const [test, setTest] = useState("");
  const { userRole } = useContext(AuthenticatedContext);
  const [getAllocatedTestsByProgram, { data: selectedTestsData }] = useLazyQuery(GET_ALLOCATED_TESTS_BY_PROGRAM, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAllocatedTestsByProgram }) => {
      setProgramTests(getAllocatedTestsByProgram);
    },
    onError: error => {
      console.error(error);
    }
  });

  useEffect(() => {
    if (id) {
      getAllocatedTestsByProgram({
        variables: {
          program_id: Number(id)
        }
      });
    }
  }, [id]);

  return (
    <Box
    sx={{backgroundColor: "white"}}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="program-details-1"
        >
          <Typography>Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PackageUpdateDetails packageId={Number(id)} packageType="Program" />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="assessment-list-1"
        >
          <Typography>Assessment List</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SelectTest program_id={Number(id)} />
        </AccordionDetails>
      </Accordion>
      {
        ["Admin", "Education Consultant"].includes(userRole) && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              id="program-registration"
            >
              <Typography>Student Registration</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProgramRegistrations program_id={Number(id)} />
            </AccordionDetails>
          </Accordion>
        )
      }
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="diag-list-1"
        >
          <Typography>Diagnostic Sittings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DiagnosticSittings programId={Number(id)} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="summary-report-1"
        >
          <Typography>Summary Report</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReportStudents program_id={Number(id)} key="program" />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="allocate-training-1"
        >
          <Typography>Training Allocation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TrainingAllocation program_id={Number(id)} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ProgramDetailManagement;
