import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSnackbar } from "notistack";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { GET_ALLOCATED_TRAININGS_BY_COURSE, GET_ALLOCATED_TRAININGS_BY_STUDENT, GET_TRAININGS_BY_QUERY, GET_UNREGISTERED_TRAININGS } from "../graphql/queries";
import { ALLOCATE_TRAININGS_TO_COURSE, ALLOCATE_TRAININGS_TO_STUDENT, DEALLOCATE_STUDENT_TRAINING, DEALLOCATE_TRAINING } from "../graphql/mutations";
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import moment from "moment";

const SelectStudentTraining = ({ student_id }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [unselectedTrainings, setUnselectedTrainings] = useState([]);
  const [selectedTrainings, setSelectedTrainings] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteTrainingID, setDeleteTrainingID] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [dueDate, setDueDate] = useState(moment().format());
  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);

  const [getAllocatedTrainingsByStudent, { data: selectedTrainingsData }] = useLazyQuery(GET_ALLOCATED_TRAININGS_BY_STUDENT, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAllocatedTrainingsByStudent }) => {
      setSelectedTrainings(getAllocatedTrainingsByStudent);
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [getUnregisteredTrainings, { loading: trainingsLoading }] = useLazyQuery(GET_UNREGISTERED_TRAININGS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getUnregisteredTrainings }) => {
      setUnselectedTrainings(getUnregisteredTrainings);
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [deallocateStudentTraining] = useMutation(DEALLOCATE_STUDENT_TRAINING, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ deallocateStudentTraining }) => {
      if (deallocateStudentTraining) {
        enqueueSnackbar("Training has been successfully deallocated. ", { variant: "success" })
        setDeleteDialog(false);
      }
    },
    refetchQueries: [{ query: GET_ALLOCATED_TRAININGS_BY_STUDENT, variables: { student_id: Number(student_id) }}, 'getAllocatedTrainingsByStudent'],
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [allocateTrainingsToStudent] = useMutation(ALLOCATE_TRAININGS_TO_STUDENT, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ allocateTrainingsToStudent }) => {
      if (allocateTrainingsToStudent) {
        enqueueSnackbar(`${selectionModel?.length} Trainings(s) has been successfully allocated.`, { variant: "success" })
        if (selectionModel) {
          setUnselectedTrainings(unselectedTrainings?.filter(t => !selectionModel.includes(t.id)));
          getUnregisteredTrainings({ variables: { student_id: Number(student_id) }});
        }
      }
    },
    refetchQueries: [{ query: GET_ALLOCATED_TRAININGS_BY_STUDENT, variables: { student_id: Number(student_id) }}, 'getAllocatedTrainingsByStudent'],
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  useEffect(() => {
    if (student_id) {
      getAllocatedTrainingsByStudent({ variables: { student_id: Number(student_id) }});
      getUnregisteredTrainings({ variables: { student_id: Number(student_id) }});
    }
  }, [student_id]);

  const removeTraining = useCallback(
    (params) => () => 
      {
        setDeleteTrainingID(params.id);
        setDeleteDialog(true);
      },
    []
  )

  const handleDeallocateTraining = () => {
    deallocateStudentTraining({ variables: { student_id: Number(student_id), training_id: Number(deleteTrainingID) }})
  }

  const handleAddTrainingsToCourse = () => {
    allocateTrainingsToStudent({ variables: { student_id: Number(student_id), training_ids: selectionModel, due_date: dueDate }})
  }

  const unselectedTrainingColumns = [
    {
      field: "id",
      headerName: "Training ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Training Name",
      flex: 5,
      headerAlign: "center",
      align: "center",
    },
  ]

  const selectedTrainingColumns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true,
        headerName: "Actions",
        flex: 1,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Remove"
            color="primary"
            onClick={removeTraining(params)}
          />,
        ],
      },
      {
        field: "id",
        headerName: "Training ID",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "name",
        headerName: "Training Name",
        flex: 5,
        headerAlign: "center",
        align: "center",
      },
    ],
    [removeTraining]
  )
  
  return (
      <Paper elevation={0} sx={{ p: 1 }} >
        <Dialog open={deleteDialog} fullWidth>
          <DialogTitle>Deselect Training</DialogTitle>
          <DialogContent>
            Are you sure you want to deselect this training?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
            <Button onClick={handleDeallocateTraining}>Confirm</Button>
          </DialogActions>
        </Dialog>
        <Grid container sx={{ background: "#F8F8F8", border: "1px solid lightgray", p: 2, borderRadius: 1.2 }}>
          <Grid item xs={5.9}>
            <Typography color="primary" sx={{ mb: 2, fontWeight: 500 }}>
              Unselected Trainings
            </Typography>
            <DataGridPro
              rows={unselectedTrainings}
              columns={unselectedTrainingColumns}
              rowsPerPageOptions={[10, 25, 50, 100]}
              disableSelectionOnClick
              checkboxSelection={true}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              autoHeight
              loading={trainingsLoading}
              pagination={true}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
              density="compact"
            />
          </Grid>
          <Grid item xs={0.2}></Grid>
          <Grid item xs={5.9}>
            <Typography color="primary" sx={{ mb: 2, fontWeight: 500 }}>
              Selected Trainings
            </Typography>
            <DataGridPro
              rows={selectedTrainings}
              columns={selectedTrainingColumns}
              density="compact"
              rowsPerPageOptions={[10, 25, 50, 100]}
              disableSelectionOnClick
              autoHeight
              // loading={getStudentsByQueryLoading}
              pagination={true}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
            />
          </Grid>
          <Grid item xs={4} sx={{ mt: 2 }}>
            <DateTimePicker
              renderInput={(params) => <TextField {...params} />} 
              label="Available From"
              inputFormat="dd/MM/yyyy hh:mm"
              value={dueDate}
              onChange={(newDueDate) => setDueDate(newDueDate)}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={2} sx={{ mt: 2 }}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={selectionModel && selectionModel?.length === 0}
              onClick={handleAddTrainingsToCourse}
              startIcon={<AddCircleIcon />}
            >
              Add Training(s)
            </Button>
          </Grid>
        </Grid>
        
      </Paper>
  )
}

export default SelectStudentTraining;