import React, { useState, useContext, useEffect } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  Badge,
  Dialog,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from '@mui/icons-material/Notifications';
import moment from "moment";
import { AuthenticatedContext, ModuleContext } from "../App";
import { Link } from "react-router-dom";
import { GET_NOTIFICATIONS } from "../graphql/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import CircleIcon from '@mui/icons-material/Circle';
import TestReport from "./TestReport";
import { SET_NOTIFICATION_READ } from "../graphql/mutations";
// import PlacementReport from "./PlacementReport";

const UserDialog = () => {
  const { userRole, user, logoutSuccess } = useContext(AuthenticatedContext);
  const { moduleSettings, setModuleSettings, mfSaveAnswer, updateSitting } = useContext(ModuleContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifAnchorEl, setNotifAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [reportDialog, setReportDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const location = useLocation();
  const history = useHistory();

  const [getNotifications, { notificationsLoading, notificationsError, notificationsData }] = useLazyQuery(GET_NOTIFICATIONS, {
    onCompleted: (notifications) => {
      if (notifications?.getNotifications.length > 0) {
        setNotifications(notifications.getNotifications);
      }
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [setNotificationRead] = useMutation(SET_NOTIFICATION_READ, {
    onError: (error) => {
      console.log(`read notification error: ${error}`);
    },
    onCompleted: () => {
      getNotifications({
        variables: {
          student_id: JSON.parse(user).student_id
        }
      })
    },
    fetchPolicy: "network-only",
  });

  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleNotificationClick = (e) => setNotifAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleNotifClose = () => setNotifAnchorEl(null);
  const handleLogout = async () => {
    if (moduleSettings.currentQuestion && moduleSettings.moduleType && moduleSettings.reviews?.length === 0 && moduleSettings.moduleType !== "Diagnostic") await mfSaveAnswer();
    if (moduleSettings.moduleType === "Training") {
      await updateSitting({
        variables: {
          module_type: moduleSettings.moduleType,
          sitting_id: moduleSettings.currentSitting.id,
          part_one_all_correct: moduleSettings.partOneAllCorrect === true ? true : false,
          section_one_pass: moduleSettings.sectionOnePass === true ? true : false,
          section_two_pass: moduleSettings.sectionTwoPass === true ? true : false,
          total_logout_time: moduleSettings.totalLogoutTime
        },
      })
    }
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("auth");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("moduleSettings");
    sessionStorage.removeItem("placementsDone");
    logoutSuccess();
    setAnchorEl(null);
  };

  const loadReport = (event, index) => {
    // setSelectedIndex(index);
    // setReportDialog(true);
    setNotificationRead({
      variables: {
        notification_id: notifications[index]?.id
      }
    });
    setNotifAnchorEl(false);
    history.push(`/report/${notifications[index]?.contentId}`);
  }

  useEffect(() => {
    if (selectedIndex >= 0) {
      setNotificationRead({
        variables: {
          notification_id: notifications[selectedIndex].id
        }
      });
    }
  }, [selectedIndex])

  useEffect(() => {
    if (userRole === "Student" && user) {
      getNotifications({
        variables: {
          student_id: JSON.parse(user).student_id
        }
      });
    }
  }, [location]);

  return (
    <>
      {(userRole === "Student" && user) && (
        <>
          <Dialog open={reportDialog} fullScreen>
            {/* { notifications[selectedIndex]?.isSummary === true && (<PlacementReport openFn={setReportDialog} program_id={notifications[selectedIndex]?.contentId} student_id={JSON.parse(user).student_id} student_name={JSON.parse(user).student_givenname + " " + JSON.parse(user).student_surname} />)} */}
            { notifications[selectedIndex]?.isSummary === false && (<TestReport openFn={setReportDialog} test_id={notifications[selectedIndex]?.contentId} student_id={JSON.parse(user).student_id} student_name={JSON.parse(user).student_givenname + " " + JSON.parse(user).student_surname} />)}
          </Dialog>
          <Badge color="error" badgeContent={notifications.filter(n => !n.isRead).length}>
            <IconButton
              size="small"
              aria-label="Student Notifications"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleNotificationClick}
              color="inherit"
            >
              <NotificationsIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </Badge>

          <Menu
            id="appbar-user-dialog"
            keepMounted
            anchorEl={notifAnchorEl}
            open={Boolean(notifAnchorEl)}
            onClose={handleNotifClose}
          >
            {
              notifications.length > 0 ? 
                notifications.map((n, i) => {
                  return (
                    <MenuItem onClick={(event) => loadReport(event, i)} key={i}>
                      {
                        !n.isRead ? (
                          <>
                            <ListItemIcon color="inherit">
                              <CircleIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText>Your {n.name} Report is ready to view. Click here to view.</ListItemText>
                          </>
                        ) : (
                          <ListItemText inset>Your {n.name} Report is ready to view. Click here to view.</ListItemText>
                        )
                      }
                      
                      
                    </MenuItem>
                  )
                }) :
                <MenuItem>
                  You have no notifications
                </MenuItem>
            }
          </Menu>
        </>
      )}
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        <AccountCircle sx={{ fontSize: 30 }} />
      </IconButton>

      <Menu
        id="appbar-user-dialog"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout} component={Link} to="/">
          <ListItemIcon color="inherit">
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDialog;
