import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { AuthenticatedContext, ModuleContext } from "../App";
import { GET_DASHBOARD } from "../graphql/queries";
import medal from "../assets/medal.png";

const Dashboard = () => {
  const value = useContext(AuthenticatedContext);
  const { moduleSettings, setModuleSettings, exitSitting } =
    useContext(ModuleContext);
  const [placementCards, setPlacementCards] = useState([]);
  // const [trainingCards, setTrainingCards] = useState([]);
  // const [assessmentCards, setAssessmentCards] = useState([]);
  const [totalMedals, setTotalMedals] = useState(0);
  const [redeemableMedals, setRedeemableMedals] = useState(0);
  const history = useHistory();

  function goToPlacement(placement) {
    const type = getStatus(placement);
    if (type === "Start" || type === "Resume" || type === "Review") {
      setModuleSettings({
        ...moduleSettings,
        selectedModule: placement,
        moduleType: "Placement",
      });
    } else {
      setModuleSettings({
        ...moduleSettings,
        selectedModule: placement,
        moduleType: "Diagnostic",
      });
    }
    if (type === "Start" || type === "Start Diagnostic Test") {
      history.push("/instructions");
    } else if (
      type === "Resume" ||
      type === "Resume Diagnostic Test" ||
      type === "Review"
    ) {
      history.push("/placement-question");
    }
  }

  function goToDiagnostic(program) {
    setModuleSettings({
      ...moduleSettings,
      selectedModule: program,
      moduleType: "Diagnostic",
    });
    if (program.DiagnosticSittings.length === 0) {
      history.push("/instructions");
    } else {
      history.push("/placement-question");
    }
  }

  // function goToTraining(training) {
  //   const type = getTrainingStatus(training);
  //   setModuleSettings({
  //     ...moduleSettings,
  //     selectedModule: training,
  //     moduleType: "Training",
  //   });
  //   if (type === "Start") {
  //     history.push("/instructions");
  //   } else if (type === "Resume" || type === "Review") {
  //     history.push("/training-question");
  //   }
  // }

  // async function goToAssessment(assessment) {
  //   const type = getAssessmentStatus(assessment);
  //   setModuleSettings({
  //     ...moduleSettings,
  //     selectedModule: assessment,
  //     moduleType: "Assessment",
  //   });

  //   if (type === "Start") await createSitting(assessment?.id, "Assessment");
  //   history.push("/assessment-question");
  // }

  function getStatus(placement) {
    if (placement.Sittings.length === 0) {
      return "Start";
    } else if (!placement.Sittings[0].isCompleted) {
      return "Resume";
    // } else if (
    //   placement.Sittings[0].isCompleted &&
    //   placement.DiagSittings.length === 0 && 
    //   placement.isDiagnostic
    // ) {
    //   return "Start Diagnostic Test";
    // } else if (
    //   placement.Sittings[0].isCompleted &&
    //   !placement.DiagSittings[0]?.isCompleted &&
    //   placement.isDiagnostic
    // ) {
    //   return "Resume Diagnostic Test";
    } else if (placement.Sittings[0].isCompleted) {
      return "Review";
    } else {
      return "";
    }
  }

  // function getTrainingStatus(training) {
  //   if (training.TrainingSittings.length === 0) {
  //     return "Start";
  //   } else if (!training.TrainingSittings[0].isCompleted) {
  //     return "Resume";
  //   } else if (training.TrainingSittings[0].isCompleted) {
  //     return "Review";
  //   } else {
  //     return "";
  //   }
  // }

  // function getAssessmentStatus(assessment) {
  //   if (assessment.Sittings.length === 0) {
  //     return "Start";
  //   } else if (!assessment.Sittings[0].isCompleted) {
  //     return "Resume";
  //   } else if (assessment.Sittings[0].isCompleted) {
  //     return "Review";
  //   } else {
  //     return "";
  //   }
  // }

  function enableDiagnostic(placements) {
    const incomplete = placements.find(p => {
      if (p.Sittings.length === 0) return p;
      if (p.Sittings[0].isCompleted === false) return p;
    });
    if (incomplete) return false;
    return true;
  }

  const [getDashboard] = useLazyQuery(GET_DASHBOARD, {
    onCompleted: (dashboardItems) => {
      if (value.userRole === "Student") {
        if (dashboardItems.getDashboard.placements) {
          const programs = [...new Set(dashboardItems.getDashboard.placements.map(p => p.Programs[0].Program.name))];
          let placementList = programs.map(pr => {
            const placements = dashboardItems.getDashboard.placements.filter(t => t.Programs[0].Program.name === pr);
            const program = dashboardItems.getDashboard.programs.find(prog => prog.name === pr);
            const canDiagnostic = enableDiagnostic(placements);
            let placementArray = placements.map((p) => (
              <Grid item xs={4} key={p.id} sx={{ pb: 2 }}>
                <Card sx={{ pb: 1 }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    {p.name}
                    <br />
                    {p.totalQuestions} Questions
                    <br />
                    {p.Sittings.length > 0 &&
                      !p.Sittings[0].isCompleted &&
                      "Question " +
                        p.Sittings[0].upTo +
                        " of " +
                        p.totalQuestions}
                    <br />
                    {p.Sittings.length > 0 &&
                      p.Sittings[0].isCompleted &&
                      "Completed"}
                    <br />
                  </CardContent>
                  <CardActions sx={{ justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => goToPlacement(p)}
                    >
                      {getStatus(p)}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ));
            if (program.hasDiagnostic) {
              const numSpaces = 3 - placements.length % 3;
              if (numSpaces > 0) {
                placementArray.push((
                  <Grid item xs={2 * numSpaces} />
                ));
                placementArray.splice(placementArray.length - 1 - placements.length % 3, 0, (
                  <Grid item xs={2 * numSpaces} />
                ));
              }
              placementArray.push((
                <Grid item xs={4} key={0} sx={{ pb: 2 }}>
                  <Card sx={{ pb: 1 }}>
                    <CardContent sx={{ textAlign: "center" }}>
                      {`${program.name} Diagnostic Test`}
                      <br />
                      <br />
                      { canDiagnostic ? (<><br /></>) : "The diagnostic test will be available after finishing all previous tests."}
                      {program.DiagnosticSittings.length > 0 &&
                        program.DiagnosticSittings[0].isCompleted &&
                        "Completed"}
                      <br />
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        size="small"
                        disabled={!canDiagnostic || (program.DiagnosticSittings.length > 0 && program.DiagnosticSittings[0].isCompleted)}
                        onClick={() => goToDiagnostic(program)}
                      >
                        {program.DiagnosticSittings.length === 0 ? "Start" : "Resume"} Diagnostic Test
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ));
            }
            return (
              <>
                <Typography
                  color="primary"
                  gutterBottom
                  variant="h6"
                  sx={{ mt: 5 }}
                >
                  {pr}
                </Typography>
                <Box sx={{ backgroundColor: "whitesmoke" }}>
                  <Grid
                    container
                    columnSpacing={3}
                    sx={{ padding: 2, justifyContent: "center" }}
                  >
                    {placementArray}
                  </Grid>
                </Box>
              </>
            )
          });
          setPlacementCards(
            placementList
          );
          value.setPlacementsDone(false);
          setTotalMedals(dashboardItems.getDashboard.totalMedals);
          setRedeemableMedals(dashboardItems.getDashboard.redeemableMedals);
        }
        // if (dashboardItems.getDashboard.trainings) {
        //   setTrainingCards(
        //     dashboardItems.getDashboard.trainings.map((t) => (
        //       <Grid item xs={4} key={t.id} sx={{ pb: 2 }}>
        //         <Card sx={{ pb: 1 }}>
        //           <CardContent sx={{ textAlign: "center" }}>
        //             {t.name} training
        //             <br />
        //             {t.totalQuestions +
        //               t.totalQuestions -
        //               t.firstGroupQuestions}{" "}
        //             Questions
        //             <br />
        //             {t.TrainingSittings.length > 0 &&
        //               !t.TrainingSittings[0].isCompleted &&
        //               "Question " +
        //                 t.TrainingSittings[0].upTo +
        //                 " of " +
        //                 (t.totalQuestions +
        //                   t.totalQuestions -
        //                   t.firstGroupQuestions)}
        //             <br />
        //             {t.TrainingSittings.length > 0 &&
        //               t.TrainingSittings[0].isCompleted &&
        //               "Completed"}
        //             <br />
        //           </CardContent>
        //           <CardActions sx={{ justifyContent: "center" }}>
        //             <Button
        //               variant="contained"
        //               size="small"
        //               onClick={() => goToTraining(t)}
        //             >
        //               {getTrainingStatus(t)}
        //             </Button>
        //           </CardActions>
        //         </Card>
        //       </Grid>
        //     ))
        //   );
        // }
        // if (dashboardItems.getDashboard.assessments) {
        //   setAssessmentCards(
        //     dashboardItems.getDashboard.assessments.map((a) => (
        //       <Grid item xs={4} key={a.id} sx={{ pb: 2 }}>
        //         <Card sx={{ pb: 1 }}>
        //           <CardContent sx={{ textAlign: "center" }}>
        //             {a.name} assessment
        //             <br />
        //             {a.totalQuestions} Questions
        //             <br />
        //             {a.Sittings.length > 0 &&
        //               !a.Sittings[0].isCompleted &&
        //               "Question " +
        //                 a.Sittings[0].upTo +
        //                 " of " +
        //                 a.totalQuestions}
        //             <br />
        //             {a.Sittings.length > 0 &&
        //               a.Sittings[0].isCompleted &&
        //               "Completed"}
        //             <br />
        //           </CardContent>
        //           <CardActions sx={{ justifyContent: "center" }}>
        //             <Button
        //               variant="contained"
        //               size="small"
        //               onClick={() => goToAssessment(a)}
        //             >
        //               {getAssessmentStatus(a)}
        //             </Button>
        //           </CardActions>
        //         </Card>
        //       </Grid>
        //     ))
        //   );
        // }
      }
    },
    onError: (error) => {
      console.log(`getDashboard Error ${error}`);
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    value.userToken &&
      value.user &&
      value.userRole &&
      getDashboard({
        variables: {
          user_id: JSON.parse(value.user).student_id,
          role: value.userRole,
        },
      });
  }, [value]);

  useEffect(() => {
    exitSitting();
  }, []);

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 9.1 }}>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {value.userRole === "Student" ? (
            <Typography color="black" gutterBottom variant="h5" sx={{ mb: 2 }}>
              Welcome {value.user && JSON.parse(value.user).student_givenname}
            </Typography>
          ) : (
            <Typography
              color="primary"
              gutterBottom
              variant="h6"
              sx={{ mb: 2 }}
            >
              {value.userRole} Dashboard
            </Typography>
          )}
        </Box>
        {
          value.userRole === "Student" && (
            <Stack direction="row" justifyContent="space-between">
              <Box />
              {/* <Box sx={{ backgroundColor: "whitesmoke", maxWidth: 500, width: "50%" }}>
                <Grid
                  container
                  columnSpacing={3}
                  sx={{ padding: 2, justifyContent: "center" }}
                >
                  <Grid item xs={12}>
                    <Card sx={{ pb: 1 }}>
                      <CardContent sx={{ textAlign: "center" }}>
                      <Typography sx={{ fontSize: "30px" }}>Total medals earned so far:</Typography>
                        <br />
                        <Typography variant="h1" noWrap>{totalMedals}</Typography> 
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box> */}
              <Box />
            </Stack>
          )
        }
        
        {placementCards.length > 0 && (
          <>
            <Typography
              color="primary"
              gutterBottom
              variant="h6"
              sx={{ mt: 0 }}
            >
              Start Your Problem Solving Assessment Here
            </Typography>
            {placementCards}
          </>
        )}
        {/* <Divider variant="full-width" sx={{ mt: 3, mb: 2 }} />
        {(trainingCards.length > 0 || assessmentCards.length > 0) && (
          <>
            <Typography
              color="primary"
              gutterBottom
              variant="h6"
              sx={{ mb: 2 }}
            >
              Start Your Trainings/Assessments Here
            </Typography>
            <Box sx={{ backgroundColor: "whitesmoke" }}>
              <Grid
                container
                columnSpacing={3}
                sx={{ padding: 2, justifyContent: "center" }}
              >
                {trainingCards}
                {assessmentCards}
              </Grid>
            </Box>
            <Typography
              color="primary"
              gutterBottom
              variant="h6"
              sx={{ mt: 3 }}
            >
              What Are Trainings For?
            </Typography>
            <Typography
              color="black"
              gutterBottom
              variant="body1"
              sx={{ mt: 0 }}
            >
              Training Description.
            </Typography>
          </>
        )} */}
      </Paper>
    </Container>
  );
};

export default Dashboard;
