import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Alert, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import parse from "html-react-parser";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const HorizontalSortType = ({
  currentQuestion: {
    id,
    content,
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    answer8,
    answer9,
    answer10,
    correctAns,
    src,
    hint,
    solution
  },
}) => {
  const [items, setItems] = useState([]);
  const [initialItems, setInitialItems] = useState([]);

  useEffect(() => {
    if (correctAns) setItems(formatList(parse(correctAns)));
    setInitialItems(formatList());
  }, [
    id,
    content,
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    answer8,
    answer9,
    answer10,
    correctAns,
    src,
    hint,
    solution
  ]);

  const getCorrectList = () => {};

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 3,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",
    color: "black",
    fontSize: "1.4rem",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    display: "flex",
    padding: grid,
    overflow: "auto",
  });

  const getCorrectItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 3,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: "lightgreen",
    color: "black",
    fontSize: "1.4rem",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getCorrectListStyle = (isDraggingOver) => ({
    background: "lightblue",
    display: "flex",
    padding: grid,
    overflow: "auto",
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      initialItems,
      result.source.index,
      result.destination.index
    );

    setInitialItems(newItems);
  };

  const onDragEndCorrect = (result) => {
    if (!result.destination) {
      return;
    }

    // const newItems = reorder(
    //   items,
    //   result.source.index,
    //   result.destination.index
    // );
    setItems(items);
  };

  const formatList = (list) => {
    let indexes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    if (list) indexes = JSON.parse("[" + list + "]");
    let result = [];
    for (let i of indexes) {
      switch (i) {
        case 1:
          result.push({ name: answer1, id: "1" });
          break;
        case 2:
          result.push({ name: answer2, id: "2" });
          break;
        case 3:
          result.push({ name: answer3, id: "3" });
          break;
        case 4:
          result.push({ name: answer4, id: "4" });
          break;
        case 5:
          if (answer5) result.push({ name: answer5, id: "5" });
          break;
        case 6:
          if (answer6) result.push({ name: answer6, id: "6" });
          break;
        case 7:
          if (answer7) result.push({ name: answer7, id: "7" });
          break;
        case 8:
          if (answer8) result.push({ name: answer8, id: "8" });
          break;
        case 9:
          if (answer9) result.push({ name: answer9, id: "9" });
          break;
        case 10:
          if (answer10) result.push({ name: answer10, id: "10" });
          break;
        default:
      }
    }
    return result;
  };

  return (
    <Box sx={{ pb: 2 }}>
      {src && <Typography variant="h6">{parse(src)}</Typography>}
      {content && <Typography variant="h6">{parse(content)}</Typography>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {initialItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {item.name}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Alert severity="success" sx={{ mt: 2 }}>
        <div style={{ marginBottom: "8px" }}>
          <strong>Correct answer:</strong>
        </div>
        <DragDropContext onDragEnd={onDragEndCorrect}>
          <Droppable droppableId="droppableCorrect" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getCorrectListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {items.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={true}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getCorrectItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.name}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Alert>

      {hint && (
        <Alert
          severity="info"
          iconMapping={{
            info: <WbIncandescentIcon fontSize="inherit" />,
          }}
          sx={{ mt: 2 }}
        >
          <strong>
            Hint: <br></br>
          </strong>
          {parse(hint)}
        </Alert>
      )}
      {solution && (
        <Alert
          severity="warning"
          iconMapping={{
            warning: <RadioButtonCheckedIcon fontSize="inherit" />,
          }}
          sx={{ mt: 2 }}
        >
          <strong>
            Solution: <br></br>
          </strong>
          {parse(solution)}
        </Alert>
      )}
    </Box>
  );
};

export default HorizontalSortType;
