import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid-pro";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { GET_ALL_TESTS } from "../graphql/queries";
import { DELETE_TEST, REGISTER_MOCK_STUDENTS } from "../graphql/mutations";
import { AuthenticatedContext, PreferenceContext } from "../App";

const TestManagement = () => {
  const { userRole } = React.useContext(AuthenticatedContext);
  const { preferences, setPreferences, defaults } = React.useContext(PreferenceContext);
  let testPref = {...preferences.testMgmt};
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(testPref.pageSize || 10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogParams, setDeleteDialogParams] = useState({});
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [getAllTests, { testsLoading, testsError, testsData }] = useLazyQuery(GET_ALL_TESTS, {
    fetchPolicy: "no-cache",
    onCompleted: (tests) => {
      if (tests.getAllTests?.tests.length >= 0) {
        setRows(tests.getAllTests.tests);
      }
      setRowCount(tests.getAllTests.total);
    }
  });

  const [deleteTest] = useMutation(DELETE_TEST, {
    onError: (error) => {
      console.log(error);
      setDeleteDialogOpen(false);
    },
    onCompleted: ({deleteTest}) => {
      if (deleteTest === true) {
        setRows(rows.filter((row) => row.id !== deleteDialogParams.id));
        enqueueSnackbar(
          `Test(id: ${deleteDialogParams.id}) has been successfully deleted`,
          {
            variant: "success",
          }
        );
      } else if (deleteTest === false) {
        enqueueSnackbar("Test can not be found", {
          variant: "error",
        });
      }
      setDeleteDialogOpen(false);
    },
    fetchPolicy: "network-only",
  });

  const [registerMockStudents] = useMutation(REGISTER_MOCK_STUDENTS, {
    onError: (error) => {
      console.log(error);
    },
    onCompleted: ({registerMockStudents}) => {
      if (registerMockStudents > 0) {
        enqueueSnackbar(
          `${registerMockStudents} test students were successfully registered`,
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar("No new test students were registered",
          {
            variant: "warning",
          }
        );
      }
    }
  });

  useEffect(() => {
    getAllTests({
      variables: {
        skip: page * pageSize,
        take: pageSize,
        filters: testPref?.filters || defaults.testMgmt.filters,
        sort: testPref?.sort || defaults.testMgmt.sort,
      }
    });
  }, [page]);

  const handleDeleteTest = (params) => {
    deleteTest({ variables: { id: params.id, module_type_id: params.moduleTypeId }})
  }  

  const deleteTestFunction = useCallback((params) => () => {
    setDeleteDialogOpen(true);
    setDeleteDialogParams(params.row);
  });

  const registerTest = useCallback(
    (params) => () =>
      registerMockStudents({variables: {id: params.row.id, module_type_id: params.row.moduleTypeId}}),
    []
  );

  const viewTest = useCallback(
    (params) => () => {
      history.push(`/testmanagement/${params.row.moduleTypeId}/${params.row.id}`, { data: params.row })},
    []
  );

  const handlePageSizeChange = (newPageSize) => {
    testPref.pageSize = newPageSize;
    setPageSize(newPageSize);
    setPreferences({
      ...preferences,
      testMgmt: {
        ...testPref
      }
    });
    if (page === 0 && defaults) {
      getAllTests({
        variables: {
          skip: page * newPageSize,
          take: newPageSize,
          filters: testPref.filters,
          sort: testPref.sort,
        }
      });
    } else {
      setPage(0);
    }
  };

  const handleColumnWidthChange = (params) => {
    testPref.widths[params.colDef.field] = params.width;
    setPreferences({
      ...preferences,
      testMgmt: {
        ...testPref
      }
    });
  };

  const handleColumnOrderChange = (params) => {
    testPref.columns.splice(params.oldIndex, 1);
    testPref.columns.splice(params.targetIndex, 0, params.field);
    setPreferences({
      ...preferences,
      testMgmt: {
        ...testPref
      }
    });
  };

  const handleColumnVisibilityModelChange = (model) => {
    testPref.visible = {...model};
    setPreferences({
      ...preferences,
      testMgmt: {
        ...testPref
      }
    });
  }

  const handleSortModelChange = (model) => {
    testPref.sort = [...model];

    setPreferences({
      ...preferences,
      testMgmt: {
        ...testPref
      }
    });
    if (page === 0 && defaults) {
      getAllTests({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filters: testPref.filters,
          sort: [...model],
        }
      });
    } else {
      setPage(0);
    }
  };

  const handleFilterModelChange = (model) => {
    testPref.filters = {...model};

    
    setPreferences({
      ...preferences,
      testMgmt: {
        ...testPref
      }
    });
    if (page === 0) {
      getAllTests({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filters: {...model},
          sort: testPref.sort,
        }
      });
    } else {
      setPage(0);
    }
  };

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        width: testPref?.widths?.actions,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => {
          let actions = [
            <GridActionsCellItem
              icon={<Tooltip title="View Test"><VisibilityIcon /></Tooltip>}
              label="View"
              color="primary"
              onClick={viewTest(params)}
            />
          ];
          if (["Admin", "Education Consultant"].includes(userRole)) {
            actions.push(
              <GridActionsCellItem
                icon={<Tooltip title="Delete Test"><DeleteIcon /></Tooltip>}
                label="Delete"
                color="primary"
                disabled={params?.row?.moduleTypeId === 3}
                onClick={deleteTestFunction(params)}
              />,
            )
          }
          return actions;
        }
      },
      {
        field: "id",
        headerName: "Test ID",
        width: testPref?.widths?.id,
        headerAlign: "center",
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
        hide: !testPref?.visible?.id || !["Admin", "Education Consultant"].includes(userRole),
        type: "number"
      },
      {
        field: "name",
        headerName: "Test Name",
        width: testPref?.widths?.name,
        headerAlign: "center",
        headerAlign: "center",
        align: "center",
        hide: !testPref?.visible?.name,
        getApplyQuickFilterFn: getApplyFilterFnName,
      },
      {
        field: "totalQuestions",
        headerName: "# of Questions",
        width: testPref?.widths?.totalQuestions,
        headerAlign: "center",
        align: "center",
        hide: !testPref?.visible?.totalQuestions,
        getApplyQuickFilterFn: undefined,
        type: "number"
      },
      // {
      //   field: "moduleTypeId",
      //   headerName: "Test Type",
      //   width: testPref?.widths?.moduleTypeId,
      //   headerAlign: "center",
      //   align: "center",
      //   hide: !testPref?.visible?.moduleTypeId,
      //   getApplyQuickFilterFn: undefined,
      //   valueFormatter: ({ value }) => {
      //     if (value) {
      //       if (value === 1) {
      //         return "Placement"
      //       } else if (value === 2) {
      //         return "Progression"
      //       };
      //     }
      //   },
      // },
      {
        field: "isAdaptive",
        headerName: "Adaptive?",
        width: testPref?.widths?.isAdaptive,
        headerAlign: "center",
        align: "center",
        hide: !testPref?.visible?.isAdaptive,
        getApplyQuickFilterFn: undefined,
        type: "boolean"
      },
      {
        field: "isDiagnostic",
        headerName: "Diagnostic?",
        width: testPref?.widths?.isActive,
        headerAlign: "center",
        align: "center",
        hide: !testPref?.visible?.isActive,
        getApplyQuickFilterFn: undefined,
        type: "boolean"
      },
      {
        field: "isActive",
        headerName: "Active?",
        width: testPref?.widths?.isActive,
        headerAlign: "center",
        align: "center",
        hide: !testPref?.visible?.isActive,
        getApplyQuickFilterFn: undefined,
        type: "boolean"
      },
      // {
      //   field: "availableFrom",
      //   headerName: "Available From",
      //   width: testPref?.widths?.availableFrom,
      //   headerAlign: "center",
      //   type: "dateTime",
      //   hide: !testPref?.visible?.availableFrom,
      //   getApplyQuickFilterFn: undefined,
      //   valueFormatter: ({ value }) => {
      //     if (value) {
      //       return format(value, "dd/MM/yyyy HH:mm:ss");
      //     }
      //   },
      //   align: "center",
      //   filterable: false,
      // },
      // {
      //   field: "availableTo",
      //   headerName: "Available To",
      //   width: testPref?.widths?.availableFrom,
      //   headerAlign: "center",
      //   type: "dateTime",
      //   hide: !testPref?.visible?.availableFrom,
      //   getApplyQuickFilterFn: undefined,
      //   valueFormatter: ({ value }) => {
      //     if (value) {
      //       return format(value, "dd/MM/yyyy HH:mm:ss");
      //     }
      //   },
      //   align: "center",
      //   filterable: false,
      // },
      {
        field: "createdAt",
        headerName: "Created",
        width: testPref?.widths?.createdAt,
        headerAlign: "center",
        type: "dateTime",
        hide: !testPref?.visible?.createdAt,
        getApplyQuickFilterFn: undefined,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
        filterable: false,
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: testPref?.widths?.updatedAt,
        headerAlign: "center",
        type: "dateTime",
        hide: !testPref?.visible?.updatedAt,
        getApplyQuickFilterFn: undefined,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
        filterable: false,
      }
    ],
    [viewTest, registerTest, testPref]
  );

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
            Test Management
          </Typography>
        </Box>
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>
            {`Are you sure you want to delete this test?`}
          </DialogTitle>
          <DialogActions sx={{ mx: 1.6, my: 1 }}>
            <Button
              onClick={() => handleDeleteTest(deleteDialogParams)}
              color="primary"
              variant="contained"
              startIcon={<CheckCircleIcon />}
              autoFocus
            >
              Confirm
            </Button>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<CancelIcon />}
              onClick={() => setDeleteDialogOpen(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSize={testPref.pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          loading={testsLoading}
          onColumnWidthChange={handleColumnWidthChange}
          onColumnOrderChange={handleColumnOrderChange}
          pagination
          pinnedColumns={{ left: ["actions"] }}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          filterModel={testPref.filters}
          sortModel={testPref.sort}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          rowCount={rowCount}
          density="compact"
        />
      </Paper>
    </Container>
  );
};

export default TestManagement;
