import React, { useEffect, useState } from "react";
import { Split } from "@geoffcox/react-splitter";
import { Box } from "@mui/system";
import {
  Alert,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import parse from "html-react-parser";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { ModuleContext } from "../../App";

const InputBoxType = ({
  currentQuestion: { id, content, correctAns, src, hint, solution },
  moduleType,
  isAssessmentAnswerCorrect,
  readOnly,
  isTrainingAnswerCorrect,
  isResultShown,
  isReview,
}) => {
  const moduleValue = React.useContext(ModuleContext);
  const splitterColors = {
    color: "#e0e0e0",
    hover: "#bdbdbd",
  };

  const options = {
    replace: ({ attribs }) => {
      if (!attribs) {
        return;
      }

      if (attribs["data-value"]) {
        const parsedHtml = attribs["data-value"];
        return <Latex>{`$${parsedHtml}$`}</Latex>;
      }
    },
  };

  const handleInputChange = ({ target }) => {
    const re = /^[A-Za-z0-9\.\+\-\*\/\(\)\:]+$/;
    //const re = /^[0-9\b]+$/;
    if (target.value == "" || re.test(target.value)) {
      moduleValue.setModuleSettings({
        ...moduleValue.moduleSettings,
        selectedAnswer: target.value.toLowerCase(),
      });
    }
  };

  const getCorrectAnswer = () => correctAns?.split("[split]").join(", ");
  const questionUnitSplitBefore = () => content.split("[unit-before]");
  const questionUnitSplitAfter = () => content.split("[unit-after]");

  return (
    <Box sx={{ pb: 2, pl: 3 }}>
      <Split
        minPrimarySize="15%"
        minSecondarySize="15%"
        initialPrimarySize="50%"
        resetOnDoubleClick
        splitterSize="32px"
        defaultSplitterColors={splitterColors}
      >
        <div style={{ overflow: "auto", height: "100%" }}>
          {src && <Typography variant="h6">{parse(src, options)}</Typography>}
          {!src && content && (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>
                {questionUnitSplitBefore().length <= 1 &&
                  questionUnitSplitAfter().length <= 1 &&
                  parse(content, options)}
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                {questionUnitSplitBefore().length > 1 &&
                  parse(questionUnitSplitBefore()[0], options)}
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                {questionUnitSplitAfter().length > 1 &&
                  parse(questionUnitSplitAfter()[0], options)}
              </Typography>
            </>
          )}
        </div>
        <div style={{ overflow: "auto", height: "100%" }}>
          {src && content && (
            <>
              <Typography variant="h6">
                {questionUnitSplitBefore().length <= 1 &&
                  questionUnitSplitAfter().length <= 1 &&
                  parse(content, options)}
              </Typography>
              <Typography variant="h6">
                {questionUnitSplitBefore().length > 1 &&
                  parse(questionUnitSplitBefore()[0], options)}
              </Typography>
              <Typography variant="h6">
                {questionUnitSplitAfter().length > 1 &&
                  parse(questionUnitSplitAfter()[0], options)}
              </Typography>
            </>
          )}
          <Stack direction="row" alignItems="center" sx={{ ml: 2, mb: 2 }}>
            {questionUnitSplitBefore().length > 1 && (
              <Typography variant="h6" sx={{ mr: 1 }}>
                {parse(questionUnitSplitBefore()[1], options)}
              </Typography>
            )}
            <FormControl
              variant="standard"
              sx={{
                minWidth: "200px",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              <TextField
                label="Type your answer here"
                variant="outlined"
                onChange={handleInputChange}
                value={moduleValue.moduleSettings.selectedAnswer}
                type="input"
                disabled={readOnly}
              />
            </FormControl>
            {questionUnitSplitAfter().length > 1 && (
              <Typography variant="h6" sx={{ ml: 1 }}>
                {parse(questionUnitSplitAfter()[1], options)}
              </Typography>
            )}
          </Stack>
          {
            moduleValue.moduleSettings?.moduleType === "Diagnostic" && moduleValue.moduleSettings?.currentQuestion?.attempts > 1 && moduleValue.moduleSettings?.attempt > 1 && moduleValue.moduleSettings?.attempt <= moduleValue.moduleSettings?.currentQuestion?.attempts && (
              <Alert severity="error" sx={{ ml: 2, mb: 2 }}>
                Your answer was incorrect! Please try again.
              </Alert>
            )
          }
          {
            moduleValue.moduleSettings?.moduleType === "Diagnostic" && moduleValue.moduleSettings?.currentQuestion?.attempts > 1 && moduleValue.moduleSettings?.attempt > 1 && moduleValue.moduleSettings?.attempt > moduleValue.moduleSettings?.currentQuestion?.attempts && (
              <Alert severity="error" sx={{ ml: 2, mb: 2 }}>
                Your answer was incorrect and you are now out of attempts. Please click 'Next' to continue.
              </Alert>
            )
          }
          {isResultShown && !isReview && (isTrainingAnswerCorrect || isAssessmentAnswerCorrect) && (
            <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
              <strong>Correct!</strong>
            </Alert>
          )}
          {correctAns && isResultShown && (!isTrainingAnswerCorrect && !isAssessmentAnswerCorrect) && (
            <Alert severity="error" sx={{ ml: 2, mb: 2 }}>
              <strong>Wrong answer.</strong>
            </Alert>
          )}
          {correctAns && !isReview && isResultShown && (!isTrainingAnswerCorrect && !isAssessmentAnswerCorrect) && (
            <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
              <strong>Correct answer:</strong> {getCorrectAnswer()}
            </Alert>
          )}
          {correctAns && isReview && (
            <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
              <strong>Correct answer:</strong> {getCorrectAnswer()}
            </Alert>
          )}
          {moduleType === "Training" && hint && isResultShown && !isTrainingAnswerCorrect && (
            <Alert
              severity="info"
              iconMapping={{
                info: <WbIncandescentIcon fontSize="inherit" />,
              }}
              sx={{ ml: 2, mb: 2 }}
            >
              <strong>
                Hint: <br></br>
              </strong>
              {parse(hint, options)}
            </Alert>
          )}
          {moduleType === "Assessment" && hint && (
            <Alert
              severity="info"
              iconMapping={{
                info: <WbIncandescentIcon fontSize="inherit" />,
              }}
              sx={{ ml: 2, mb: 2 }}
            >
              <strong>
                Hint: <br></br>
              </strong>
              {parse(hint, options)}
            </Alert>
          )}
          {/* {hint && isReview && (
            <Alert
              severity="info"
              iconMapping={{
                info: <WbIncandescentIcon fontSize="inherit" />,
              }}
              sx={{ ml: 2, mb: 2 }}
            >
              <strong>
                Hint: <br></br>
              </strong>
              {parse(hint, options)}
            </Alert>
          )} */}
          {moduleType === "Training" && solution && isResultShown && !isTrainingAnswerCorrect && (
            <Alert
              severity="warning"
              iconMapping={{
                warning: <RadioButtonCheckedIcon fontSize="inherit" />,
              }}
              sx={{ ml: 2 }}
            >
              <strong>
                Solution: <br></br>
              </strong>
              {parse(solution, options)}
            </Alert>
          )}
          {moduleType === "Assessment" && solution && isResultShown && (
            <Alert
              severity="warning"
              iconMapping={{
                warning: <RadioButtonCheckedIcon fontSize="inherit" />,
              }}
              sx={{ ml: 2 }}
            >
              <strong>
                Solution: <br></br>
              </strong>
              {parse(solution, options)}
            </Alert>
          )}
          {/* {solution && isReview && (
            <Alert
              severity="warning"
              iconMapping={{
                warning: <RadioButtonCheckedIcon fontSize="inherit" />,
              }}
              sx={{ ml: 2 }}
            >
              <strong>
                Solution: <br></br>
              </strong>
              {parse(solution, options)}
            </Alert>
          )} */}
        </div>
      </Split>
    </Box>
  );
};;

export default InputBoxType;
