import React, { useEffect, useState } from "react";
import { Split } from "@geoffcox/react-splitter";
import { Box } from "@mui/system";
import {
  Alert,
  FormControl,
  Stack,
  TextField,
  Typography,
  Divider
} from "@mui/material";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import parse from "html-react-parser";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { ModuleContext } from "../../App";

const InputBoxFractionType = ({
  currentQuestion: { id, content, correctAns, src, hint, solution, order },
  moduleType,
  isAssessmentAnswerCorrect,
  readOnly,
  isTrainingAnswerCorrect,
  isResultShown,
  isReview,
}) => {
  const moduleValue = React.useContext(ModuleContext);
  const splitterColors = {
    color: "#e0e0e0",
    hover: "#bdbdbd",
  };

  const options = {
    replace: ({ attribs }) => {
      if (!attribs) {
        return;
      }

      if (attribs["data-value"]) {
        const parsedHtml = attribs["data-value"];
        return <Latex>{`$${parsedHtml}$`}</Latex>;
      }
    },
  };

  const handleInputChange = ({ target }) => {
    const re = /^[A-Za-z0-9\.\+\-\*\/\(\)\:]+$/;
    if (target.value == "" || re.test(target.value)) {
      let array = [...moduleValue.moduleSettings.selectedAnswer.split("[split]")];
      array[Number(target.id)] = target.value;
      moduleValue.setModuleSettings({
        ...moduleValue.moduleSettings,
        selectedAnswer: array.join("[split]"),
      });
    }
  };

  const getCorrectAnswer = () => {
    const answers = correctAns?.split("[or]");
    const convertedAnswer = answers.map(a => {
      const components = a.split("[split]");
      return `<span data-w-e-type="formula" data-w-e-is-void data-w-e-is-inline data-value="\\frac{${components[0]}}{${components[1]}}"></span>`;
    }).join(" or ");
    return parse("<p>" + convertedAnswer + "</p", options);
  };
  

  return (
    <Box sx={{ pb: 2, pl: 3 }}>
      <Split
        minPrimarySize="15%"
        minSecondarySize="15%"
        initialPrimarySize="50%"
        resetOnDoubleClick
        splitterSize="32px"
        defaultSplitterColors={splitterColors}
      >
        <div style={{ overflow: "auto", height: "100%" }}>
          {src && <Typography variant="h6">{parse(src, options)}</Typography>}
          {!src && content && (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>
                {parse(content, options)}
              </Typography>
            </>
          )}
        </div>
        <div style={{ overflow: "auto", height: "100%" }}>
          {src && content && (
            <>
              <Typography variant="h6">
                {parse(content, options)}
              </Typography>
            </>
          )}
          <FormControl
            variant="standard"
            sx={{
              width: "50px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Box />
              <TextField
                id={0}
                variant="outlined"
                onChange={handleInputChange}
                value={moduleValue.moduleSettings.selectedAnswer?.split("[split]")[0]}
                type="input"
                size="small"
                sx={{
                  marginBottom: "2px",
                  width: "40px"
                }}
                disabled={readOnly}
                inputProps={{
                  style: {
                    paddingLeft: 5,
                    paddingRight: 5,
                    textAlign: "center"
                  }
                }}
              />
              <Box />
            </Stack>
            <Divider sx={{ border: "none", height: "2px", color: "black", backgroundColor: "black" }} />
            <Stack direction="row" justifyContent="space-between">
              <Box />
              <TextField
                id={1}
                disabled={readOnly}
                variant="outlined"
                onChange={handleInputChange}
                value={moduleValue.moduleSettings.selectedAnswer?.split("[split]")[1]}
                type="input"
                size="small"
                sx={{
                  marginTop: "2px",
                  width: "40px"
                }}
                inputProps={{
                  style: {
                    paddingLeft: 5,
                    paddingRight: 5,
                    textAlign: "center"
                  }
                }}
              />
              <Box />
            </Stack>
          </FormControl>
          {
            moduleValue.moduleSettings?.moduleType === "Diagnostic" && moduleValue.moduleSettings?.currentQuestion?.attempts > 1 && moduleValue.moduleSettings?.attempt > 1 && moduleValue.moduleSettings?.attempt <= moduleValue.moduleSettings?.currentQuestion?.attempts && (
              <Alert severity="error" sx={{ ml: 2, mb: 2 }}>
                Your answer was incorrect! Please try again.
              </Alert>
            )
          }
          {
            moduleValue.moduleSettings?.moduleType === "Diagnostic" && moduleValue.moduleSettings?.currentQuestion?.attempts > 1 && moduleValue.moduleSettings?.attempt > 1 && moduleValue.moduleSettings?.attempt > moduleValue.moduleSettings?.currentQuestion?.attempts && (
              <Alert severity="error" sx={{ ml: 2, mb: 2 }}>
                Your answer was incorrect and you are now out of attempts. Please click 'Next' to continue.
              </Alert>
            )
          }
          {isResultShown && !isReview && (isTrainingAnswerCorrect || isAssessmentAnswerCorrect) && (
            <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
              <strong>Correct!</strong>
            </Alert>
          )}
          {correctAns && isResultShown && (!isTrainingAnswerCorrect && !isAssessmentAnswerCorrect) && (
            <Alert severity="error" sx={{ ml: 2, mb: 2 }}>
              <strong>Wrong answer.</strong>
            </Alert>
          )}
          {correctAns && !isReview && isResultShown && (!isTrainingAnswerCorrect && !isAssessmentAnswerCorrect) && (
            <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
              <strong>Correct answer:</strong> {getCorrectAnswer()}
            </Alert>
          )}
          {correctAns && isReview && (
            <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
              <strong>Correct answer:</strong> {getCorrectAnswer()}
            </Alert>
          )}
          {moduleType === "Training" && hint && isResultShown && !isTrainingAnswerCorrect && (
            <Alert
              severity="info"
              iconMapping={{
                info: <WbIncandescentIcon fontSize="inherit" />,
              }}
              sx={{ ml: 2, mb: 2 }}
            >
              <strong>
                Hint: <br></br>
              </strong>
              {parse(hint, options)}
            </Alert>
          )}
          {moduleType === "Assessment" && hint && (
            <Alert
              severity="info"
              iconMapping={{
                info: <WbIncandescentIcon fontSize="inherit" />,
              }}
              sx={{ ml: 2, mb: 2 }}
            >
              <strong>
                Hint: <br></br>
              </strong>
              {parse(hint, options)}
            </Alert>
          )}
          {moduleType === "Training" && solution && isResultShown && !isTrainingAnswerCorrect && (
            <Alert
              severity="warning"
              iconMapping={{
                warning: <RadioButtonCheckedIcon fontSize="inherit" />,
              }}
              sx={{ ml: 2 }}
            >
              <strong>
                Solution: <br></br>
              </strong>
              {parse(solution, options)}
            </Alert>
          )}
          {moduleType === "Assessment" && solution && isResultShown && (
            <Alert
              severity="warning"
              iconMapping={{
                warning: <RadioButtonCheckedIcon fontSize="inherit" />,
              }}
              sx={{ ml: 2 }}
            >
              <strong>
                Solution: <br></br>
              </strong>
              {parse(solution, options)}
            </Alert>
          )}
        </div>
      </Split>
    </Box>
  );
};;

export default InputBoxFractionType;
