import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar, GridActionsCellItem, useGridApiRef, gridQuickFilterValuesSelector } from "@mui/x-data-grid-pro";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { format } from "date-fns";
import { AuthenticatedContext, PreferenceContext } from "../App";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET_REGISTERED_TESTS, GET_UNREGISTERED_PLACEMENTS } from "../graphql/queries";
import { REGISTER_MODULE, DELETE_REGISTRATION } from "../graphql/mutations";

const TestRegistrations = ({ student_id }) => {
  const { preferences, setPreferences } = useContext(PreferenceContext);
  const { userRole } = useContext(AuthenticatedContext);
  let regPref = {...preferences.testReg};
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(regPref.pageSize || 10);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [dialog, setDialog] = useState(false);
  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");

  const [getRegisteredTests, { testsLoading, testsError, testsData }] = useLazyQuery(GET_REGISTERED_TESTS, {
    onCompleted: (tests) => {
      if (tests?.getRegisteredTests.tests.length > 0) {
        setRows(tests.getRegisteredTests.tests);
      }
      setRowCount(tests.getRegisteredTests.total);
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [getUnregisteredPlacements] = useLazyQuery(GET_UNREGISTERED_PLACEMENTS, {
    onCompleted: (tests) => {
      if (tests?.getUnregisteredPlacements.length > 0) {
        setTests(tests.getUnregisteredPlacements);
      }
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [registerModule] = useMutation(REGISTER_MODULE, {
    onCompleted: (test) => {
      if (test.registerModule === true) {
        enqueueSnackbar(
          `Registration created`,
          {
            variant: "success",
          }
        );
        getRegisteredTests({
          variables: {
            student_id,
            skip: page * pageSize,
            take: pageSize,
            filters: regPref.filters,
            sort: regPref.sort,
          }
        });
      } else {
        enqueueSnackbar(
          `Registration unable to be created`,
          {
            variant: "error",
          }
        );
      }
    },
    fetchPolicy: 'network-only',
  });

  const [deleteRegistration] = useMutation(DELETE_REGISTRATION, {
    onCompleted: (test) => {
      if (test.deleteRegistration === true) {
        enqueueSnackbar(
          `Registration deleted`,
          {
            variant: "success",
          }
        );
        getRegisteredTests({
          variables: {
            student_id,
            skip: page * pageSize,
            take: pageSize,
            filters: regPref.filters,
            sort: regPref.sort,
          }
        });
      } else {
        enqueueSnackbar(
          `Registration not found`,
          {
            variant: "error",
          }
        );
      }
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getRegisteredTests({
      variables: {
        student_id,
        skip: page * pageSize,
        take: pageSize,
        filters: regPref.filters,
        sort: regPref.sort,
      }
    });
  }, [page]);

  useEffect(() => {
    if (dialog === true) {
      getUnregisteredPlacements({
        variables: { student_id }
      });
    }
  }, [dialog]);

  const handlePageSizeChange = (newPageSize) => {
    regPref.pageSize = newPageSize;
    setPreferences({
      ...preferences,
      testReg: {
        ...regPref
      }
    });
    if (page === 0) {
      getRegisteredTests({
        variables: {
          student_id,
          skip: page * newPageSize,
          take: newPageSize,
          filters: regPref.filters,
          sort: regPref.sort,
        }
      });
    } else {
      setPage(0);
    }
  };

  const handleColumnWidthChange = (params) => {
    regPref.widths[params.colDef.field] = params.width;
    setPreferences({
      ...preferences,
      testReg: {
        ...regPref
      }
    });
  };

  const handleColumnOrderChange = (params) => {
    regPref.columns.splice(params.oldIndex, 1);
    regPref.columns.splice(params.targetIndex, 0, params.field);
    setPreferences({
      ...preferences,
      testReg: {
        ...regPref
      }
    });
  };

  const handleColumnVisibilityModelChange = (model) => {
    regPref.visible = {...model};
    setPreferences({
      ...preferences,
      testReg: {
        ...regPref
      }
    });
  }

  const handleSortModelChange = (model) => {
    regPref.sort = [...model];

    setPreferences({
      ...preferences,
      testReg: {
        ...regPref
      }
    });
    if (page === 0) {
      getRegisteredTests({
        variables: {
          student_id,
          skip: page * pageSize,
          take: pageSize,
          filters: regPref.filters,
          sort: [...model],
        }
      });
    } else {
      setPage(0);
    }
  };

  const handleFilterModelChange = (model) => {
    regPref.filters = {...model};

    
    setPreferences({
      ...preferences,
      testReg: {
        ...regPref
      }
    });
    if (page === 0) {
      getRegisteredTests({
        variables: {
          student_id,
          skip: page * pageSize,
          take: pageSize,
          filters: {...model},
          sort: regPref.sort,
        }
      });
    } else {
      setPage(0);
    }
  };

  const viewStudent = useCallback(
    (params) => () =>
      history.push(`/registrations/${params.row.id}`, { data: params.row }),
    []
  );

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const registerStudents = () => {
    registerModule({variables: { module_id: selectedTest, module_type: "Placement", student_id }});
    setDialog(false);
  }

  const handleSelectChange = ({ target }) => {
    setSelectedTest(target.value);
  }

  const startDelete = (params) => {
    setSelectedRow(params.row);
    setDeleteDialog(true);
  }

  const deleteReg = () => {
    deleteRegistration({variables: { module_id: selectedRow.id, module_type: "Placement", student_id }});
    setDeleteDialog(false);
  }

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        width: regPref?.widths?.actions,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        hide: !["Admin", "Education Consultant"].includes(userRole),
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="primary"
            onClick={() => startDelete(params)}
          />,
        ],
      },
      {
        field: "id",
        headerName: "Test ID",
        width: regPref?.widths?.id,
        headerAlign: "center",
        align: "center",
        hide: !regPref?.visible?.id,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "name",
        headerName: "Name",
        width: regPref?.widths?.name,
        headerAlign: "center",
        align: "center",
        hide: !regPref?.visible?.name,
        filterable: false,
        getApplyQuickFilterFn: getApplyFilterFnName,
      },
      {
        field: "isCompleted",
        headerName: "Is Completed",
        width: regPref?.widths?.isCompleted,
        headerAlign: "center",
        align: "center",
        hide: !regPref?.visible?.isCompleted,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "createdAt",
        headerName: "Created At",
        width: regPref?.widths?.createdAt,
        headerAlign: "center",
        hide: !regPref?.visible?.createdAt,
        type: "dateTime",
        getApplyQuickFilterFn: undefined,
        filterable: false,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
      }
    ],
    [viewStudent]
  );
  columns.sort((a, b) => regPref.columns.indexOf(a.field) - regPref.columns.indexOf(b.field));

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Dialog open={deleteDialog}>
          <DialogTitle>Delete Registration</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this registration?
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
            <Button onClick={() => deleteReg()}>Delete</Button>
          </DialogActions>
      </Dialog>
      <Dialog open={dialog}>
          <DialogTitle>Register Test</DialogTitle>
          <DialogContent>
            Select the test to register<br />
            <FormControl variant="standard" sx={{ minWidth: "200px" }}>
              <Select
                value={selectedTest}
                onChange={handleSelectChange}
                autoWidth
                required
              >
                {tests.map(({ id, name }) => (
                  <MenuItem value={id} key={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button onClick={() => setDialog(false)}>Cancel</Button>
            <Button onClick={() => registerStudents()}>Register</Button>
          </DialogActions>
      </Dialog>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box />
          {
            ["Admin", "Education Consultant"].includes(userRole) && (
              <Button color="primary" gutterBottom variant="contained" sx={{ mb: 0 }} onClick={() => setDialog(true)}>
                Register Test
              </Button>
            )
          }
        </Box>
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true
            }
          }}
          pageSize={regPref.pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          loading={testsLoading}
          onColumnWidthChange={handleColumnWidthChange}
          onColumnOrderChange={handleColumnOrderChange}
          pagination
          pinnedColumns={{left: ["actions"]}}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          filterModel={regPref.filters}
          sortModel={regPref.sort}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          rowCount={rowCount}
        />
      </Paper>
    </Container>
  );
};

export default TestRegistrations;
