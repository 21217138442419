import { Container, Paper } from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import Instructions from "../components/Instructions";

const InstructionsPage = () => {
  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 9.1 }}>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Instructions />
        </Box>
      </Paper>
    </Container>
  );
};

export default InstructionsPage;
