import React, { useEffect, useState } from "react";
import { Split } from "@geoffcox/react-splitter";
import { Box } from "@mui/system";
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
  useEventCallback,
} from "@mui/material";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import parse from "html-react-parser";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { ModuleContext } from "../../App";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

const MultipleCheckBoxType = ({
  currentQuestion: {
    id,
    content,
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    answer8,
    answer9,
    answer10,
    correctAns,
    src,
    hint,
    solution
  },
  readOnly,
}) => {
  const moduleValue = React.useContext(ModuleContext);
  const splitterColors = {
    color: "#e0e0e0",
    hover: "#bdbdbd",
  };

  const options = {
    replace: ({ attribs }) => {
      if (!attribs) {
        return;
      }

      if (attribs["data-value"]) {
        const parsedHtml = attribs["data-value"];
        return <Latex>{`$${parsedHtml}$`}</Latex>;
      }
    },
  };

  const [checkedState, setCheckedState] = useState({
    "1": false,
    "2": false,
    "3": false,
    "4": false,
    "5": false,
    "6": false,
    "7": false,
    "8": false,
    "9": false,
    "10": false,
  });

  const handleChange = (event) => {
    setCheckedState({
      ...checkedState,
      [event.target.value]: event.target.checked,
    });
  }

  useEffect(() => {
    let initialChecked = [false, false, false, false, false, false, false, false, false, false];
    if (moduleValue.moduleSettings.selectedAnswer) {
      let selectedAnswer = moduleValue.moduleSettings.selectedAnswer.split(",");
      for (let i = 0; i < selectedAnswer.length; i++) {
        initialChecked[parseInt(selectedAnswer[i]) - 1] = true;
      }
    }
    
    let checkedObject = {};
    for (let i = 0; i < initialChecked.length; i++) {
      checkedObject[`${(i + 1).toString()}`] = initialChecked[i];
    }
    setCheckedState({...checkedObject});
  }, [id]);

  useEffect(() => {
    let selectedArray = [];
    for (let i = 1; i < 10; i++) {
      if (checkedState[`${i.toString()}`] === true) selectedArray.push(i);
    }
    moduleValue.setModuleSettings({
      ...moduleValue.moduleSettings,
      selectedAnswer: selectedArray.join(","),
    })
  }, [checkedState]);

  const getCorrectAnswer = (correctAns) => {
    switch (parseInt(correctAns)) {
      case 1:
        return answer1 ? parse(answer1, options) : "";
      case 2:
        return answer2 ? parse(answer2, options) : "";
      case 3:
        return answer3 ? parse(answer3, options) : "";
      case 4:
        return answer4 ? parse(answer4, options) : "";
      case 5:
        return answer5 ? parse(answer5, options) : "";
      case 6:
        return answer6 ? parse(answer6, options) : "";
      case 7:
        return answer7 ? parse(answer7, options) : "";
      case 8:
        return answer8 ? parse(answer8, options) : "";
      case 9:
        return answer9 ? parse(answer9, options) : "";
      case 10:
        return answer10 ? parse(answer10, options) : "";
      default:
        return null;
    }
  };

  const getCorrectAnswers = (correctAnswers) => {
    const correctAnswersArray = correctAnswers.split(",");
    const newAnswersArray = correctAnswersArray.map((item) =>
      getCorrectAnswer(item)
    );
    return newAnswersArray.join();
  };

  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Split
          minPrimarySize="15%"
          minSecondarySize="15%"
          initialPrimarySize="50%"
          resetOnDoubleClick
          splitterSize="32px"
          defaultSplitterColors={splitterColors}
        >
          <div>
            {src && (
              <Typography variant="p">{parse(src, options)}</Typography>
            )}
            {!src && content && (
              <Typography variant="p">{parse(content, options)}</Typography>
            )}
          </div>
          <>
            {(src) && content && (
              <Typography variant="p">{parse(content, options)}</Typography>
            )}
            <FormControl component="fieldset" sx={{ m: 2 }}>
              <FormGroup>
                {answer1 && (
                  <FormControlLabel
                    value={"1"}
                    size="medium"
                    control={<Checkbox size="medium" />}
                    label={parse(answer1, options)}
                    key={1}
                    onChange={handleChange}
                    disabled={readOnly}
                    checked={checkedState["1"]}
                  />
                )}
                {answer2 && (
                  <FormControlLabel
                    value={"2"}
                    size="medium"
                    control={<Checkbox size="medium" />}
                    label={parse(answer2, options)}
                    key={2}
                    onChange={handleChange}
                    disabled={readOnly}
                    checked={checkedState["2"]}
                  />
                )}
                {answer3 && (
                  <FormControlLabel
                    value={"3"}
                    size="medium"
                    control={<Checkbox size="medium" />}
                    label={parse(answer3, options)}
                    key={3}
                    onChange={handleChange}
                    disabled={readOnly}
                    checked={checkedState["3"]}
                  />
                )}
                {answer4 && (
                  <FormControlLabel
                    value={"4"}
                    size="medium"
                    control={<Checkbox size="medium" />}
                    label={parse(answer4, options)}
                    key={4}
                    onChange={handleChange}
                    disabled={readOnly}
                    checked={checkedState["4"]}
                  />
                )}
                {answer5 && (
                  <FormControlLabel
                    value={"5"}
                    size="medium"
                    control={<Checkbox size="medium" />}
                    label={parse(answer5, options)}
                    key={5}
                    onChange={handleChange}
                    disabled={readOnly}
                    checked={checkedState["5"]}
                  />
                )}
                {answer6 && (
                  <FormControlLabel
                    value={"6"}
                    size="medium"
                    control={<Checkbox size="medium" />}
                    label={parse(answer6, options)}
                    key={6}
                    onChange={handleChange}
                    disabled={readOnly}
                    checked={checkedState["6"]}
                  />
                )}
                {answer7 && (
                  <FormControlLabel
                    value={"7"}
                    size="medium"
                    control={<Checkbox size="medium" />}
                    label={parse(answer7, options)}
                    key={7}
                    onChange={handleChange}
                    disabled={readOnly}
                    checked={checkedState["7"]}
                  />
                )}
                {answer8 && (
                  <FormControlLabel
                    value={"8"}
                    size="medium"
                    control={<Checkbox size="medium" />}
                    label={parse(answer8, options)}
                    key={8}
                    onChange={handleChange}
                    disabled={readOnly}
                    checked={checkedState["8"]}
                  />
                )}
                {answer9 && (
                  <FormControlLabel
                    value={"9"}
                    size="medium"
                    control={<Checkbox size="medium" />}
                    label={parse(answer9, options)}
                    key={9}
                    onChange={handleChange}
                    disabled={readOnly}
                    checked={checkedState["9"]}
                  />
                )}
                {answer10 && (
                  <FormControlLabel
                    value={"10"}
                    size="medium"
                    control={<Checkbox size="medium" />}
                    label={parse(answer10, options)}
                    key={10}
                    onChange={handleChange}
                    disabled={readOnly}
                    checked={checkedState["10"]}
                  />
                )}
              </FormGroup>
            </FormControl>
            {correctAns && (
              <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
                <strong>Correct answer:</strong>{" "}
                {getCorrectAnswers(correctAns, options)}
              </Alert>
            )}
            {hint && (
              <Alert
                severity="info"
                iconMapping={{
                  info: <WbIncandescentIcon fontSize="inherit" />,
                }}
                sx={{ ml: 2 }}
              >
                <strong>
                  Hint: <br></br>
                </strong>
                {parse(hint, options)}
              </Alert>
            )}
            {solution && (
              <Alert
                severity="warning"
                iconMapping={{
                  warning: <RadioButtonCheckedIcon fontSize="inherit" />,
                }}
                sx={{ ml: 2, mt: 2 }}
              >
                <strong>
                  Solution: <br></br>
                </strong>
                {parse(solution, options)}
              </Alert>
            )}
          </>
        </Split>
      </Box>
    </>
  );
};

export default MultipleCheckBoxType;
