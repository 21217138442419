import {
  Autocomplete,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar, GridActionsCellItem, useGridApiRef, gridQuickFilterValuesSelector } from "@mui/x-data-grid-pro";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { format } from "date-fns";
import { PreferenceContext } from "../App";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET_REPORT_TESTS, GET_UNADDED_TESTS } from "../graphql/queries";
import { ADD_REPORT_TEST, DELETE_REPORT_TEST } from "../graphql/mutations";

const ReportRegistrations = ({ report_id }) => {
  const { preferences, setPreferences } = React.useContext(PreferenceContext);
  let testPref = {...preferences.reportTest};
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const testTypes = ["Reading", "General Ability", "Speed & Accuracy", "Method & Strategy", "Mathematics"];
  const [testType, setTestType] = useState("");

  const [getReportTests, { testsLoading, testsError, testsData }] = useLazyQuery(GET_REPORT_TESTS, {
    onCompleted: (tests) => {
      if (tests?.getReportTests.length > 0) {
        setRows(tests.getReportTests.map((t, i) => ({
          id: i,
          ...t
        })));
      }
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [getUnaddedTests] = useLazyQuery(GET_UNADDED_TESTS, {
    onCompleted: (tests) => {
      if (tests?.getUnaddedTests.length > 0) {
        setTests(tests.getUnaddedTests);
      }
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [addReportTest] = useMutation(ADD_REPORT_TEST, {
    onCompleted: (test) => {
      if (test.addReportTest === true) {
        enqueueSnackbar(
          `Test added`,
          {
            variant: "success",
          }
        );
        getReportTests({
          variables: {
            report_id
          }
        });
      } else {
        enqueueSnackbar(
          `Test unable to be added`,
          {
            variant: "error",
          }
        );
      }
    },
    fetchPolicy: 'network-only',
  });

  const [deleteReportTest] = useMutation(DELETE_REPORT_TEST, {
    onCompleted: (test) => {
      if (test.deleteReportTest === true) {
        enqueueSnackbar(
          `Test deleted`,
          {
            variant: "success",
          }
        );
        getReportTests({
          variables: {
            report_id
          }
        });
      } else {
        enqueueSnackbar(
          `Test not found`,
          {
            variant: "error",
          }
        );
      }
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getReportTests({
      variables: {
        report_id,
      }
    });
  }, []);

  useEffect(() => {
    if (dialog === true) {
      getUnaddedTests({
        variables: { report_id }
      });
    }
  }, [dialog]);

  const handleColumnWidthChange = (params) => {
    testPref.widths[params.colDef.field] = params.width;
    setPreferences({
      ...preferences,
      reportTest: {
        ...testPref
      }
    });
  };

  const handleColumnOrderChange = (params) => {
    testPref.columns.splice(params.oldIndex, 1);
    testPref.columns.splice(params.targetIndex, 0, params.field);
    setPreferences({
      ...preferences,
      reportTest: {
        ...testPref
      }
    });
  };

  const handleColumnVisibilityModelChange = (model) => {
    testPref.visible = {...model};
    setPreferences({
      ...preferences,
      reportTest: {
        ...testPref
      }
    });
  }

  function getTestName(params) {
    return params.row.Test.name;
  }

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const addReportTestFunction = () => {
    addReportTest({variables: { report_id, test_id: selectedTest, test_type: testType }});
    setDialog(false);
  }

  const handleSelectChange = ({ target }) => {
    setSelectedTest(target.value);
  }

  const startDelete = (params) => {
    console.log(params.row);
    setSelectedRow(params.row);
    setDeleteDialog(true);
  }

  const deleteReg = () => {
    deleteReportTest({variables: { report_id, test_id: selectedRow.testId }});
    setDeleteDialog(false);
  }

  const handleTestTypeChange = ({ target }) => {
    setTestType(target.value);
  }

  useEffect(() => {
    console.log(rows);
  }, [rows])

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        width: testPref?.widths?.actions,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="primary"
            onClick={() => startDelete(params)}
          />,
        ],
      },
      {
        field: "testId",
        headerName: "Test ID",
        width: testPref?.widths?.testId,
        headerAlign: "center",
        align: "center",
        hide: !testPref?.visible?.testId,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "testName",
        headerName: "Test Name",
        width: testPref?.widths?.testName,
        headerAlign: "center",
        align: "center",
        hide: !testPref?.visible?.testName,
        filterable: false,
        getApplyQuickFilterFn: getApplyFilterFnName,
        valueGetter: getTestName,
      },
      {
        field: "psType",
        headerName: "Profile Trait",
        width: testPref?.widths?.psType,
        headerAlign: "center",
        align: "center",
        hide: !testPref?.visible?.psType,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "createdAt",
        headerName: "Created At",
        width: testPref?.widths?.createdAt,
        headerAlign: "center",
        hide: !testPref?.visible?.createdAt,
        type: "dateTime",
        getApplyQuickFilterFn: undefined,
        filterable: false,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
      },
      {
        field: "updatedAt",
        headerName: "Updated At",
        width: testPref?.widths?.updatedAt,
        headerAlign: "center",
        hide: !testPref?.visible?.updatedAt,
        type: "dateTime",
        getApplyQuickFilterFn: undefined,
        filterable: false,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
      }
    ],
    []
  );
  columns.sort((a, b) => testPref.columns.indexOf(a.field) - testPref.columns.indexOf(b.field));

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Dialog open={deleteDialog}>
        <DialogTitle>Delete Registration</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this test?
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
          <Button onClick={() => deleteReg()}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialog}>
        <DialogTitle>Add Test</DialogTitle>
        <DialogContent>
          Select the test to add<br />
          <FormControl variant="standard" sx={{ minWidth: "200px" }}>
            <Select
              value={selectedTest}
              onChange={handleSelectChange}
              autoWidth
              required
            >
              {tests.map(({ id, name }) => (
                <MenuItem value={id} key={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <br />
          <FormControl variant="standard" sx={{ minWidth: "200px" }}>
            <InputLabel>Test Type</InputLabel>
            <Select
              id="testtype"
              value={testType}
              onChange={handleTestTypeChange}
            >
              {testTypes.map((t, i) => (
                <MenuItem value={t} key={i}>
                  {t}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button onClick={() => setDialog(false)}>Cancel</Button>
          <Button onClick={() => addReportTestFunction()}>Register</Button>
        </DialogActions>
      </Dialog>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box />
          <Button color="primary" gutterBottom variant="contained" sx={{ mb: 0 }} onClick={() => setDialog(true)}>
            Add Test
          </Button>
        </Box>
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true
            }
          }}
          disableSelectionOnClick
          autoHeight
          loading={testsLoading}
          onColumnWidthChange={handleColumnWidthChange}
          onColumnOrderChange={handleColumnOrderChange}
          pagination
          pinnedColumns={{left: ["actions"]}}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        />
      </Paper>
    </Container>
  );
};

export default ReportRegistrations;
