import {
  Container,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { AuthenticatedContext } from "../App";
import StudentUpdateDetails from "../components/StudentUpdateDetails";
import StaffUpdateDetails from "../components/StaffUpdateDetails";

const UpdateDetails = () => {
  const value = React.useContext(AuthenticatedContext);

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 3 }}>
          Update Details
        </Typography>
        { value.userRole === "Student" ? (
          <StudentUpdateDetails />
        ) : (
          <StaffUpdateDetails />
        )}
      </Paper>
    </Container>
  );
};

export default UpdateDetails;
