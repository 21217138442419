import React from "react";
import { Box } from "@mui/system";
import { Split } from "@geoffcox/react-splitter";
import {
  Alert,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import parse from "html-react-parser";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import { useState } from "react";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { ModuleContext } from "../../App";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

const InlineSelectType = ({
  currentQuestion: {
    id,
    content,
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    answer8,
    answer9,
    answer10,
    correctAns,
    src,
    hint,
    solution,
  },
  readOnly,
  isTrainingAnswerCorrect,
  isResultShown,
  isReview,
}) => {
  const moduleValue = React.useContext(ModuleContext);
  const splitterColors = {
    color: "#e0e0e0",
    hover: "#bdbdbd",
  };
  const options = {
    replace: ({ attribs }) => {
      if (!attribs) {
        return;
      }

      if (attribs["data-value"]) {
        const parsedHtml = attribs["data-value"];
        return <Latex>{`$${parsedHtml}$`}</Latex>;
      }
    },
  };
  const handleSelectChange = ({ target }) => {
    //console.log(target.value);
    moduleValue.setModuleSettings({
      ...moduleValue.moduleSettings,
      selectedAnswer: target.value,
    });
  };

  const questionSplit = () => content.split("[select-answer]");

  const selectOptions = () => {
    return [
      { text: parse(answer1, options), value: "1" },
      { text: parse(answer2, options), value: "2" },
      ...(answer3 ? [{ text: parse(answer3, options), value: "3" }] : []),
      ...(answer4 ? [{ text: parse(answer4, options), value: "4" }] : []),
      ...(answer5 ? [{ text: parse(answer5, options), value: "5" }] : []),
      ...(answer6 ? [{ text: parse(answer6, options), value: "6" }] : []),
      ...(answer7 ? [{ text: parse(answer7, options), value: "7" }] : []),
      ...(answer8 ? [{ text: parse(answer8, options), value: "8" }] : []),
      ...(answer9 ? [{ text: parse(answer9, options), value: "9" }] : []),
      ...(answer10 ? [{ text: parse(answer10, options), value: "10" }] : []),
    ];
  };

  const getCorrectAnswer = (correctAns) => {
    switch (parseInt(correctAns)) {
      case 1:
        return answer1 ? parse(answer1, options) : "";
      case 2:
        return answer2 ? parse(answer2, options) : "";
      case 3:
        return answer3 ? parse(answer3, options) : "";
      case 4:
        return answer4 ? parse(answer4, options) : "";
      case 5:
        return answer5 ? parse(answer5, options) : "";
      case 6:
        return answer6 ? parse(answer6, options) : "";
      case 7:
        return answer7 ? parse(answer7, options) : "";
      case 8:
        return answer8 ? parse(answer8, options) : "";
      case 9:
        return answer9 ? parse(answer9, options) : "";
      case 10:
        return answer10 ? parse(answer10, options) : "";
      default:
        return null;
    }
  };

  return src ? (
    <Box sx={{ pb: 2, pl: 3 }}>
      <Split
        minPrimarySize="15%"
        minSecondarySize="15%"
        initialPrimarySize="50%"
        resetOnDoubleClick
        splitterSize="32px"
        defaultSplitterColors={splitterColors}
      >
        <div>
          {src && <Typography variant="h6">{parse(src, options)}</Typography>}
        </div>

        <div>
          <Typography variant="h6" sx={{ pr: 1, lineHeight: 2.5 }}>
            {parse(questionSplit()[0], options)}
          </Typography>
          <FormControl variant="standard" sx={{ minWidth: "200px" }}>
            <Select
              value={moduleValue.moduleSettings.selectedAnswer}
              onChange={handleSelectChange}
              autoWidth
              disabled={readOnly}
            >
              {selectOptions().map(({ value, text }) => (
                <MenuItem value={value} key={value}>
                  {text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="h6" sx={{ pl: 1, lineHeight: 2.5 }}>
            {questionSplit().length > 1 && parse(questionSplit()[1], options)}
          </Typography>

          {correctAns && (
            <Alert severity="success" sx={{ mt: 2 }}>
              <strong>Correct Answer:</strong> {getCorrectAnswer(correctAns)}
            </Alert>
          )}
          {hint && (
            <Alert
              severity="info"
              iconMapping={{
                info: <WbIncandescentIcon fontSize="inherit" />,
              }}
            >
              <strong>
                Hint: <br></br>
              </strong>
              {parse(hint, options)}
            </Alert>
          )}
          {solution && (
            <Alert
              severity="warning"
              iconMapping={{
                warning: <RadioButtonCheckedIcon fontSize="inherit" />,
              }}
              sx={{ ml: 2 }}
            >
              <strong>
                Solution: <br></br>
              </strong>
              {parse(solution, options)}
            </Alert>
          )}
        </div>
      </Split>
    </Box>
  ) : (
    <Box sx={{ pb: 2, pl: 3 }}>
      <Typography variant="h6" sx={{ pr: 1, lineHeight: 2.5 }}>
        {parse(questionSplit()[0], options)}
      </Typography>

      <FormControl variant="standard" sx={{ minWidth: "200px" }}>
        <Select
          value={moduleValue.moduleSettings.selectedAnswer}
          onChange={handleSelectChange}
          autoWidth
        >
          {selectOptions().map(({ value, text }) => (
            <MenuItem value={value} key={value}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography variant="h6" sx={{ pl: 1, lineHeight: 2.5 }}>
        {questionSplit().length > 1 && parse(questionSplit()[1], options)}
      </Typography>
      {isResultShown && isTrainingAnswerCorrect && (
        <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
          <strong>Correct!</strong>
        </Alert>
      )}
      {correctAns && isResultShown && !isTrainingAnswerCorrect && (
        <Alert severity="error" sx={{ ml: 2, mb: 2 }}>
          <strong>Wrong answer.</strong>
        </Alert>
      )}
      {correctAns && isResultShown && !isTrainingAnswerCorrect && (
        <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
          <strong>Correct answer:</strong> {getCorrectAnswer(correctAns)}
        </Alert>
      )}
      {correctAns && isReview && (
        <Alert severity="success" sx={{ ml: 2, mt: 2 }}>
          <strong>Correct answer:</strong> {getCorrectAnswer(correctAns)}
        </Alert>
      )}
      {hint && isResultShown && !isTrainingAnswerCorrect && (
        <Alert
          severity="info"
          iconMapping={{
            info: <WbIncandescentIcon fontSize="inherit" />,
          }}
          sx={{ ml: 2, mb: 2 }}
        >
          <strong>
            Hint: <br></br>
          </strong>
          {parse(hint, options)}
        </Alert>
      )}
      {hint && isReview && (
        <Alert
          severity="info"
          iconMapping={{
            info: <WbIncandescentIcon fontSize="inherit" />,
          }}
          sx={{ ml: 2, mb: 2 }}
        >
          <strong>
            Hint: <br></br>
          </strong>
          {parse(hint, options)}
        </Alert>
      )}
      {solution && isResultShown && !isTrainingAnswerCorrect && (
        <Alert
          severity="warning"
          iconMapping={{
            warning: <RadioButtonCheckedIcon fontSize="inherit" />,
          }}
          sx={{ ml: 2 }}
        >
          <strong>
            Solution: <br></br>
          </strong>
          {parse(solution, options)}
        </Alert>
      )}
      {solution && isReview && (
        <Alert
          severity="warning"
          iconMapping={{
            warning: <RadioButtonCheckedIcon fontSize="inherit" />,
          }}
          sx={{ ml: 2 }}
        >
          <strong>
            Solution: <br></br>
          </strong>
          {parse(solution, options)}
        </Alert>
      )}
    </Box>
  );
};

export default InlineSelectType;
