import {
  Button,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import RadialBarGraph from "./graphs/RadialBarGraph";
import TrainingBarGraph from "./graphs/TrainingBarGraph";
import { GET_ACTIVITY_REPORT, GET_TRAINING_SUMMARY } from "../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import logo from "../assets/NSCC_logo.png";

const InteractiveReport = ({ openFn, student_id, training_module }) => {
  const [open, setOpen] = useState(true);
  const [result, setResult] = useState({});

  const convertTime = (toConvert) => {
    if (toConvert < 60) {
      return `${Math.round(toConvert * 10) / 10} second${(Math.round(toConvert * 10) / 10 === 1 ? "" : "s")}`;
    } else if (toConvert < 3600) {
      return `${Math.round(toConvert / 6) / 10} minute${(Math.round(toConvert / 6) / 10 === 1 ? "" : "s")}`;
    } else {
      return `${Math.round(toConvert / 144) / 10} hour${(Math.round(toConvert / 144) / 10 === 1 ? "" : "s")}`;
    }
  };

  const [getActivityReport, { reportLoading, reportError, reportData }] = useLazyQuery(GET_ACTIVITY_REPORT, {
    onCompleted: ({ getActivityReport }) => {
      let report = {...getActivityReport};
      report.total = convertTime(report.totalTime);
      report.average = convertTime(report.totalTime / report.attempts);
      setResult(report);
    }
  });

  useEffect(() => {
    getActivityReport({
      variables: {
        student_id,
        training_module
      }
    })
  }, []);
  return (
    <Dialog open={open} fullScreen>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => openFn(false)}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={logo}
            alt="NSCC Logo"
            style={{ marginRight: "32px", height: "60px", maxWidth: "100px" }}
          />
        </Toolbar>
      </AppBar>
      <Box sx={{ margin: 3, mt: 1 }} >
        <Stack direction="row" justifyContent="space-between">
          <Box />
          <Typography variant="h4" component="div">
            { result.trainingName }
          </Typography>
        </Stack>
        <Typography variant="h6">Attempts: {result.attempts}</Typography>
        <Typography variant="h6">Total Time Spent on Activity: {result.total}</Typography>
        <Typography variant="h6">Average Time Spent on Activity: {result.average}</Typography>
      </Box>
    </Dialog>
  )
};

export default InteractiveReport;