import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar, GridActionsCellItem, useGridApiRef, gridQuickFilterValuesSelector } from "@mui/x-data-grid-pro";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { format } from "date-fns";
import { AuthenticatedContext, PreferenceContext } from "../App";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET_REPORT_STUDENTS } from "../graphql/queries";
import { CREATE_NOTIFICATIONS, GENERATE_TRAINING_RECOMMENDATIONS } from "../graphql/mutations";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TestReport from "./TestReport";
// import PlacementReport from "./PlacementReport";

const ReportStudents = ({ test_id, program_id }) => {
  const { preferences, setPreferences, defaults } = React.useContext(PreferenceContext);
  const { userRole } = React.useContext(AuthenticatedContext);
  let stuPref = {...preferences.reportStudent};
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [reportDialog, setReportDialog] = useState(false);
  const [notifyDialog, setNotifyDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [numTests, setNumTests] = useState(0);

  const [getReportStudents, { studentsLoading, studentsError, studentsData }] = useLazyQuery(GET_REPORT_STUDENTS, {
    onCompleted: ({ getReportStudents }) => {
      if (getReportStudents.students.length > 0) {
        setRows(getReportStudents.students);
      }
      if (getReportStudents.numTests) {
        setNumTests(getReportStudents.numTests);
      }
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    // notifyOnNetworkStatusChange: true
  });

  const [createNotifications] = useMutation(CREATE_NOTIFICATIONS, {
    onCompleted: (notifs) => {
      setSelectedRows([]);
      if (notifs.createNotifications > 0) {
        if (test_id) {
          getReportStudents({
            variables: {
              test_id,
            }
          });
        } else if (program_id) {
          getReportStudents({
            variables: {
              program_id,
            }
          });
        }
      }
    },
    onError: (error) => {
      console.log(`create notification error: ${error}`);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (test_id) {
      getReportStudents({
        variables: {
          test_id,
        }
      });
    } else if (program_id) {
      getReportStudents({
        variables: {
          program_id,
        }
      });
    }
  }, []);

  useEffect(() => {
    if (rows.length > 0) {
      if (!!(rows[0].count)) {
        stuPref.visible.count = true;
      } else {
        stuPref.visible.count = false;
      }
      setPreferences({
        ...preferences,
        reportStudent: {
          ...stuPref
        }
      });
    }
  }, [rows]);

  const handleColumnWidthChange = (params) => {
    stuPref.widths[params.colDef.field] = params.width;
    setPreferences({
      ...preferences,
      reportStudent: {
        ...stuPref
      }
    });
  };

  const handleColumnOrderChange = (params) => {
    stuPref.columns.splice(params.oldIndex, 1);
    stuPref.columns.splice(params.targetIndex, 0, params.field);
    setPreferences({
      ...preferences,
      reportStudent: {
        ...stuPref
      }
    });
  };

  const handleColumnVisibilityModelChange = (model) => {
    stuPref.visible = {...model};
    setPreferences({
      ...preferences,
      reportStudent: {
        ...stuPref
      }
    });
  };

  function getStudentName(params) {
    return params.row.Student.givenname + " " + params.row.Student.surname;
  };

  function getStudentNumber(params) {
    return params.row.Student.login;
  };

  function getIsSent(params) {
    return params.row.Student.Notifications?.length > 0;
  };

  function getIsRead(params) {
    return params.row.Student.Notifications?.length > 0 ? (params.row.Student.Notifications[0]?.isRead ? true : false) : null;
  };

  function getCreatedAt(params) {
    return params.row.Student.Notifications?.length > 0 ? params.row.Student.Notifications[0]?.createdAt : null;
  };

  function getCampus(params) {
    return params.row.Student.Centre?.name;
  }

  function getIsGenerated(params) {
    if (params.row.Student.Results?.length === 0) return false;
    return [...new Set(params.row.Student.Results.map(r => r.psType))].length === 6;
  }

  function getIsRecommended(params) {
    return params.row.Student.Trainings?.length > 0 && params.row.Student.Trainings.filter(t => t.registrationId).length > 0;
  }

  function isDisabled(params) {
    if (test_id) return false;
    return (params.row.count !== numTests || [...new Set(params.row.Student.Results.map(r => r.psType))].length !== 6);
  }

  function getTestCount(params) {
    return params.row.count;
  }

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const viewReport = (params) => {
    // setSelectedRow(params.row);
    // setReportDialog(true);
    history.push(`/report/${program_id}/${params.row.Student?.id}`);
  }

  const sendNotifications = () => {
    createNotifications({
      variables: {
        ...(test_id) ? { test_id } : {},
        ...(program_id) ? { program_id } : {},
        ...(selectedRows.length > 0) ? { send_all: false, student_ids: selectedRows } : { send_all: true },
      }
    });
    setNotifyDialog(false);
  }

  const columns = useMemo(
    () => {
      let options = [
        {
          field: "actions",
          type: "actions",
          disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
          headerName: "Actions",
          width: stuPref?.widths?.actions,
          headerAlign: "center",
          disableReorder: true,
          hideable: false,
          getApplyQuickFilterFn: undefined,
          getActions: (params) => [
            <GridActionsCellItem
              icon={<PictureAsPdfIcon />}
              label="View Report"
              color="primary"
              onClick={() => viewReport(params)}
              disabled={isDisabled(params)}
            />,
          ],
        },
        {
          field: "studentNumber",
          headerName: "Student Number",
          width: stuPref?.widths?.studentNumber,
          headerAlign: "center",
          align: "center",
          hide: !stuPref?.visible?.studentNumber,
          getApplyQuickFilterFn: undefined,
          valueGetter: getStudentNumber,
        },
        {
          field: "studentName",
          headerName: "Student Name",
          width: stuPref?.widths?.studentName,
          headerAlign: "center",
          align: "center",
          hide: !stuPref?.visible?.studentName,
          filterable: false,
          getApplyQuickFilterFn: getApplyFilterFnName,
          valueGetter: getStudentName,
        },
        {
          field: "campus",
          headerName: "Campus",
          width: stuPref?.widths?.campus,
          headerAlign: "center",
          align: "center",
          hide: !stuPref?.visible?.campus,
          getApplyQuickFilterFn: undefined,
          valueGetter: getCampus,
        }
      ];
      if (program_id) {
        options.push(
          {
            field: "countTest",
            headerName: "Completed Tests",
            width: stuPref?.widths?.countTest,
            headerAlign: "center",
            align: "center",
            hide: !stuPref?.visible?.countTest,
            getApplyQuickFilterFn: undefined,
            type: "number",
            valueGetter: getTestCount,
          },
          {
            field: "reports",
            headerName: "Results Generated?",
            width: stuPref?.widths?.reports,
            headerAlign: "center",
            align: "center",
            hide: !stuPref?.visible?.reports,
            getApplyQuickFilterFn: undefined,
            valueGetter: getIsGenerated,
            type: "boolean"
          },
          {
            field: "trainings",
            headerName: "Trainings Recommended?",
            width: stuPref?.widths?.trainings,
            headerAlign: "center",
            align: "center",
            hide: !stuPref?.visible?.trainings,
            getApplyQuickFilterFn: undefined,
            valueGetter: getIsRecommended,
            type: "boolean"
          }
        )
      }
      options.push(
        {
          field: "isSent",
          headerName: "Notification Sent",
          width: stuPref?.widths?.isSent,
          headerAlign: "center",
          align: "center",
          hide: !stuPref?.visible?.isSent,
          getApplyQuickFilterFn: undefined,
          valueGetter: getIsSent,
          type: "boolean"
        },
        {
          field: "isRead",
          headerName: "Notification Read",
          width: stuPref?.widths?.isRead,
          headerAlign: "center",
          align: "center",
          hide: !stuPref?.visible?.isRead,
          getApplyQuickFilterFn: undefined,
          valueGetter: getIsRead,
          type: "boolean"
        },
        {
          field: "createdAt",
          headerName: "Notification Created At",
          width: stuPref?.widths?.createdAt,
          headerAlign: "center",
          hide: !stuPref?.visible?.createdAt,
          type: "dateTime",
          getApplyQuickFilterFn: undefined,
          filterable: false,
          valueFormatter: ({ value }) => {
            if (value) {
              return format(value, "dd/MM/yyyy");
            }
          },
          valueGetter: getCreatedAt,
          align: "center",
          filterable: false,
        }
      );
      return options;
    },
    [numTests, test_id, program_id]
  );
  columns.sort((a, b) => stuPref?.columns?.indexOf(a.field) - stuPref?.columns?.indexOf(b.field));

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Dialog open={notifyDialog}>
          <DialogTitle>Send Notifications</DialogTitle>
          <DialogContent>
            Are you sure you want to send notifications to {selectedRows.length > 0 ? selectedRows.length : "ALL" } students?
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button onClick={() => setNotifyDialog(false)}>Cancel</Button>
            <Button onClick={() => sendNotifications()}>Send Notifications</Button>
          </DialogActions>
      </Dialog>
      <Dialog open={reportDialog} fullScreen>
        {(rows.length > 0 && !!(rows[0].count)) ? (
          // <PlacementReport openFn={setReportDialog} program_id={program_id} student_id={selectedRow.Student?.id} student_name={selectedRow.Student?.givenname + " " + selectedRow.Student?.surname} student_number={selectedRow.Student?.login} />
          <Typography>Hi</Typography>
        ) : (
          <TestReport openFn={setReportDialog} test_id={test_id} student_id={selectedRow.Student?.id} student_name={selectedRow.Student?.givenname + " " + selectedRow.Student?.surname} />
        )}
        
      </Dialog>
      <Paper elevation={0} sx={{ p: 3 }}>
        { ["Admin", "Education Consultant"].includes(userRole) && (
          <Box
            sx={{
              display: "flex",
              mb: 3,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box />
            <Button color="primary" gutterBottom variant="contained" sx={{ mb: 0 }} onClick={() => setNotifyDialog(true)}>
              Send Reports
            </Button>
          </Box>
        )}
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true
            }
          }}
          disableSelectionOnClick
          autoHeight
          loading={studentsLoading}
          onColumnWidthChange={handleColumnWidthChange}
          onColumnOrderChange={handleColumnOrderChange}
          pagination
          pinnedColumns={{left: ["actions"]}}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          getRowId={(row) => row.Student.id}
          checkboxSelection={true}
          onSelectionModelChange={(newSelectedRows) => {
            setSelectedRows(newSelectedRows)
          }}
          selectionModel={selectedRows}
          isRowSelectable={(params) => params.row.Student.Trainings?.length > 0 && params.row.Student.Notifications?.length === 0}
        />
      </Paper>
    </Container>
  );
};

export default ReportStudents;
