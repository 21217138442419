import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSnackbar } from "notistack";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { GET_ALLOCATED_STUDENTS_BY_CLASS, GET_ALL_CAMPUS_NAMES, GET_ALL_TRAINING_NAMES, GET_COURSE_CURRICULA, GET_GRADES, GET_STUDENTS_BY_QUERY } from "../../graphql/queries";
import { ALLOCATE_STUDENT_TO_CLASS, DEALLOCATE_STUDENTS } from "../../graphql/mutations";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import renderCellExpand from "../../widgets/renderCellExpand";
import { useParams, useHistory } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AuthenticatedContext } from "../../App";

const SelectStudent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userRole } = useContext(AuthenticatedContext);
  let { id: class_id } = useParams();
  const [studentName, setStudentName] = useState("");
  const [studentID, setStudentID] = useState("");
  const [trainingModule, setTrainingModule] = useState("");
  const [trainingModules, setTrainingModules] = useState([]);
  const [unselectedStudents, setUnselectedStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteStudentID, setDeleteStudentID] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [grade, setGrade] = useState("");
  const [grades, setGrades] = useState([]);
  const [campus, setCampus] = useState("");
  const [campuses, setCampuses] = useState([]);
  const [curricula, setCurricula] = useState([]);
  const [curriculum, setCurriculum] = useState("");
  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);
  const history = useHistory();
  
  const [getCourseCurricula] = useLazyQuery(GET_COURSE_CURRICULA, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getCourseCurricula }) => {
      if (getCourseCurricula?.length !== 0) {
        setCurricula(getCourseCurricula);
      }
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [getAllTrainingNames] = useLazyQuery(GET_ALL_TRAINING_NAMES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAllTrainingNames }) => {
      if (getAllTrainingNames?.length !== 0) {
        setTrainingModules(getAllTrainingNames);
      }
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [getAllocatedStudentsByClass, { data: selectedStudentsData }] = useLazyQuery(GET_ALLOCATED_STUDENTS_BY_CLASS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAllocatedStudentsByClass }) => {
      setSelectedStudents(getAllocatedStudentsByClass);
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [getStudentsByQuery, { loading: getStudentsByQueryLoading }] = useLazyQuery(GET_STUDENTS_BY_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getStudentsByQuery }) => {
      if (getStudentsByQuery.length !== 0) {
        if (selectedStudentsData) {
          const studentsIDs = selectedStudentsData?.getAllocatedStudentsByClass?.map(i => i.id)
          const filteredStudents = getStudentsByQuery.filter(s => {
            return !studentsIDs.includes(s.id)
          })

          if (filteredStudents?.length !== 0) {
            setUnselectedStudents(filteredStudents)
          } else {
            enqueueSnackbar("No student found", { variant: "warning" })
            clearSearchData()
          }
        } else {
          setUnselectedStudents(getStudentsByQuery)
        }
      } else {
        enqueueSnackbar("No student found", { variant: "warning" })
        clearSearchData()
      }
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [deallocateStudent] = useMutation(DEALLOCATE_STUDENTS, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ deallocateStudent }) => {
      if (deallocateStudent) {
        enqueueSnackbar("Student has been successfully deallocated. ", { variant: "success" });
        setDeleteDialog(false);
      }
    },
    refetchQueries: [{ query: GET_ALLOCATED_STUDENTS_BY_CLASS, variables: { class_id: Number(class_id) }}, 'getAllocatedStudentsByClass'],
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  })

  const [allocateStudentToClass] = useMutation(ALLOCATE_STUDENT_TO_CLASS, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ allocateStudentToClass }) => {
      if (allocateStudentToClass) {
        enqueueSnackbar(`${selectionModel?.length} Student(s) has been successfully allocated.`, { variant: "success" })
        if (selectionModel) {
          setUnselectedStudents(unselectedStudents?.filter(s => !selectionModel.includes(s.id)))
        }
      }
    },
    refetchQueries: [
      { query: GET_ALLOCATED_STUDENTS_BY_CLASS, variables: { class_id: Number(class_id) }}, 'getAllocatedStudentsByClass',
    ],
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [getAllCampusNames] = useLazyQuery(GET_ALL_CAMPUS_NAMES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAllCampusNames }) => {
      if (getAllCampusNames?.length !== 0) {
        setCampuses(getAllCampusNames);
      }
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  const [getGrades] = useLazyQuery(GET_GRADES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getGrades }) => {
      if (getGrades?.length !== 0) {
        setGrades(getGrades);
      }
    },
    onError: error => {
      enqueueSnackbar(`${error}`, { variant: "error" })
    }
  });

  useEffect(() => {
    getAllTrainingNames({
      variables: {
        course_id: history.location.state?.data.courseId
      }
    });
    !history.location.state?.data.centreId && getAllCampusNames({
      variables: {
        region_id: history.location.state?.data.regionId
      }
    });
    getGrades();
    getCourseCurricula({
      variables: {
        course_id: history.location.state?.data.courseId
      }
    })
    if (class_id) getAllocatedStudentsByClass({ variables: { class_id: Number(class_id) }})
  }, [class_id]);

  const clearSearchData = () => {
    setUnselectedStudents([]);
    setStudentID("");
    setStudentName("");
    setTrainingModule("");
    setCampus("");
    setGrade("");
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getStudentsByQuery({ variables: {
      context: "Class",
      ...(studentName ? { student_name: studentName } : {}),
      ...(studentID ? { student_id: studentID } : {}),
      ...(trainingModule ? { training_module: Number(trainingModule.id) } : {}),
      ...(grade ? { grade_id: Number(grade.id) } : {}),
      ...(campus ? { centre_id: Number(campus.id) } : {}),
      ...(curriculum ? { curriculum_id: Number(curriculum.id) } : {}),
      class_id: Number(class_id)
    }})
  }

  const removeStudent = useCallback(
    (params) => () => 
      {
        setDeleteStudentID(params.id)
        setDeleteDialog(true)
      },
    []
  )

  const handleDeallocateStudent = () => {
    deallocateStudent({ variables: { class_id: Number(class_id), student_id: Number(deleteStudentID) }})
  }

  const handleAddStudentsToClass = () => {
    allocateStudentToClass({ variables: { class_id: Number(class_id), student_ids: selectionModel }})
  }

  const unselectedStudentColumns = [
    {
      field: "login",
      headerName: "Student Number",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Student Name",
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => params.row.givenname + " " + params.row.surname,
    },
    {
      field: "campus",
      headerName: "Campus",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "grade",
      headerName: "Grade",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "curriculum",
      headerName: "Assigned Curriculum",
      flex: 5,
      headerAlign: "center",
      align: "center",
    },
  ]

  const selectedStudentColumns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true,
        headerName: "Actions",
        flex: 1,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Remove"
            color="primary"
            onClick={removeStudent(params)}
          />,
        ],
      },
      {
        field: "login",
        headerName: "Student Number",
        flex: 1.5,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "name",
        headerName: "Student Name",
        flex: 2,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => params.row.givenname + " " + params.row.surname,
      },
      {
        field: "campus",
        headerName: "Campus",
        flex: 1.5,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "grade",
        headerName: "Grade",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "curriculum",
        headerName: "Assigned Curriculum",
        flex: 5,
        headerAlign: "center",
        align: "center",
      },
    ],
    [removeStudent]
  )
  
  return (
      <Paper elevation={0} sx={{ p: 1 }} >
        <Dialog open={deleteDialog}>
          <DialogTitle>Deselect Student</DialogTitle>
          <DialogContent>
            Are you sure you want to deselect this student?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
            <Button onClick={handleDeallocateStudent}>Confirm</Button>
          </DialogActions>
        </Dialog>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSearchSubmit}
          sx={{ mb: 3 }}
        >
          <Grid container spacing={4}>
            <Grid item xs={2}>
              <TextField
                fullWidth
                id="studentName"
                label="Student Name"
                value={studentName}
                size="small"
                onChange={e => setStudentName(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                id="studentID"
                type="number"
                label="Student ID"
                value={studentID}
                size="small"
                onChange={e => setStudentID(e.target.value)}
              />
            </Grid>
            <Grid item xs={2.5}>
              { grades && (
                <Autocomplete
                  name="grade"
                  value={grade}
                  options={grades}
                  onChange={(e, options) => setGrade(options)}
                  getOptionLabel={(option) => 
                    option.name ? option.name : ""
                  }
                  renderOption={(props, option) => 
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Select Grade"
                      placeholder="Select Grade"
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={2.5}>
              { (campuses && !history.location.state?.data.centreId) && (
                <Autocomplete
                  name="campuses"
                  value={campus}
                  options={campuses}
                  onChange={(e, options) => setCampus(options)}
                  getOptionLabel={(option) => 
                    option.name ? option.name : ""
                  }
                  renderOption={(props, option) => 
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Select Campus"
                      placeholder="Select Campus"
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={4.5}>
              <Autocomplete
                name="curriculum"
                value={curriculum}
                options={curricula}
                onChange={(e, options) => setCurriculum(options)}
                getOptionLabel={(option) => 
                  option.id ? `${option.Course.name} ${option.Grade.name}`: ""
                }
                renderOption={(props, option) => 
                  <li {...props} key={option.id}>
                    {option.id ? `${option.Course.name} ${option.Grade.name}`: ""}
                  </li>
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Select Course Curriculum"
                    placeholder="Select Course Curriculum"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<SearchIcon />}
                type="submit"
              >
                Search Student(s)
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ mb: 3 }} />
        <Grid container sx={{ background: "#F8F8F8", border: "1px solid lightgray", p: 2, borderRadius: 1.2 }}>
          <Grid item xs={5.9}>
            <Typography color="primary" sx={{ mb: 2, fontWeight: 500 }}>
              Unselected Students
            </Typography>
            <DataGridPro
              rows={unselectedStudents}
              columns={unselectedStudentColumns}
              // componentsProps={{
              //   toolbar: {
              //     showQuickFilter: true,
              //     quickFilterProps: { debounceMs: 500 },
              //   }
              // }}
              rowsPerPageOptions={[10, 25, 50, 100]}
              disableSelectionOnClick
              checkboxSelection={true}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              autoHeight
              loading={getStudentsByQueryLoading}
              pagination={true}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
              density="compact"
            />
          </Grid>
          <Grid item xs={0.2}></Grid>
          <Grid item xs={5.9}>
            <Typography color="primary" sx={{ mb: 2, fontWeight: 500 }}>
              Selected Students
            </Typography>
            <DataGridPro
              rows={selectedStudents}
              columns={selectedStudentColumns}
              density="compact"
              // componentsProps={{
              //   toolbar: {
              //     showQuickFilter: true,
              //     quickFilterProps: { debounceMs: 500 },
              //   }
              // }}
              rowsPerPageOptions={[10, 25, 50, 100]}
              disableSelectionOnClick
              autoHeight
              // loading={getStudentsByQueryLoading}
              pagination={true}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
            />
          </Grid>
          <Grid item xs={2} sx={{ mt: 2 }}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={selectionModel && selectionModel?.length === 0}
              onClick={handleAddStudentsToClass}
              startIcon={<AddCircleIcon />}
            >
              Add Student(s)
            </Button>
          </Grid>
        </Grid>
      </Paper>
  )
}

export default SelectStudent