import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Registrations from "../components/Registrations";
import Sittings from "../components/Sittings";
import ModuleUpdateDetails from "../components/ModuleUpdateDetails";
import PackageUpdateDetails from "../components/PackageUpdateDetails";
import SelectTraining from "../components/SelectTraining";
import CourseStudents from "../components/CourseStudents";
import CourseRegistrations from "../components/CourseRegistrations";
import CourseCurriculum from "../components/CourseCurriculum";
import { AuthenticatedContext } from "../App";

const CourseDetailManagement = () => {
  const { id } = useParams();
  const { userRole } = useContext(AuthenticatedContext);

  return (
    <Box
    sx={{backgroundColor: "white"}}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="course-details-1"
        >
          <Typography>Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PackageUpdateDetails packageId={Number(id)} packageType="Course" />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="training-list-1"
        >
          <Typography>Training List</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SelectTraining course_id={Number(id)} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="program-registration"
        >
          <Typography>Course Curriculum</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CourseCurriculum course_id={Number(id)} />
        </AccordionDetails>
      </Accordion>
      {
        ["Admin", "Education Consultant"].includes(userRole) && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              id="program-registration"
            >
              <Typography>Student Registration</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CourseRegistrations course_id={Number(id)} />
            </AccordionDetails>
          </Accordion>
        )
      }
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="student-list-1"
        >
          <Typography>Student List</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CourseStudents course_id={Number(id)} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CourseDetailManagement;
