import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { format } from "date-fns";
import Instructions from "./Instructions";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import jwtDecode from "jwt-decode";
import { AuthenticatedContext } from "../App";
import * as yup from "yup";
import { GET_MY_STAFF_DETAILS } from "../graphql/queries";
import { UPDATE_STAFF } from "../graphql/mutations";

const StaffUpdateDetails = () => {
  const value = React.useContext(AuthenticatedContext);
  const schema = yup
  .object({
    staffGivenname: yup.string().max(45).required("Required"),
    staffSurname: yup.string().max(45).required("Required"),
  })
  .required();
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      staffGivenname: "",
      staffSurname: "",
    },
  });
  
  const history = useHistory();
  const location = useLocation();
  const [staffDetails, setStaffDetails] = useState({});

  const [getMyStaffDetails] = useLazyQuery(GET_MY_STAFF_DETAILS, {
    onCompleted: (returnStaffDetails) => {
      setStaffDetails(returnStaffDetails.getMyStaffDetails);
      setValue("staffGivenname", returnStaffDetails.getMyStaffDetails.staffGivenname);
      setValue("staffSurname", returnStaffDetails.getMyStaffDetails.staffSurname);
    },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    getMyStaffDetails()
  }, []);


  return (
    <>
      <Grid
        item
        xs={6}
      >
        <Controller
          name="staffGivenname"
          control={control}
          render={({ field }) => (
            <FormControl
              variant="outlined"
              fullWidth
              required
              error={errors.staffGivenname ? true : false}
            >
              <InputLabel>Staff Given Name</InputLabel>
              <OutlinedInput {...field} label="Staff Given Name" />
              <FormHelperText sx={{ color: "primary.main" }}>
                {errors.staffGivenname?.message}
              </FormHelperText>
            </FormControl>
          )}>
        </Controller>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Controller
          name="staffSurname"
          control={control}
          render={({ field }) => (
            <FormControl
              variant="outlined"
              fullWidth
              required
              error={errors.staffSurname ? true : false}
            >
              <InputLabel>Staff Surname</InputLabel>
              <OutlinedInput {...field} label="Staff Surname" />
              <FormHelperText sx={{ color: "primary.main" }}>
                {errors.staffSurname?.message}
              </FormHelperText>
            </FormControl>
          )}>
        </Controller>
      </Grid>
    </>
  );
};

export default StaffUpdateDetails;
