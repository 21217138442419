import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ReportRegistrations from "../components/ReportRegistrations";
import ReportStudents from "../components/ReportStudents";

const ReportDetailManagement = () => {
  const { id } = useParams();

  return (
    <Box
    sx={{backgroundColor: "white"}}>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="report-details-1"
        >
          <Typography>Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="report-tests-1"
        >
          <Typography>Tests</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReportRegistrations report_id={Number(id)} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="student-modules-1"
        >
          <Typography>Student Reports</Typography>
        </AccordionSummary>
        <AccordionDetails>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ReportDetailManagement;
