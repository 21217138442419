import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { gql, useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import { Box } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CREATE_STAFF } from "../graphql/mutations";

const schema = yup.object({
  givenname: yup.string().max(45).required("Required"),
  surname: yup.string().max(45).required("Required"),
  email: yup.string().max(500).required("Required"),
  roleId: yup.number().typeError("Required").required("Required"),
  regionId: yup.number(),
});

const AddUser = ({ handleClose, open, roles, regions, getStaffs }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createStaff] = useMutation(CREATE_STAFF, {
    onCompleted: ({ createStaff }) => {
      getStaffs();
      enqueueSnackbar(
        `New user: ${createStaff} has been successfully created`,
        {
          variant: "success",
        }
      );
      handleClose();
    },
    onError: (error) => {
      enqueueSnackbar(`Error occured: ${error}`, {
        variant: "error",
      });
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      givenname: "",
      surname: "",
      email: "",
      roleId: "",
      regionId: null,
    },
  });

  const onSubmit = ({
    givenname,
    surname,
    email,
    roleId,
    regionId
  }) => {
    createStaff({
      variables: {
        givenname,
        surname,
        email,
        roleId,
        regionId
      },
    });
    reset({
      givenname: "",
      surname: "",
      email: "",
      roleId: "",
      regionId: null,
    });
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ pt: 1, pb: 1 }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="givenname"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    required
                    error={errors.givenname ? true : false}
                  >
                    <InputLabel>Given Name</InputLabel>
                    <OutlinedInput {...field} label="Given Name" />
                    <FormHelperText sx={{ color: "primary.main" }}>
                      {errors.givenname?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="surname"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    required
                    error={errors.surname ? true : false}
                  >
                    <InputLabel>Surname</InputLabel>
                    <OutlinedInput {...field} label="Surname" />
                    <FormHelperText sx={{ color: "primary.main" }}>
                      {errors.surname?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    required
                    error={errors.email ? true : false}
                  >
                    <InputLabel>Email</InputLabel>
                    <OutlinedInput {...field} label="Email" />
                    <FormHelperText sx={{ color: "primary.main" }}>
                      {errors.email?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="roleId"
                control={control}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    required
                    error={errors.roleId ? true : false}
                  >
                    <InputLabel>Role</InputLabel>
                    <Select label="Role" {...field} defaultValue="">
                      {roles?.map(({ roleId, name }) => (
                        <MenuItem value={roleId} key={roleId}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: "primary.main" }}>
                      {errors.roleId?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="regionId"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth error={errors.regionId ? true : false}>
                    <InputLabel>Region</InputLabel>
                    <Select label="Region" {...field} defaultValue="">
                      {regions?.map(({ id, name }) => (
                        <MenuItem value={id} key={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  //disabled={}
                  sx={{ mr: 2 }}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  //disabled={}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddUser;
