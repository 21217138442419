import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import {
  DataGridPro,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { GET_ALL_TRAININGS } from "../graphql/queries";
import { DELETE_TRAINING, REGISTER_MOCK_STUDENTS } from "../graphql/mutations";
import { AuthenticatedContext, PreferenceContext } from "../App";

const TrainingManagement = () => {
  const { preferences, setPreferences } = useContext(PreferenceContext);
  const { userRole } = useContext(AuthenticatedContext);
  let trainingPref = { ...preferences.trainingMgmt };
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(trainingPref.pageSize || 10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogParams, setDeleteDialogParams] = useState({});
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);

  const [getAllTrainings, { testsLoading }] = useLazyQuery(GET_ALL_TRAININGS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAllTrainings }) => {
      console.log(getAllTrainings);
      if (getAllTrainings?.trainings.length > 0) {
        setRows(getAllTrainings.trainings);
      }
      setRowCount(getAllTrainings.total);
    },
  });

  const [deleteTraining] = useMutation(DELETE_TRAINING, {
    onError: (error) => {
      console.log(error);
      setDeleteDialogOpen(false);
    },
    onCompleted: ({ deleteTraining }) => {
      if (deleteTraining === true) {
        setRows(rows.filter((row) => row.id !== deleteDialogParams.id));
        enqueueSnackbar(
          `Test(id: ${deleteDialogParams.id}) has been successfully deleted`,
          {
            variant: "success",
          }
        );
      } else if (deleteTraining === false) {
        enqueueSnackbar("Test can not be found", {
          variant: "error",
        });
      }
      setDeleteDialogOpen(false);
    },
    fetchPolicy: "network-only",
  });

  const [registerMockStudents] = useMutation(REGISTER_MOCK_STUDENTS, {
    onError: (error) => {
      console.log(error);
    },
    onCompleted: ({ registerMockStudents }) => {
      if (registerMockStudents > 0) {
        enqueueSnackbar(
          `${registerMockStudents} test students were successfully registered`,
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar("No new test students were registered", {
          variant: "warning",
        });
      }
    },
  });

  useEffect(() => {
    getAllTrainings({
      variables: {
        skip: page * pageSize,
        take: pageSize,
        filters: trainingPref.filters,
        sort: trainingPref.sort,
      },
    });
  }, [page]);

  const handleDeleteTraining = (params) => {
    deleteTraining({
      variables: { id: params.id },
    });
  };

  const deleteTestFunction = useCallback((params) => () => {
    setDeleteDialogOpen(true);
    setDeleteDialogParams(params.row);
  });

  const registerTest = useCallback(
    (params) => () =>
      registerMockStudents({
        variables: {
          id: params.row.id,
          module_type_id: 4,
        },
      }),
    []
  );

  const viewTest = useCallback(
    (params) => () =>
      history.push(
        `/trainingmanagement/${params.row.id}`,
        { data: params.row }
      ),
    []
  );

  const handlePageSizeChange = (newPageSize) => {
    trainingPref.pageSize = newPageSize;
    setPageSize(newPageSize);
    setPreferences({
      ...preferences,
      trainingMgmt: {
        ...trainingPref,
      },
    });
    if (page === 0) {
      getAllTrainings({
        variables: {
          skip: page * newPageSize,
          take: newPageSize,
          filters: trainingPref.filters,
          sort: trainingPref.sort,
        },
      });
    } else {
      setPage(0);
    }
  };

  const handleColumnWidthChange = (params) => {
    trainingPref.widths[params.colDef.field] = params.width;
    setPreferences({
      ...preferences,
      trainingMgmt: {
        ...trainingPref,
      },
    });
  };

  const handleColumnOrderChange = (params) => {
    trainingPref.columns.splice(params.oldIndex, 1);
    trainingPref.columns.splice(params.targetIndex, 0, params.field);
    setPreferences({
      ...preferences,
      trainingMgmt: {
        ...trainingPref,
      },
    });
  };

  const handleColumnVisibilityModelChange = (model) => {
    trainingPref.visible = { ...model };
    setPreferences({
      ...preferences,
      trainingMgmt: {
        ...trainingPref,
      },
    });
  };

  const handleSortModelChange = (model) => {
    trainingPref.sort = [...model];

    setPreferences({
      ...preferences,
      trainingMgmt: {
        ...trainingPref,
      },
    });
    if (page === 0) {
      getAllTrainings({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filters: trainingPref.filters,
          sort: [...model],
        },
      });
    } else {
      setPage(0);
    }
  };

  const handleFilterModelChange = (model) => {
    trainingPref.filters = { ...model };

    setPreferences({
      ...preferences,
      trainingMgmt: {
        ...trainingPref,
      },
    });
    if (page === 0) {
      getAllTrainings({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filters: { ...model },
          sort: trainingPref.sort,
        },
      });
    } else {
      setPage(0);
    }
  };

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        width: trainingPref?.widths?.actions,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => {
          let actions = [
            <GridActionsCellItem
              icon={
                <Tooltip title="View Training">
                  <VisibilityIcon />
                </Tooltip>
              }
              label="View"
              color="primary"
              onClick={viewTest(params)}
            />
          ];
          if (["Admin", "Education Consultant"].includes(userRole)) {
            actions.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Delete Training">
                    <DeleteIcon />
                  </Tooltip>
                }
                label="Delete"
                color="primary"
                onClick={deleteTestFunction(params)}
              />
            );
          }
          return actions;
        }
      },
      {
        field: "id",
        headerName: "Training ID",
        width: trainingPref?.widths?.id,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
        hide: !trainingPref?.visible?.id || !["Admin", "Education Consultant"].includes(userRole),
        type: "number",
      },
      {
        field: "name",
        headerName: "Training Name",
        width: trainingPref?.widths?.name,
        headerAlign: "center",
        align: "center",
        hide: !trainingPref?.visible?.name,
        getApplyQuickFilterFn: getApplyFilterFnName,
      },
      {
        field: "numEnrolled",
        headerName: "# Enrolled",
        width: trainingPref?.widths?.numEnrolled,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
        hide: !trainingPref?.visible?.numEnrolled,
        type: "number"
      },
      {
        field: "numActive",
        headerName: "# Active",
        width: trainingPref?.widths?.numActive,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
        hide: !trainingPref?.visible?.numActive,
        type: "number"
      },
      {
        field: "assessmentId",
        headerName: "Assessment ID",
        width: trainingPref?.widths?.assessmentId,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
        hide: !trainingPref?.visible?.assessmentId || !["Admin", "Education Consultant"].includes(userRole),
        type: "number"
      },
      {
        field: "totalQuestions",
        headerName: "Total Questions",
        width: trainingPref?.widths?.totalQuestions,
        headerAlign: "center",
        align: "center",
        hide: !trainingPref?.visible?.totalQuestions,
        getApplyQuickFilterFn: undefined,
        type: "number"
      },
      {
        field: "firstGroupQuestions",
        headerName: "First Group Questions",
        width: trainingPref?.widths?.firstGroupQuestions,
        headerAlign: "center",
        align: "center",
        hide: !trainingPref?.visible?.firstGroupQuestions,
        getApplyQuickFilterFn: undefined,
        type: "number"
      },
      {
        field: "timeOfFirstGroupQuestions",
        headerName: "Time Of First Group Questions",
        width: trainingPref?.widths?.timeOfFirstGroupQuestions,
        headerAlign: "center",
        align: "center",
        hide: !trainingPref?.visible?.timeOfFirstGroupQuestions,
        getApplyQuickFilterFn: undefined,
        type: "number"
      },
      {
        field: "totalCorrectScoreSectionOne",
        headerName: "Total Correct Score Section One",
        width: trainingPref?.widths?.totalCorrectScoreSectionOne,
        headerAlign: "center",
        align: "center",
        hide: !trainingPref?.visible?.totalCorrectScoreSectionOne,
        getApplyQuickFilterFn: undefined,
        type: "number"
      },
      // {
      //   field: "totalTimeSectionOne",
      //   headerName: "Total Time Section One",
      //   width: trainingPref?.widths?.totalTimeSectionOne,
      //   headerAlign: "center",
      //   align: "center",
      //   hide: !trainingPref?.visible?.totalTimeSectionOne,
      //   getApplyQuickFilterFn: undefined,
      // },
      {
        field: "totalCorrectScoreSectionTwo",
        headerName: "Total Correct Score Section Two",
        width: trainingPref?.widths?.totalCorrectScoreSectionTwo,
        headerAlign: "center",
        align: "center",
        hide: !trainingPref?.visible?.totalCorrectScoreSectionTwo,
        getApplyQuickFilterFn: undefined,
        type: "number"
      },
      // {
      //   field: "totalTimeSectionTwo",
      //   headerName: "Total Time Section Two",
      //   width: trainingPref?.widths?.totalTimeSectionTwo,
      //   headerAlign: "center",
      //   align: "center",
      //   hide: !trainingPref?.visible?.totalTimeSectionTwo,
      //   getApplyQuickFilterFn: undefined,
      // },
      {
        field: "isActive",
        headerName: "Active?",
        width: trainingPref?.widths?.isActive,
        headerAlign: "center",
        align: "center",
        hide: !trainingPref?.visible?.isActive,
        getApplyQuickFilterFn: undefined,
        type: "boolean"
      },
      {
        field: "createdAt",
        headerName: "Created",
        width: trainingPref?.widths?.createdAt,
        headerAlign: "center",
        type: "dateTime",
        hide: !trainingPref?.visible?.createdAt,
        getApplyQuickFilterFn: undefined,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
        filterable: false,
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: trainingPref?.widths?.updatedAt,
        headerAlign: "center",
        type: "dateTime",
        hide: !trainingPref?.visible?.updatedAt,
        getApplyQuickFilterFn: undefined,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
        filterable: false,
      },
    ],
    [viewTest, registerTest]
  );

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
            Training Management
          </Typography>
        </Box>
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>
            {`Are you sure you want to delete this training?`}
          </DialogTitle>
          <DialogActions sx={{ mx: 1.6, my: 1 }}>
            <Button
              onClick={() => handleDeleteTraining(deleteDialogParams)}
              color="primary"
              variant="contained"
              startIcon={<CheckCircleIcon />}
              autoFocus
            >
              Confirm
            </Button>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<CancelIcon />}
              onClick={() => setDeleteDialogOpen(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSize={trainingPref.pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          loading={testsLoading}
          onColumnWidthChange={handleColumnWidthChange}
          onColumnOrderChange={handleColumnOrderChange}
          pagination
          pinnedColumns={{ left: ["actions"] }}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          filterModel={trainingPref.filters}
          sortModel={trainingPref.sort}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          rowCount={rowCount}
          density="compact"
        />
      </Paper>
    </Container>
  );
};

export default TrainingManagement;
