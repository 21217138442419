import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { format } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import jwtDecode from "jwt-decode";
import { AuthenticatedContext } from "../../App";
import * as yup from "yup";
import { GET_MY_STAFF_DETAILS } from "../../graphql/queries";
import { UPDATE_STAFF } from "../../graphql/mutations";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";

const RadarGraph = ({ type, data }) => {
  return (
    <RadarChart outerRadius={150} data={data} width={920} height={438}>
      <PolarGrid />
      <PolarAngleAxis dataKey="subject" />
      <PolarRadiusAxis domain={[0, 15]} tickCount={4} tickFormatter={() => ""} angle={30}/>
      <Radar
        name="Your Score"
        dataKey="A"
        stroke="#8884d8"
        fill="#8884d8"
        fillOpacity={0.6}
      />
      {/* { type === "top" && (
        <Radar
          name="Top score of students your age"
          dataKey="Top"
          stroke="#82ca9d"
          fill="#82ca9d"
          fillOpacity={0.6}
        />
      )}
      { type === "average" && (
        <Radar
          name="Average score of students your age"
          dataKey="B"
          stroke="#82ca9d"
          fill="#82ca9d"
          fillOpacity={0.6}
        />
      )} */}
      <Legend />
    </RadarChart>
  )
};

export default RadarGraph;
