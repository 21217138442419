import {
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { GET_DASHBOARD, GET_RAISE_HAND } from "../graphql/queries";
import { TOGGLE_RAISE_HAND } from "../graphql/mutations";
import { AuthenticatedContext, ModuleContext, PreferenceContext } from "../App";
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded';
import TrainingReport from "../components/TrainingReport";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import InteractiveReport from "../components/InteractiveReport";

const StudentTraining = () => {
  const { userRole, user, userToken } = useContext(AuthenticatedContext);
  const { createSitting, moduleSettings, setModuleSettings, exitSitting, deleteSitting, deleteTrainingAnswers, updateSitting, finishSitting } = useContext(ModuleContext);
  const { preferences } = useContext(PreferenceContext);
  const history = useHistory();
  const location = useLocation();
  let trainPref = { ...preferences.studentTraining };
  const [rows, setRows] = useState([]);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [raiseHandchecked, setRaiseHandChecked] = useState(false);
  const handleRaiseHandChange = () => toggleRaiseHand({ variables: { student_id: JSON.parse(user).student_id }})
  const [pageSize, setPageSize] = useState(10);
  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);
  const [selectedRow, setSelectedRow] = useState(null);
  const tawkMessengerRef = useRef();

  const onLoad = () => {
    const student = JSON.parse(sessionStorage.getItem("user"));
    tawkMessengerRef?.current?.setAttributes({
        name: student?.student_givenname + " " + student?.student_surname
    }, function(error) {
        console.error(error);
    });
};

  const [getDashboard, { data: getDashboardData, loading: dashboardLoading }] = useLazyQuery(GET_DASHBOARD, {
    onCompleted: ({ getDashboard }) => {
      console.log(getDashboard);
      let rows = []
      if (getDashboard.trainings) {
        for (const i of getDashboard.trainings) {
          if (i.TrainingSittings.length > 0) {
            for (const j of i.TrainingSittings) {  
              let showReport = false;
              let totalQuestionsAssessment = 0;
              if (j.isCompleted) {
                const findAssessment = getDashboard.assessments.find(a => i.assessmentId === a.id);
                if (findAssessment && findAssessment.Sittings.length > 0) {
                  if (findAssessment.Sittings[0].isCompleted) {
                    showReport = true;
                    totalQuestionsAssessment = findAssessment.totalQuestions;
                  }
                }
              }
              const row = {
                id: Math.random().toString(16).slice(2),
                originId: i.id,
                sittingId: j.id,
                name: i.name,
                strand: "",
                topic: "",
                subTopic: "",
                ...i.isInteractive ? { type: "Activity" } : { type: "Training" },
                status: j.isCompleted ? "Completed" : "In Progress",
                isCompleted: j.isCompleted,
                ...i.isInteractive ? { progress: `${j.upTo}/${i.totalQuestions}` } : { progress: `${j.upTo}/${i.totalQuestions + i.totalQuestions - i.firstGroupQuestions}` },
                completionDate: j.completedDate,
                firstGroupQuestions: i.firstGroupQuestions,
                totalQuestions: i.totalQuestions,
                hidden: i.order || 0,
                showReport,
                totalQuestionsAssessment,
                isInteractive: i.isInteractive,
                ...i.isInteractive || !i.assessmentId ? { dueDate: i.dueDate } : {},
                assessmentId: i.assessmentId
              }
              rows = [...rows, row]
            }
          } else {
            const row = {
              id: Math.random().toString(16).slice(2),
              originId: i.id,
              name: i.name,
              strand: "",
              topic: "",
              subTopic: "",
              ...i.isInteractive ? { type: "Activity" } : { type: "Training" },
              status: "Not Started",
              hidden: i.order || 0,
              showReport: false,
              isInteractive: i.isInteractive,
              ...i.isInteractive || !i.assessmentId ? { dueDate: i.dueDate } : {},
              assessmentId: i.assessmentId
              // progress: `${j.upTo}/${i.totalQuestions}`,
              // completionDate: j.completedDate,
            }
            rows = [...rows, row]
          }
        }
      }
      if (getDashboard.assessments) {
        for (const i of getDashboard.assessments) {
          // console.log(i);
          if (i.Sittings.length > 0) {
            for (const j of i.Sittings) {  
              const findTraining = getDashboard.trainings.find(t => t.assessmentId === i.id);

              const row = {
                id: Math.random().toString(16).slice(2),
                originId: i.id,
                sittingId: j.id,
                name: i.name,
                strand: "",
                topic: "",
                subTopic: "",
                type: "Assessment",
                status: j.isCompleted ? "Completed" : "In Progress",
                progress: `${j.upTo}/${i.totalQuestions}`,
                completionDate: j.completedDate,
                dueDate: i.dueDate,
                hidden: i.order || 0,
                trainingId: findTraining.id,
                firstGroupQuestions: findTraining.firstGroupQuestions,
                totalQuestions: findTraining.totalQuestions,
                assessmentQuestions: i.totalQuestions,
                isCompleted: j.isCompleted
              }
              rows = [...rows, row]
            }
          } else {
            const findTraining = getDashboard.trainings.find(t => t.assessmentId === i.id)

            let canStartAssessment = false

            if (findTraining && findTraining.TrainingSittings?.length > 0) {
              if (findTraining?.TrainingSittings[0].isCompleted === true || findTraining?.TrainingSittings[0].isRedo === true) {
                canStartAssessment = true
              }
            }

            const row = {
              id: Math.random().toString(16).slice(2),
              originId: i.id,
              name: i.name,
              strand: "",
              topic: "",
              subTopic: "",
              type: "Assessment",
              status: canStartAssessment ? "Not Started" : "Locked",
              dueDate: i.dueDate,
              hidden: i.order || 0,
              // progress: `${j.upTo}/${i.totalQuestions}`,
              // completionDate: j.completedDate,
            }
            rows = [...rows, row]
          }
        }
      }
      function compare(a, b) {
        if (a.hidden === b.hidden) {
          if (a.type === "Activity" && (b.type === "Training" || b.type === "Assessment")) {
            return 1;
          } else if (b.type === "Activity" && (a.type === "Training" || a.type === "Assessment")) {
            return -1;
          }
          if (a.name === b.name) {
            console.log(a.name);
            if (a.type === "Training") {
              return -1;
            } else {
              return 1;
            }
          } else if (a.name < b.name) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return a.hidden - b.hidden;
        }
      }
      setRows(rows.sort(compare))
    },
    onError: (error) => console.log(`getDashboard Error ${error}`),
    fetchPolicy: "no-cache",
  });

  const [ getRaiseHand, { loading: getRaiseHandLoading } ] = useLazyQuery(GET_RAISE_HAND, {
    onCompleted: ({ getRaiseHand }) => {
      if (getRaiseHand === 1) return setRaiseHandChecked(true)
        setRaiseHandChecked(false)
    },
    onError: (error) => console.log(`getRaiseHand Error ${error}`),
    fetchPolicy: "no-cache",
  })

  const [ toggleRaiseHand, { loading: toggleRaiseHandLoading } ] = useMutation(TOGGLE_RAISE_HAND, {
    onCompleted: () => setRaiseHandChecked(!raiseHandchecked),
    onError: (error) => {
      console.log(`toggleRaiseHand Error ${error}`);
    },
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    userToken && user && userRole && getDashboard({
      variables: {
        user_id: JSON.parse(user).student_id,
        role: userRole,
      },
    }) && getRaiseHand({ variables: { student_id: JSON.parse(user).student_id }})
  }, []);

  const handleActionClick = async (params) => {

    let findModule = (params.row.type === "Training" || params.row.type === "Activity") ? 
      getDashboardData.getDashboard.trainings.find(t => t.id === params.row.originId) :
      getDashboardData.getDashboard.assessments.find(t => t.id === params.row.originId)

    if(params.row.type === "Assessment") {
      const findTraining = getDashboardData.getDashboard.trainings.find(t => t.assessmentId === params.row.originId)
      if (findTraining) {
        findModule = { ...findModule, Medias: [...findTraining.Medias] }
      }
    }

    if(findModule) {
      if (params.row.type === "Training" || params.row.type === "Activity") {
        setModuleSettings({
          ...moduleSettings,
          selectedModule: findModule,
          ...params.row.isInteractive ? { moduleType: "Interactive" } : { moduleType: "Training" },
        });

        if (params.row.status === "Not Started") {
          history.push({
            pathname: "/instructions",
            from: location?.pathname,
          })
        } else if (params.row.status === "In Progress" && params.row.type !== "Activity") {
          history.push("/training-question");
        } else if (params.row.status === "In Progress" && params.row.type === "Activity") {
          await finishSitting(findModule, "Interactive");
          const result = await deleteTrainingAnswers({
            variables: {
              sitting_id: params.row.sittingId,
              current_section: [1]
            },
          });

          if (result && result?.data?.deleteTrainingAnswers) {
            await updateSitting({
              variables: {
                module_type: params.row.type,
                sitting_id: params.row.sittingId,
                up_to: "1",
                current_section: 1,
                part_one_all_correct: false,
                is_redo: true
              },
            });
            history.push({
              pathname: "/training-question",
              from: location?.pathname,
            })
          }
        } else if (params.row.status === "Completed") {
          const result = await deleteTrainingAnswers({
            variables: {
              sitting_id: params.row.sittingId,
              ...params.row.isInteractive || (params.row.firstGroupQuestions === params.row.totalQuestions) ? { current_section: [1] } : { current_section: [2, 3] },
            },
          });

          if (result && result?.data?.deleteTrainingAnswers) {
            await updateSitting({
              variables: {
                module_type: params.row.type,
                sitting_id: params.row.sittingId,
                ...params.row.isInteractive || (params.row.firstGroupQuestions === params.row.totalQuestions) ? { up_to: "1" } : { up_to: (params.row.firstGroupQuestions + 1).toString() },
                ...params.row.isInteractive || (params.row.firstGroupQuestions === params.row.totalQuestions) ? { current_section: 1 } : { current_section: 2 },
                part_one_all_correct: false,
                is_redo: true
              },
            });
            history.push({
              pathname: "/training-question",
              from: location?.pathname,
            })
          }
        }
      } else {
        if (params.row.status === "Completed") {
          const findTrainingID = getDashboardData.getDashboard.trainings.find(t => t.assessmentId === findModule.id).id
          await deleteSitting({ variables: { module_id: findTrainingID, module_type: "Assessment", student_id: sessionStorage.getItem("user") && JSON.parse(sessionStorage.getItem("user")).student_id }});
          exitSitting()
        }

        setModuleSettings({
          ...moduleSettings,
          selectedModule: findModule,
          moduleType: "Assessment",
        });

        if (params.row.status === "Not Started" || params.row.status === "Completed" ) await createSitting(findModule.id, "Assessment");
        history.push("/assessment-question");
      }
    }
  }

  const handleReview = params => {
    let findModule = params.row.type === "Training" ? 
      getDashboardData.getDashboard.trainings.find(t => t.id === params.row.originId) :
      getDashboardData.getDashboard.assessments.find(t => t.id === params.row.originId)

    if (params.row.type === "Assessment") {
      const findTraining = getDashboardData.getDashboard.trainings.find(t => t.assessmentId === params.row.originId);
      if (findTraining) {
        findModule = { ...findModule, Medias: [...findTraining.Medias] }
      }
      if(findModule) {
        setModuleSettings({
          ...moduleSettings,
          selectedModule: findModule,
          moduleType: "Assessment",
        });
  
        history.push("/assessment-question");
      }
    } else {
      const findTraining = getDashboardData.getDashboard.trainings.find(t => t.id === params.row.originId);
      if (findTraining) {
        findModule = { ...findModule, Medias: [...findTraining.Medias] }
      }
      if (findModule) {
        setModuleSettings({
          ...moduleSettings,
          selectedModule: findModule,
          moduleType: "Training",
        });
  
        history.push("/training-question");
      }
    }

    
  }

  const getButtonStatus = (row) => {
    if(row.status === "Not Started" || row.status === "Locked") {
      return "start"
    } else if (row.status === "In Progress" && !row.isInteractive) {
      return "resume"
    } else if (row.status === "Completed" || row.isInteractive) {
      return "restart"
    } else {
      return ""
    }
  }

  const getButtonColor = (row) => {
    if(row.status === "Not Started" || row.status === "Locked") {
      return "error"
    } else if (row.status === "In Progress" && !row.isInteractive) {
      return "success"
    } else if (row.status === "Completed" || row.isInteractive) {
      return "error"
    } else {
      return ""
    }
  }

  const getButtonIcon = (row) => {
    if((row.status !== "Completed" && !row.isInteractive) || row.status === "Not Started") {
      return <PlayArrowIcon />
    } else if (row.status === "Completed" || row.isInteractive) {
      return <RestartAltIcon />
    } else {
      return "";
    }
  }

  const viewReport = (params) => {
    console.log(params.row);
    setSelectedRow(params.row);
    setResultDialogOpen(true);
  }

  const columns = useMemo(
    () => [
      {
        field: "actions",
        disableClickEventBubbling: true,
        headerName: "Actions",
        width: trainPref?.widths?.actions,
        headerAlign: "center",
        align: "left",
        disableReorder: true,
        hideable: false,
        renderCell: (params) => (
          <Box>
            <Button
              variant="contained"
              size="small"
              color={getButtonColor(params.row)}
              onClick={() => handleActionClick(params)}
              tabIndex={params.hasFocus ? 0 : -1}
              disabled={params.row.status === "Locked"}
              startIcon={getButtonIcon(params.row)}
              sx={{ width: "100px", textTransform: "capitalize" }}
            >
              {getButtonStatus(params.row)}
            </Button>
            {(params.row.type === "Assessment") || (params.row.type === "Training" && !params.row.assessmentId) ? <Button
              variant="contained"
              size="small"
              color="warning"
              onClick={() => handleReview(params)}
              tabIndex={params.hasFocus ? 0 : -1}
              disabled={params.row.status !== "Completed"}
              startIcon={<VisibilityIcon />}
              sx={{ marginLeft: 1.6, width: "100px", textTransform: "capitalize" }}
            >
              Review
            </Button> : <Button variant="contained" sx={{ marginLeft: 1.6, width: "100px", visibility: "hidden" }}>Hidden</Button>}
            { (params.row.type === "Assessment" || params.row.type === "Activity" || (params.row.type === "Training" && !params.row.assessmentId)) && (<Button
              variant="contained"
              size="small"
              color="info"
              sx={{ marginLeft: 1.6, width: "100px", textTransform: "capitalize" }}
              onClick={() => viewReport(params)}
              tabIndex={params.hasFocus ? 0 : -1}
              disabled={!params.row.isCompleted}
              startIcon={<AssessmentIcon />}
            >
              {(params.row.type === "Assessment" || params.row.type === "Training") ? "Result" : "Stats"}
            </Button>)}
          </Box>
        ),
      },
      {
        field: "id",
        headerName: "ID",
        width: trainPref?.widths?.id,
        headerAlign: "center",
        align: "center",
        hide: !trainPref?.visible?.id,
      },
      {
        field: "originId",
        headerName: "Origin ID",
        width: trainPref?.widths?.id,
        headerAlign: "center",
        align: "center",
        hide: !trainPref?.visible?.id,
      },
      {
        field: "name",
        headerName: "Training Name",
        width: trainPref?.widths?.name,
        headerAlign: "center",
        align: "center",
        hide: !trainPref?.visible?.name,
      },
      {
        field: "type",
        headerName: "Type",
        width: trainPref?.widths?.type,
        headerAlign: "center",
        align: "center",
        hide: !trainPref?.visible?.type,
        renderCell: ({ value }) => {
          if (value === "Training") {
            return <Chip variant="filled" label="Training" size="small" color="info" sx={{ py: 1.8, px: 0.8, fontWeight: 500 }} />
          } else if (value === "Assessment") {
            return <Chip variant="filled" label="Assessment" size="small" color="warning" sx={{ py: 1.8, px: 0.8, fontWeight: 500 }} />
          } else if (value === "Activity") {
            return <Chip variant="filled" label="Activity" size="small" color="success" sx={{ py: 1.8, px: 0.8, fontWeight: 500 }} />
          }
        },
      },
      {
        field: "status",
        headerName: "Status",
        width: trainPref?.widths?.status,
        headerAlign: "center",
        align: "center",
        hide: !trainPref?.visible?.status,
        renderCell: ({ value }) => {
          if (value === "Completed") {
            return <Chip variant="outlined" label="Completed" size="small" color="success" sx={{ py: 1.8, px: 0.8, fontWeight: 500 }} />
          } else if (value === "In Progress"){
            return <Chip variant="outlined" label="In Progress" size="small" color="info" sx={{ py: 1.8, px: 0.8, fontWeight: 500 }} />
          } else if (value === "Not Started") {
            return <Chip variant="outlined" label="Not Started" size="small" color="warning" sx={{ py: 1.8, px: 0.8, fontWeight: 500 }} />
          } else {
            return <Chip variant="outlined" label="Locked" size="small" color="error" sx={{ py: 1.8, px: 0.8, fontWeight: 500 }} />
          }
        },
      },
      {
        field: "progress",
        headerName: "Progress",
        width: trainPref?.widths?.progress,
        headerAlign: "center",
        align: "center",
        hide: !trainPref?.visible?.progress,
      },
      {
        field: "completionDate",
        headerName: "Completion Date",
        width: trainPref?.widths?.completionDate,
        headerAlign: "center",
        align: "center",
        type: "dateTime",
        hide: !trainPref?.visible?.completionDate,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
      },
      // {
      //   field: "dueDate",
      //   headerName: "Due Date",
      //   width: trainPref?.widths?.dueDate,
      //   headerAlign: "center",
      //   align: "center",
      //   type: "dateTime",
      //   hide: !trainPref?.visible?.dueDate,
      //   valueFormatter: ({ value }) => {
      //     if (value) {
      //       return format(value, "dd/MM/yyyy");
      //     }
      //   },
      // },
    ],
    [handleActionClick, handleReview, setResultDialogOpen]
  );

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
            Training Modules
          </Typography>
          {!getRaiseHandLoading && 
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
              <TawkMessengerReact
                propertyId="652224e7eb150b3fb99f2b02"
                widgetId="1hc6m0aq0"
                onLoad={onLoad}
                ref={tawkMessengerRef}
              />
              <Button
                variant="contained"
                color={"primary"}
                disabled={toggleRaiseHandLoading}
                onClick={handleRaiseHandChange}
                startIcon={<PanToolRoundedIcon sx={{ color: "gold"}} />}
              >
                {raiseHandchecked ? "Lower Hand" : "Raise Hand"}
              </Button>
            </Box>}
        </Box>
        <Dialog fullScreen open={resultDialogOpen}>
          { (selectedRow?.id && selectedRow?.isInteractive) ? <InteractiveReport openFn={setResultDialogOpen} student_id={JSON.parse(user).student_id} training_module={selectedRow?.originId} /> : <TrainingReport openFn={setResultDialogOpen} student_id={JSON.parse(user).student_id} training_module={selectedRow?.trainingId || selectedRow?.originId} name={selectedRow?.name} totalTraining={2 * selectedRow?.totalQuestions - selectedRow?.firstGroupQuestions} totalAssessment={selectedRow?.assessmentQuestions} />}
        </Dialog>
        <DataGridPro
          rows={rows}
          columns={columns}
          // components={{ Toolbar: GridToolbar }}
          // componentsProps={{
          //   toolbar: {
          //     showQuickFilter: true
          //   }
          // }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          loading={dashboardLoading || getRaiseHandLoading}
          pagination={true}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          pinnedColumns={{left: ["actions"]}}
        />
      </Paper>
    </Container>
  );
};

export default StudentTraining;