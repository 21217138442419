import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tabs,
  Tab,
  Box,
  Typography,
  Stack,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Divider,
  IconButton
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { DataGridPro, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { AuthenticatedContext, PreferenceContext } from "../App";
import { useSnackbar } from "notistack";
import { GET_ALLOCATED_TRAININGS_BY_COURSE, GET_CENTRES, GET_COURSE_CURRICULA, GET_COURSE_REGISTRATIONS, GET_CURRICULUM_TRAININGS, GET_EASY_COURSES, GET_EASY_TERMS, GET_GRADES, GET_NSDC_GRADES, GET_PROGRAM_REGISTRATIONS, GET_REGIONS, GET_SITTINGS, GET_STUDENT_SITTINGS } from "../graphql/queries";
import { CREATE_COURSE_CURRICULUM, CREATE_COURSE_REGISTRATION, CREATE_PROGRAM_REGISTRATION, DELETE_COURSE_CURRICULUM, DELETE_COURSE_REGISTRATION, DELETE_PROGRAM_REGISTRATION, DELETE_SITTING } from "../graphql/mutations";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import SaveIcon from '@mui/icons-material/Save';

const CurriculumWeek = ({ index, weekTrainings, unselectedTrainings, setWeekTrainings }) => {
  const { userRole } = useContext(AuthenticatedContext);
  const [trainings, setTrainings] = useState([]);

  useEffect(() => {
    setTrainings([...weekTrainings]);
  }, [weekTrainings]);

  return (
    <Box sx={{ my: 2 }}>
      <Typography
        color="primary"
        gutterBottom
        variant="body1"
      >
        Week {index+1}
      </Typography>

      {
        ["Admin", "Education Consultant"].includes(userRole) && (
          <Stack direction="row">
            <Button variant="contained" sx={{ mr: 2 }} onClick={() => setTrainings([...trainings, { trainingId: 0, percentile: 0, name: "" }])}>Add Training</Button>
            <Button variant="contained" startIcon={<SaveIcon />} onClick={() => setWeekTrainings(index, trainings)} >
              Save
            </Button>
          </Stack>
        )
      }
      {
        trainings.map((t, i) => {
          return (
            <Stack direction="row">
              <Autocomplete
                options={unselectedTrainings}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Training" margin="normal" />}
                value={trainings[i]}
                onChange={(event, newValue) => { 
                  let newTrainings = [...trainings]; 
                  newTrainings[i]["trainingId"] = newValue.id; 
                  newTrainings[i]["name"] = newValue.name; 
                  setTrainings([...newTrainings])
                }}
                sx={{ width: 400, mr: 2 }}
                isOptionEqualToValue={(option, value) => option.id === value.trainingId}
                disabled={!["Admin", "Education Consultant"].includes(userRole)}
              />
              <TextField
                sx={{ pt: 2, mr: 2 }}
                label="Percentile"
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    pt: 3
                  }
                }}
                type="number"
                inputProps={{ min: 0, max: 100 }}
                value={trainings[i].percentile}
                onChange={(event) => {
                  let newTrainings = [...trainings];
                  newTrainings[i].percentile = event.target.valueAsNumber;
                  setTrainings([...newTrainings]);
                }}
                disabled={!["Admin", "Education Consultant"].includes(userRole)}
              />
              {
                ["Admin", "Education Consultant"].includes(userRole) && (
                  <IconButton color="primary" onClick={() => { let newTrainings = [...trainings]; newTrainings.splice(i, 1); setTrainings([...newTrainings])}}>
                    <DeleteIcon />
                  </IconButton>
                )
              }
            </Stack>
          )
        })
      }
      <Divider sx={{ mt: 2 }} />
    </Box>
  )
};

export default CurriculumWeek;