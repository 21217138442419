import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MobileStepper,
  Paper,
  Radio,
  RadioGroup,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AuthenticatedContext, ModuleContext, StudentMonitoringContext, TimerContext } from "../App";
import { useHistory, useLocation } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import InputBoxType from "../components/questionType/InputBoxType";
import MultipleCheckBoxType from "../components/questionType/MultipleCheckBoxType";
import HorizontalSortType from "../components/questionType/HorizontalSortType";
import SortOrderType from "../components/questionType/SortOrderType";
import MultipleTrueOrFalseType from "../components/questionType/MultipleTrueOrFalseType";
import MultipleInlineSelectType from "../components/questionType/MultipleInlineSelectType";
import DragDropIntoBoxType from "../components/questionType/DragDropIntoBoxType";
import SingleInlineSelectType from "../components/questionType/SingleInlineSelectType";
import MultipleChoiceType from "../components/questionType/MultipleChoiceType";
import InlineSelectType from "../components/questionType/InlineSelectType";
import { estimateAbilityEAP } from 'irt-js';
import WordExplanation from "../components/questionType/WordExplanation";
import WordExplanationV2 from "../components/questionType/WordExplanationV2";
import { GET_SITTING, GET_FORMATTED_PASSAGE, GET_FORMATTED_QUESTION, GET_SURVEY_QUESTIONS } from "../graphql/queries";
import CheckIcon from '@mui/icons-material/Check';
import EmbedType from "../components/questionType/EmbedType";
import InputBoxFractionType from "../components/questionType/InputBoxFractionType";
import InputBoxMixedFractionType from "../components/questionType/InputBoxMixedFractionType";
import MultipleInputBoxType from "../components/questionType/MultipleInputBoxType";
import { CREATE_STUDENT_FEEDBACK } from "../graphql/mutations";
var moment = require('moment');

const Question = () => {
  const { moduleSettings, setModuleSettings, finishSitting, exitSitting } = React.useContext(ModuleContext);
  const { setLoading } = React.useContext(AuthenticatedContext);
  const { setTimeLeft, setTimeUp } = React.useContext(TimerContext);
  const { studentMonitoringData } = useContext(StudentMonitoringContext)
  const history = useHistory();
  const { userRole, user } = useContext(AuthenticatedContext);
  const [diagOpen, setDiagOpen] = useState(false);
  const [surveyOpen, setSurveyOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [questions, setQuestions] = useState([]);
  const [ratings, setRatings] = useState([]);

  function toTitleCase(str) {
    return str.toLowerCase().split(' ').map(function (word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  const changeRatings = (index) => (event) => {
    let newRatings = [...ratings];
    newRatings[index] = event.target.value;
    setRatings(newRatings);
  }

  const [getSurveyQuestions, { regLoading, regError, regData }] = useLazyQuery(GET_SURVEY_QUESTIONS, {
    onCompleted: ({ getSurveyQuestions }) => {
      let initRatings = [];
      for (let i = 0; i < getSurveyQuestions.length; i++) {
        initRatings.push("");
      }
      setRatings(initRatings);
      setQuestions(getSurveyQuestions);
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [createStudentFeedback] = useMutation(CREATE_STUDENT_FEEDBACK, {
    onCompleted: ({ createStudentFeedback }) => {
      cancelSurvey();
    },
    onError: (error) => {
      console.log(`create sitting error: ${error}`);
    },
    fetchPolicy: "network-only",
  });

  const handleFeedbackChange = ({ target }) => {
    setFeedback(target.value);
  }

  const cancelSurvey = () => {
    const typeCopy = moduleSettings.moduleType;
    exitSitting();
    if (typeCopy === "Training" || typeCopy === "Assessment" || typeCopy === "Interactive") {
      setSurveyOpen(false);
      return history.push("/studenttraining");
    }
    setSurveyOpen(false);
    history.push("/dashboard");
  }

  const submitSurvey = () => {
    const studentFeedback = questions.map((q, i) => {
      const surveyResponse = ratings[i];
      return {
        type: "Module Survey",
        rating: 0,
        moduleId: moduleSettings.selectedModule?.id || 0,
        moduleType: moduleSettings.moduleType || "",
        subject: "",
        feedback: surveyResponse,
        studentId: JSON.parse(user).student_id,
        surveyQuestionId: q.id
      }
    });
    createStudentFeedback({
      variables: {
        responses: studentFeedback
      }
    });
  }

  const checkAnswer = (chosen, correctAnswer) => {
    if (!chosen || !correctAnswer) return false;
    let isCorrect;
    const correctAns = correctAnswer
      .split('[or]')
      .map((as) => as.trim());
    const chosenAns = chosen.split('[split]').map((c) => c.trim().toLowerCase());
    for (let i = 0; i < correctAns.length; i++) {
      const correctAnsComponent = correctAns[i].split('[split]').map(as => as.trim().toLowerCase());
      for (let j = 0; j < correctAnsComponent.length; j++) {
        if (correctAnsComponent[j] !== chosenAns[j]) {
          break;
        }
        if (j === correctAnsComponent.length - 1) isCorrect = true;
      }
      if (isCorrect) break;
      if (i === correctAns.length - 1) isCorrect = false;
    }
  }
  const getQuestionType = (currentQuestion) => {
    switch (currentQuestion?.questionType) {
      case "MultipleChoiceType":
        return (
          <MultipleChoiceType
            currentQuestion={currentQuestion}
            moduleType={moduleSettings.moduleType}
            readOnly={
              userRole === "Student" ? moduleSettings.moduleType !== "Assessment" ? (moduleSettings.reviews?.length > 0 || moduleSettings.readOnly) : moduleSettings.readOnly : true
            }
            isTrainingAnswerCorrect={userRole === "Student" ? moduleSettings.isTrainingAnswerCorrect : moduleSettings.reviews?.find(r => r.questionId === currentQuestion.id)?.chosen === currentQuestion.correctAns}
            isAssessmentAnswerCorrect={userRole === "Student" ? moduleSettings.isAssessmentAnswerCorrect : moduleSettings.reviews?.find(r => r.questionId === currentQuestion.id)?.chosen === currentQuestion.correctAns}
            isResultShown={moduleSettings.isResultShown}
            isReview={userRole === "Student" ? moduleSettings.moduleType !== "Assessment" ? moduleSettings.reviews?.length > 0 : false : true}
          />
        );
      case "DragDropIntoBoxType":
        return (
          <DragDropIntoBoxType
            currentQuestion={currentQuestion}
            readOnly={moduleSettings.reviews?.length > 0}
          />
        );
      case "SortOrderType":
        return (
          <SortOrderType
            currentQuestion={currentQuestion}
            readOnly={moduleSettings.reviews?.length > 0}
          />
        );
      case "HorizontalSortType":
        return (
          <HorizontalSortType
            currentQuestion={currentQuestion}
            readOnly={moduleSettings.reviews?.length > 0}
          />
        );
      case "InlineSelectType":
        return (
          <InlineSelectType
            currentQuestion={currentQuestion}
            readOnly={moduleSettings.reviews?.length > 0}
          />
        );
      case "MultipleCheckBoxType":
        return (
          <MultipleCheckBoxType
            currentQuestion={currentQuestion}
            readOnly={moduleSettings.reviews?.length > 0}
          />
        );
      // case 7:
      //   return Answer7;
      // case 8:
      //   return Answer8;
      // case 9:
      //   return Answer9;
      case "InputBoxType":
        return (
          <InputBoxType
            currentQuestion={currentQuestion}
            moduleType={moduleSettings.moduleType}
            readOnly={
              userRole === "Student" ? moduleSettings.moduleType !== "Assessment" ? (moduleSettings.reviews?.length > 0 || moduleSettings.readOnly) : moduleSettings.readOnly : true
            }
            isTrainingAnswerCorrect={moduleSettings.isTrainingAnswerCorrect}
            isAssessmentAnswerCorrect={userRole === "Student" ? moduleSettings.isAssessmentAnswerCorrect : checkAnswer(moduleSettings.reviews?.find(r => r.questionId === currentQuestion.id)?.chosen, currentQuestion.correctAns)}
            isResultShown={moduleSettings.isResultShown}
            isReview={userRole === "Student" ? moduleSettings.moduleType !== "Assessment" ? moduleSettings.reviews?.length > 0 : false : true}
          />
        );
      case "InputBoxFractionType":
        return (
          <InputBoxFractionType
            currentQuestion={currentQuestion}
            moduleType={moduleSettings.moduleType}
            readOnly={
              userRole === "Student" ? moduleSettings.moduleType !== "Assessment" ? (moduleSettings.reviews?.length > 0 || moduleSettings.readOnly) : moduleSettings.readOnly : true
            }
            isTrainingAnswerCorrect={moduleSettings.isTrainingAnswerCorrect}
            isAssessmentAnswerCorrect={userRole === "Student" ? moduleSettings.isAssessmentAnswerCorrect : checkAnswer(moduleSettings.reviews?.find(r => r.questionId === currentQuestion.id)?.chosen, currentQuestion.correctAns)}
            isResultShown={moduleSettings.isResultShown}
            isReview={userRole === "Student" ? moduleSettings.moduleType !== "Assessment" ? moduleSettings.reviews?.length > 0 : false : true}
          />
        );
      case "InputBoxMixedFractionType":
        return (
          <InputBoxMixedFractionType
            currentQuestion={currentQuestion}
            moduleType={moduleSettings.moduleType}
            readOnly={
              userRole === "Student" ? moduleSettings.moduleType !== "Assessment" ? (moduleSettings.reviews?.length > 0 || moduleSettings.readOnly) : moduleSettings.readOnly : true
            }
            isTrainingAnswerCorrect={moduleSettings.isTrainingAnswerCorrect}
            isAssessmentAnswerCorrect={userRole === "Student" ? moduleSettings.isAssessmentAnswerCorrect : checkAnswer(moduleSettings.reviews?.find(r => r.questionId === currentQuestion.id)?.chosen, currentQuestion.correctAns)}
            isResultShown={moduleSettings.isResultShown}
            isReview={userRole === "Student" ? moduleSettings.moduleType !== "Assessment" ? moduleSettings.reviews?.length > 0 : false : true}
          />
        );
      case "MultipleInputBoxType":
        return (
          <MultipleInputBoxType
            currentQuestion={currentQuestion}
            moduleType={moduleSettings.moduleType}
            readOnly={
              userRole === "Student" ? moduleSettings.moduleType !== "Assessment" ? (moduleSettings.reviews?.length > 0 || moduleSettings.readOnly) : moduleSettings.readOnly : true
            }
            isTrainingAnswerCorrect={moduleSettings.isTrainingAnswerCorrect}
            isAssessmentAnswerCorrect={userRole === "Student" ? moduleSettings.isAssessmentAnswerCorrect : !!(currentQuestion.correctAns.split("[split]").find(a => a === moduleSettings.reviews?.find(r => r.questionId === currentQuestion.id)?.chosen))}
            isResultShown={moduleSettings.isResultShown}
            isReview={userRole === "Student" ? moduleSettings.moduleType !== "Assessment" ? moduleSettings.reviews?.length > 0 : false : true}
          />
        );
      case "MultipleTrueOrFalseType":
        return (
          <MultipleTrueOrFalseType
            currentQuestion={currentQuestion}
            readOnly={moduleSettings.reviews?.length > 0}
          />
        );
      case "MultipleInlineSelectType":
        return (
          <MultipleInlineSelectType
            currentQuestion={currentQuestion}
            readOnly={moduleSettings.reviews?.length > 0}
          />
        );
      case "SingleInlineSelectType":
        return (
          <SingleInlineSelectType
            currentQuestion={currentQuestion}
            readOnly={moduleSettings.reviews?.length > 0}
          />
        );
      case "WordExplanation":
        return <WordExplanation currentQuestion={currentQuestion} />;
      case "WordExplanationV2":
        return <WordExplanationV2 currentQuestion={currentQuestion} />;
      case "EmbedType":
        return <EmbedType currentQuestion={currentQuestion} />;
      default:
        return null;
    }
  };

  const closeDialog = () => {
    setModuleSettings({
      ...moduleSettings,
      open: false,
    });
  };

  const submitSitting = async () => {
    const moduleCopy = {...moduleSettings.selectedModule};
    const typeCopy = moduleSettings.moduleType;
    await finishSitting();
    setTimeUp(false);
    setModuleSettings({
      ...moduleSettings,
      countdown: false
    });
    getSurveyQuestions({
      variables: {
        usage: "Post Module Survey"
      }
    });
    setSurveyOpen(true);
    // if (typeCopy === "Training" || typeCopy === "Assessment" || typeCopy === "Interactive") {
    //   exitSitting();
    //   return history.push("/studenttraining");
    // }
    // if (moduleCopy.isDiagnostic && typeCopy === "Placement") {
    //   // setDiagOpen(true);
    //   exitSitting();
    //   history.push("/dashboard");
    // } else {
    //   exitSitting();
    //   history.push("/dashboard");
    // }
  };

  const [getFormattedPassage] = useLazyQuery(GET_FORMATTED_PASSAGE, {
    fetchPolicy: "no-cache",
  });

  const [getFormattedQuestion] = useLazyQuery(GET_FORMATTED_QUESTION, {
    fetchPolicy: "no-cache",
  });

  const [getSitting] = useLazyQuery(GET_SITTING, {
    onCompleted: async (sittingItems) => {
      console.log(sittingItems);
      if (sittingItems.getSitting.reviews?.length > 0) {
        setModuleSettings({
          ...moduleSettings,
          currentSitting:
            (moduleSettings.moduleType !== "Training" && moduleSettings.moduleType !== "Interactive")
              ? sittingItems.getSitting.testSitting
              : sittingItems.getSitting.trainingSitting,
          questions: sittingItems.getSitting.questions,
          answers:
          (moduleSettings.moduleType !== "Training" && moduleSettings.moduleType !== "Interactive")
              ? sittingItems.getSitting.testAnswers
              : sittingItems.getSitting.trainingAnswers,
          openReview: true,
          reviews: sittingItems.getSitting.reviews,
          incorrect: sittingItems.getSitting.reviews
            .filter((r) => !r.isCorrect)
            .map((r) => r.buttonNumber),
          ...moduleSettings.moduleType === "Training" ? { totalParts: sittingItems.getSitting.numSections } : {},
        });
      } else if (moduleSettings.moduleType === "Diagnostic") {
        if (sittingItems.getSitting.diagSitting && !sittingItems.getSitting.diagSitting.route) {
          setModuleSettings({
            ...moduleSettings,
            currentSitting: sittingItems.getSitting.diagSitting,
            open: false,
            openReview: false,
            openDiag: true
          });
          setLoading(false);
          return;
        }
        let questions = [...sittingItems.getSitting.questions];
        let currentQuestion = { ...sittingItems.getSitting.currentQuestion };
        let selectedAnswer;
        let currentQuestionNumber;
        let attempt = 1;
        let loadedPassages = {};
        if (sittingItems.getSitting.diagAnswers) {
          const answer =
            sittingItems.getSitting.diagAnswers[
              sittingItems.getSitting.diagAnswers.length - 1
            ];
          if (answer.diagnosticQuestionId === currentQuestion.id) {
            let answers = answer.chosen.split("[split]");
            selectedAnswer = answers[answers.length - 1];
            attempt = answers.length + 1;
            currentQuestionNumber = sittingItems.getSitting.diagAnswers.length;
          } else {
            currentQuestionNumber =
              sittingItems.getSitting.diagAnswers.length + 1;
          }
        }

        if (currentQuestion.passageId) {
          const passageResult = await getFormattedPassage({
            variables: { passage_id: currentQuestion.passageId },
          });
          if (passageResult && passageResult.data.getFormattedPassage) {
            currentQuestion.src = passageResult.data.getFormattedPassage;
            loadedPassages[currentQuestion.passageId] =
              passageResult.data.getFormattedPassage;
          }
        }

        const questionIndex = questions.findIndex(
          (q) => q.id === currentQuestion.id
        );
        questions.splice(questionIndex, 1, currentQuestion);

        setModuleSettings({
          ...moduleSettings,
          currentSitting: sittingItems.getSitting.diagSitting,
          currentQuestion,
          questions,
          answers: sittingItems.getSitting.diagAnswers || [],
          ...(currentQuestionNumber
            ? {
                currentQuestionNumber,
                totalQuestionNumber: currentQuestionNumber,
              }
            : { currentQuestionNumber: 1, totalQuestionNumber: 1 }),
          open: false,
          openReview: false,
          ...(selectedAnswer
            ? { selectedAnswer, initialAnswer: selectedAnswer }
            : { initialAnswer: "" }),
          startTime: moment(),
          diagTree: [...sittingItems.getSitting.diagTree],
          maxOrder: sittingItems.getSitting.maxOrder,
          loadedPassages,
          attempt,
        });
      } else if (moduleSettings.moduleType === "Training" || moduleSettings.moduleType === "Interactive") {
        let loadedPassages = {};
        let questions = [...sittingItems.getSitting.questions];
        let currentQuestion = { ...sittingItems.getSitting.currentQuestion };
        let selectedAnswer;
        let lastAnswer
        if (sittingItems.getSitting.trainingAnswers) {
          const answer =
            sittingItems.getSitting.trainingAnswers[
              sittingItems.getSitting.trainingAnswers.length - 1
            ];
          if (answer.questionNumber === currentQuestion.order) {
            selectedAnswer = answer.chosen;
            lastAnswer = answer
          }
        }

        // let lastQuestion = sittingItems.getSitting.trainingAnswers && sittingItems.getSitting.trainingAnswers[sittingItems.getSitting.trainingAnswers.length - 1] || null

        if (currentQuestion.passageId) {
          const passageResult = await getFormattedPassage({
            variables: { passage_id: currentQuestion.passageId },
          });
          if (passageResult && passageResult.data.getFormattedPassage) {
            currentQuestion.src = passageResult.data.getFormattedPassage;
            loadedPassages[currentQuestion.passageId] =
              passageResult.data.getFormattedPassage;
          }
        }

        const questionIndex = questions.findIndex(
          (q) => q.order === currentQuestion.order
        );
        questions.splice(questionIndex, 1, currentQuestion);

        let logoutTimeTaken = null

        if (sittingItems.getSitting.trainingSitting.logoutTime) {
          logoutTimeTaken = moment() - sittingItems.getSitting.trainingSitting.logoutTime
        }

        if (moduleSettings.moduleType === "Training") {
          setModuleSettings({
            ...moduleSettings,
            currentSitting: sittingItems.getSitting.trainingSitting,
            currentQuestion,
            questions,
            answers: sittingItems.getSitting.trainingAnswers || [],
            currentQuestionNumber: parseInt(
              sittingItems.getSitting.trainingSitting.upTo
            ),
            totalQuestionNumber: parseInt(
              sittingItems.getSitting.trainingSitting.upTo
            ),
            open: false,
            openReview: false,
            isResultShown: lastAnswer ? lastAnswer.isResultShown : false,
            readOnly: lastAnswer ? lastAnswer.readOnly : false,
            isTrainingAnswerCorrect: lastAnswer && lastAnswer.isCorrect,
            loadedPassages,
            ...(selectedAnswer
              ? { selectedAnswer, initialAnswer: selectedAnswer }
              : { initialAnswer: "" }),
            startTime: lastAnswer && lastAnswer.isResultShown ? null : moment(),
            endTime: lastAnswer && lastAnswer.isResultShown ? moment() : null,
            totalLogoutTime: sittingItems.getSitting.trainingSitting.totalLogoutTime ? sittingItems.getSitting.trainingSitting.totalLogoutTime + logoutTimeTaken : logoutTimeTaken,
            countdown: true,
            totalScorePartOne: Number(sittingItems.getSitting.trainingSitting.scoreSection1),
            totalScorePartTwo: Number(sittingItems.getSitting.trainingSitting.scoreSection2),
            totalScorePartThree: Number(sittingItems.getSitting.trainingSitting.scoreSection3),
            totalTimeTakenPartOne: sittingItems.getSitting.trainingSitting.timeUsedSection1,
            totalTimeTakenPartTwo: sittingItems.getSitting.trainingSitting.timeUsedSection2,
            totalTimeTakenPartThree: sittingItems.getSitting.trainingSitting.timeUsedSection3,
            partOneAllCorrect: sittingItems.getSitting.trainingSitting.partOneAllCorrect,
            sectionOnePass: sittingItems.getSitting.trainingSitting.sectionOnePass,
            sectionTwoPass: sittingItems.getSitting.trainingSitting.sectionTwoPass
          });
        } else {
          setModuleSettings({
            ...moduleSettings,
            currentSitting: sittingItems.getSitting.trainingSitting,
            currentQuestion,
            questions,
            answers: sittingItems.getSitting.trainingAnswers || [],
            currentQuestionNumber: parseInt(
              sittingItems.getSitting.trainingSitting.upTo
            ),
            totalQuestionNumber: parseInt(
              sittingItems.getSitting.trainingSitting.upTo
            ),
            open: false,
            openReview: false,
            isResultShown: false,
            readOnly: false,
            loadedPassages,
            ...(selectedAnswer
              ? { selectedAnswer, initialAnswer: selectedAnswer }
              : { initialAnswer: "" }),
            startTime: lastAnswer && lastAnswer.isResultShown ? null : moment(),
            endTime: lastAnswer && lastAnswer.isResultShown ? moment() : null,
            totalLogoutTime: sittingItems.getSitting.trainingSitting.totalLogoutTime ? sittingItems.getSitting.trainingSitting.totalLogoutTime + logoutTimeTaken : logoutTimeTaken,
            countdown: true,
          });
        }
        
        
      } else if (moduleSettings.selectedModule.isAdaptive) {
        let marks = { All: [] };
        let zetas = { All: [] };
        let abilities = { All: [] };
        let questionsPicked = {};
        let strands = sittingItems.getSitting.strands;
        let lowest = sittingItems.getSitting.lowest;
        let loadedPassages = {};
        let passages = sittingItems.getSitting.passages;
        let route = [...sittingItems.getSitting.route];
        let questions = [...sittingItems.getSitting.questions];
        let flags = [];
        let passageLeft = 0;
        let limit = {};

        for (let i = 0; i < strands.length; i++) {
          marks[`${strands[i].name}`] = [];
          zetas[`${strands[i].name}`] = [];
          abilities[`${strands[i].name}`] = [];
        }

        lowest.map(t => {
          questionsPicked[`${t.name}`] = 0;
        });

        strands.map(s => {
          const topics = lowest.filter(l => l.strand === s.name).length;
          let extra = 0;
          lowest.map(l => {
            if (l.strand === s.name) {
              if (s.numQuestions % topics === 0) {
                limit[`${l.name}`] = s.numQuestions / topics;
              } else {
                if (extra < s.numQuestions % topics) {
                  limit[`${l.name}`] = Math.ceil(s.numQuestions / topics);
                  extra++;
                } else {
                  limit[`${l.name}`] = Math.floor(s.numQuestions / topics);
                }
              }
            }
          });
        })

        for (let i = 0; i < sittingItems.getSitting.route.length; i++) {
          questionsPicked[`${sittingItems.getSitting.route[i].lowest}`]++;
        }

        if (sittingItems.getSitting.testAnswers) {
          let hasFlags = sittingItems.getSitting.testAnswers.filter(
            (a) => a.isFlagged === true
          );
          flags = hasFlags.map((a) => a.questionId);
          for (let i = 0; i < sittingItems.getSitting.marks.length; i++) {
            marks.All.push(sittingItems.getSitting.marks[i].mark);
            marks[`${sittingItems.getSitting.marks[i].strand}`].push(
              sittingItems.getSitting.marks[i].mark
            );
            let newZeta = sittingItems.getSitting.zetas[i];
            zetas.All.push({
              a: newZeta.a,
              b: newZeta.b,
              c: newZeta.c,
            });
            zetas[`${sittingItems.getSitting.zetas[i].strand}`].push({
              a: newZeta.a,
              b: newZeta.b,
              c: newZeta.c,
            });
          }

          let strands = Object.keys(marks);
          for (let strand of strands) {
            abilities[`${strand}`] = estimateAbilityEAP(
              marks[`${strand}`],
              zetas[`${strand}`]
            );
          }

          if (passages?.length > 0) {
            if (
              parseInt(sittingItems.getSitting.testSitting.upTo) >
              sittingItems.getSitting.numStart
            ) {
              let passageId = route[route.length - 1].passageId;
              let currentPassage = passages.find(
                (p) => p.passageId === passageId
              );
              passageLeft =
                currentPassage.numQuestions -
                route.filter((q) => q.passageId === passageId).length;
            }
          }
        }

        let currentQuestion;
        let selectedAnswer;
        if (sittingItems.getSitting.currentQuestion) {
          currentQuestion = { ...sittingItems.getSitting.currentQuestion };
          if (sittingItems.getSitting.testAnswers) {
            const answer =
              sittingItems.getSitting.testAnswers[
                sittingItems.getSitting.testAnswers.length - 1
              ];
            selectedAnswer = answer.chosen;
          }
        } else {
          let nextLowest;
          while (true) {
            nextLowest = lowest[Math.floor(Math.random() * lowest.length)];
            if (
              questionsPicked[`${nextLowest.name}`] < limit[`${nextLowest.name}`]
            ) {
              break;
            }
          }

          let questionList = [];
          let strandAbility = abilities[`${nextLowest.strand}`];
          let ability =
            strandAbility * (moduleSettings.selectedModule.variance / 3) +
            moduleSettings.selectedModule.mean;
          let gap = 0.1;
          let newQuestions = [];
          let routeIds = route.map((r) => r.id);
          const answer =
            sittingItems.getSitting.testAnswers[
              sittingItems.getSitting.testAnswers.length - 1
            ];

          if (passages?.length > 0) {
            let lastMark;
            if (passageLeft === 0) {
              if (strandAbility >= -0.00001 && strandAbility <= 0.00001) {
                ability =
                  abilities.All * (moduleSettings.selectedModule.variance / 3) +
                  moduleSettings.selectedModule.mean;
                lastMark = marks.All[marks.All.length - 1];
              } else {
                lastMark =
                  marks[`${nextLowest.strand}`][
                    marks[`${nextLowest.strand}`].length - 1
                  ];
              }

              let currPassages = [...new Set(route.map((r) => r.passageId))];
              let passageList = [];
              let nextPassage;
              while (newQuestions.length === 0) {
                if (lastMark === 0) {
                  passageList = passages.filter(
                    (p) =>
                      p.difficulty > ability - gap &&
                      p.difficulty < ability + gap / 10
                  );
                } else {
                  passageList = passages.filter(
                    (p) =>
                      p.difficulty > ability - gap / 10 &&
                      p.difficulty < ability + gap
                  );
                }
                passageList = passageList.filter(
                  (p) => !currPassages.includes(p.passageId)
                );
                if (passageList.length > 0) {
                  questionList = questions.filter(
                    (q) =>
                      passageList.find((p) => p.passageId === q.passageId) &&
                      q.strand === nextLowest.strand
                  );
                  if (questionList.length === 0) {
                    gap += 0.05;
                    continue;
                  }
                  let pickedQuestion =
                    questionList[
                      Math.floor(Math.random() * questionList.length)
                    ];
                  nextPassage = passageList.find(
                    (p) => p.passageId === pickedQuestion.passageId
                  );
                  questionList = questions
                    .filter((q) => q.passageId === nextPassage.passageId)
                    .sort((a, b) => a.difficulty - b.difficulty);
                  newQuestions.push(
                    questionList[Math.floor(questionList.length / 2)]
                  );
                }
                gap += 0.05;
              }
              passageLeft = nextPassage.numQuestions - 1;
            } else {
              while (newQuestions.length === 0) {
                if (
                  marks[`${nextLowest.strand}`][
                    marks[`${nextLowest.strand}`].length - 1
                  ] === 0
                ) {
                  questionList = questions.filter(
                    (q) =>
                      q.difficulty > ability - 0.1 - gap &&
                      q.difficulty < ability - 0.1 + gap / 10 &&
                      q.passageId === route[route.length - 1].passageId
                  );
                } else {
                  questionList = questions.filter(
                    (q) =>
                      q.difficulty > ability - 0.1 - gap &&
                      q.difficulty < ability - 0.1 + gap / 10 &&
                      q.passageId === route[route.length - 1].passageId
                  );
                }
                newQuestions = questionList.filter(
                  (q) => !routeIds.includes(q.id)
                );
                gap += 0.05;
              }
              passageLeft--;
            }
          } else {
            while (newQuestions.length === 0) {
              if (answer.isCorrect) {
                questionList = questions.filter(
                  (q) =>
                    q.difficulty > ability + 0.1 - gap / 10 &&
                    q.difficulty < ability + 0.1 + gap
                );
              } else {
                questionList = questions.filter(
                  (q) =>
                    q.difficulty > ability - 0.1 - gap &&
                    q.difficulty < ability - 0.1 + gap / 10
                );
              }

              newQuestions = questionList.filter(
                (q) => !routeIds.includes(q.id)
              );
              gap += 0.05;
            }
          }

          currentQuestion =
            newQuestions[Math.floor(Math.random() * newQuestions.length)];
          const result = await getFormattedQuestion({
            variables: {
              question_id: currentQuestion.id,
              is_review:
                moduleSettings.moduleType === "Training" ? true : false,
            },
          });
          if (result && result.data.getFormattedQuestion) {
            currentQuestion = result.data.getFormattedQuestion;
          }
          route.push({
            id: currentQuestion.id,
            strand: currentQuestion.strand,
            passageId: currentQuestion.passageId,
          });
        }

        if (currentQuestion.passageId) {
          const passageResult = await getFormattedPassage({
            variables: { passage_id: currentQuestion.passageId },
          });
          if (passageResult && passageResult.data.getFormattedPassage) {
            currentQuestion.src = passageResult.data.getFormattedPassage;
            loadedPassages[currentQuestion.passageId] =
              passageResult.data.getFormattedPassage;
          }
        }

        const questionIndex = questions.findIndex(
          (q) => q.id === currentQuestion.id
        );
        questions.splice(questionIndex, 1, currentQuestion);

        // setCountdown(true);
        setTimeLeft(
          sittingItems.getSitting.testSitting.createdAt +
            moduleSettings.selectedModule.duration * 60 * 1000
        );
        setModuleSettings({
          ...moduleSettings,
          currentSitting: sittingItems.getSitting.testSitting,
          currentQuestion,
          questions,
          answers: sittingItems.getSitting.testAnswers || [],
          ...(sittingItems.getSitting.testAnswers
            ? {
                currentQuestionNumber: parseInt(
                  sittingItems.getSitting.testSitting.upTo
                ),
              }
            : { currentQuestionNumber: 1 }),
          totalQuestionNumber: parseInt(
            sittingItems.getSitting.testSitting.upTo
          ),
          route,
          marks,
          zetas,
          abilities,
          questionsPicked,
          strands,
          open: false,
          openReview: false,
          numStart: sittingItems.getSitting.numStart,
          ...(passages?.length > 0
            ? { passages, isComprehension: true, passageLeft }
            : {}),
          loadedPassages,
          ...(selectedAnswer
            ? { selectedAnswer, initialAnswer: selectedAnswer }
            : { initialAnswer: "" }),
          flags,
          startTime: moment(),
          countdown: true,
          lowest,
          limit
        });
      } else {
        let loadedPassages = {};
        let questions = [...sittingItems.getSitting.questions];
        let flags = [];
        let currentQuestion = { ...sittingItems.getSitting.currentQuestion };
        let selectedAnswer;
        if (sittingItems.getSitting.testAnswers) {
          let hasFlags = sittingItems.getSitting.testAnswers.filter(
            (a) => a.isFlagged === true
          );
          flags = hasFlags.map((a) => a.questionId);
          const answer =
            sittingItems.getSitting.testAnswers[
              sittingItems.getSitting.testAnswers.length - 1
            ];
          if (answer.questionId === currentQuestion.id) {
            selectedAnswer = answer.chosen;
          }
        }

        if (currentQuestion.passageId) {
          const passageResult = await getFormattedPassage({
            variables: { passage_id: currentQuestion.passageId },
          });
          if (passageResult && passageResult.data.getFormattedPassage) {
            currentQuestion.src = passageResult.data.getFormattedPassage;
            loadedPassages[currentQuestion.passageId] =
              passageResult.data.getFormattedPassage;
          }
        }

        const questionIndex = questions.findIndex(
          (q) => q.id === currentQuestion.id
        );
        questions.splice(questionIndex, 1, currentQuestion);

        setTimeLeft(
          sittingItems.getSitting.testSitting.createdAt +
            moduleSettings.selectedModule.duration * 60 * 1000
        );
        setModuleSettings({
          ...moduleSettings,
          currentSitting: sittingItems.getSitting.testSitting,
          currentQuestion,
          questions,
          answers: sittingItems.getSitting.testAnswers || [],
          currentQuestionNumber: parseInt(
            sittingItems.getSitting.testSitting.upTo
          ),
          totalQuestionNumber: parseInt(
            sittingItems.getSitting.testSitting.upTo
          ),
          open: false,
          openReview: false,
          loadedPassages,
          ...(selectedAnswer
            ? { selectedAnswer, initialAnswer: selectedAnswer }
            : { initialAnswer: "" }),
          flags,
          startTime: moment(),
          countdown: true,
        });
      }
      setLoading(false);
    },
    onError: error => console.log(`Get Sitting error: ${error}`),
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (
      moduleSettings.selectedModule &&
      moduleSettings.moduleType &&
      !moduleSettings.currentQuestion
    ) {
      setLoading(true);
      getSitting({
        variables: {
          ...moduleSettings.moduleType === "Diagnostic" ? { program_id: moduleSettings.selectedModule.id } : { module_id: moduleSettings.selectedModule.id },
          module_type: moduleSettings.moduleType,
          ...(studentMonitoringData?.id ? { student_id: studentMonitoringData?.id } : {})
        },
      });
    }
    return () => {
      console.log("unmount q");
    }
  }, []);

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Dialog open={diagOpen}>
        <DialogContent>
          <Typography variant="body1" component="div">
            <strong>Additional Instruction for Problem Solving Behaviour test:</strong>
          </Typography>
          <br />
          <Typography variant="body1" component="div">
            Well done on completing the first part of the Problem Solving Behaviour & Ability test. Click 'Start Diagnostic Test' to start the second part or 'Return to Dashboard' to exit and come back later.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              exitSitting();
              setDiagOpen(false);
              history.push("/dashboard");
            }
          }>
            Return to Dashboard
          </Button>
          <Button 
            onClick={() => {
              setModuleSettings({
                ...moduleSettings,
                moduleType: "Diagnostic",
                currentSitting: null,
                currentQuestion: null,
                answers: [],
                flags: [],
                reviews: [],
                questions: [],
                reviewQuestions: [],
                marks: null,
                currentQuestionNumber: null,
                zetas: null,
                route: [],
                abilities: null,
                totalQuestionNumber: null,
                questionsPicked: null,
                strands: [],
                passages: [],
                isPassage: false,
                passageLeft: 0,
                isComprehension: false,
                numStart: 0,
                loadedPassages: {},
                selectedAnswer: "",
                open: false,
                openReview: false,
                initialAnswer: "",
                startTime: null,
                endTime: null,
                totalLogoutTime: null,
                countdown: false,
                incorrect: [],
                incorrectIndex: 0,
                diagTree: [],
                attempt: null,
                maxOrder: 1,
                isTrainingAnswerCorrect: false,
                isAssessmentAnswerCorrect: false,
                isResultShown: false,
                readOnly: false,
                currentPart: 1,
                lowest: [],
                limit: {},
              });
              setDiagOpen(false);
              history.push("/instructions");
            }
          }>
            Start Diagnostic Test
          </Button>
        </DialogActions>
      </Dialog>
      {/* {moduleSettings.currentQuestion &&
        moduleSettings.moduleType === "Diagnostic" && (
          <Typography sx={{ mb: 1.5 }}>
            Attempt: {moduleSettings.attempt} of{" "}
            {moduleSettings.currentQuestion.attempts}
          </Typography>
        )} */}
      {moduleSettings.currentQuestion &&
        moduleSettings.moduleType === "Training" && (
          <Card sx={{ mb: 1.5, py: 2.5, px: 4 }}>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item xl={1.5} lg={1.6} md={1.7}>
                <Typography variant="h6" sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18, xl: 20 }}}>
                  Question: {moduleSettings.currentQuestionNumber} of{" "}
                  {moduleSettings.currentPart === 3
                    ? moduleSettings.selectedModule.totalQuestions + moduleSettings.selectedModule.firstGroupQuestions
                    : moduleSettings.selectedModule.totalQuestions}
                </Typography>
              </Grid>
              { moduleSettings.reviews?.length > 0 ? (
                <></>
              ) : (
                <>
                  <Grid item xl={0.75} lg={0.95} md={1}>
                    <Typography variant="h6" sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18, xl: 20 }}}>
                      Progress:
                    </Typography>
                  </Grid>
                  <Grid item xl={9.75} lg={9.45} md={9.3}>
                    {
                      moduleSettings.selectedModule?.firstGroupQuestions === moduleSettings.selectedModule?.totalQuestions ? (
                        <Grid container direction="row" alignItems="center">
                          <Grid item xl={1} lg={1} md={1}>
                            <Avatar sx={{ bgcolor: moduleSettings.currentPart === 1 ? "#C10015" : "limegreen" }}>{moduleSettings.currentPart === 1 ? "1" : <CheckIcon />}</Avatar>
                          </Grid>
                          <Grid item xl={10} lg={10} md={10}>
                            <MobileStepper
                              variant="progress"
                              steps={moduleSettings.selectedModule.totalQuestions + 1}
                              position="static"
                              activeStep={moduleSettings.currentQuestionNumber - 1}
                              sx={{ flexGrow: 1, maxWidth: "100vw" }}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container direction="row" alignItems="center">
                          <Grid item xs={4}>
                            <Grid container direction="row" alignItems="center">
                              <Grid item xl={1.5} lg={2.2} md={2.4}>
                                <Avatar sx={{ bgcolor: moduleSettings.currentPart === 1 ? "#C10015" : "limegreen" }}>{moduleSettings.currentPart === 1 ? "1" : <CheckIcon />}</Avatar>
                              </Grid>
                              <Grid item xl={3.4} lg={2.4} md={2.8}>
                                <Typography variant="subtitle2" sx={{ fontSize: { xs: 8, sm: 10, md: 12, lg: 14, xl: 16 }}}>
                                  Section 1 Part 1
                                </Typography>
                              </Grid>
                              <Grid item xl={7.1} lg={7.4} md={6.8}>
                                <MobileStepper
                                  variant="progress"
                                  steps={moduleSettings.selectedModule.firstGroupQuestions + 1}
                                  position="static"
                                  activeStep={moduleSettings.currentPart === 1 ? moduleSettings.currentQuestionNumber - 1 : moduleSettings.selectedModule.firstGroupQuestions}
                                  sx={{ width: { xs: 80, sm: 180, md: 250, lg: 350, xl: 470 }, flexGrow: 1 }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                            <Grid container direction="row" alignItems="center">
                              <Grid item xl={1.5} lg={2.2} md={2.4}>
                                <Avatar sx={{ bgcolor: `${moduleSettings.currentPart === 1 ? "gray" : moduleSettings.currentPart === 2 ? "#C10015" : "limegreen"}` }}>
                                  {moduleSettings.currentPart !== 3 ? "2" : <CheckIcon />}
                                </Avatar>
                              </Grid>
                              <Grid item xl={3.4} lg={2.4} md={2.8}>
                                <Typography variant="subtitle2" sx={{ fontSize: { xs: 8, sm: 10, md: 12, lg: 14, xl: 16 }}} color={moduleSettings.currentPart === 1 ? "gray" : "black"}>
                                  Section 1 Part 2
                                </Typography>
                              </Grid>
                              <Grid item xl={7.1} lg={7.4} md={6.8}>
                                <MobileStepper
                                  variant="progress"
                                  steps={moduleSettings.selectedModule.totalQuestions - moduleSettings.selectedModule.firstGroupQuestions + 1}
                                  position="static"
                                  activeStep={moduleSettings.currentPart === 1
                                    ? 0 
                                    : moduleSettings.currentPart === 2
                                    ? moduleSettings.currentQuestionNumber - moduleSettings.selectedModule.firstGroupQuestions - 1
                                    : moduleSettings.selectedModule.totalQuestions - moduleSettings.selectedModule.firstGroupQuestions}
                                  sx={{ width: { xs: 80, sm: 180, md: 250, lg: 350, xl: 470 }, flexGrow: 1 }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                            <Grid container direction="row" alignItems="center">
                              <Grid item xl={1.5} lg={2.2} md={2.4}>
                                <Avatar sx={{ bgcolor: `${moduleSettings.currentPart !== 3 ? "gray" : moduleSettings.sectionTwoPass ? "limegreen" : "#C10015"}` }}>
                                  {moduleSettings.sectionTwoPass ? <CheckIcon /> : "3"}
                                </Avatar>
                              </Grid>
                              <Grid item xl={2.2} lg={2.7} md={3.4}>
                                <Typography variant="subtitle2" sx={{ fontSize: { xs: 8, sm: 10, md: 12, lg: 14, xl: 16 }}} color={moduleSettings.currentPart !== 3 ? "gray" : "black"}>
                                  Section 2
                                </Typography>
                              </Grid>
                              <Grid item xl={8.3} lg={7.1} md={6.2}>
                                <MobileStepper
                                  variant="progress"
                                  steps={moduleSettings.selectedModule.totalQuestions - moduleSettings.selectedModule.firstGroupQuestions + 1}
                                  position="static"
                                  activeStep={moduleSettings.sectionTwoPass
                                    ? moduleSettings.currentQuestionNumber - moduleSettings.selectedModule.totalQuestions
                                    : !moduleSettings.sectionTwoPass && moduleSettings.currentPart === 3
                                    ? moduleSettings.currentQuestionNumber - moduleSettings.selectedModule.totalQuestions - 1
                                    : 0}
                                  sx={{ width: { xs: 80, sm: 180, md: 250, lg: 350, xl: 470 }, flexGrow: 1 }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    }
                    
                  </Grid>
                </>
              )}
              
            </Grid>
          </Card>
        )}
      <Paper elevation={0} sx={{ p: 3 }}>
        {getQuestionType(moduleSettings.currentQuestion)}
        <Dialog open={moduleSettings.open}>
          <DialogTitle>Finish Test</DialogTitle>
          <DialogContent>
            Are you sure you want to finish and submit this{" "}
            {moduleSettings.moduleType === "Placement" ? "Test" : moduleSettings.moduleType === "Interactive" ? "Training" : moduleSettings.moduleType}?
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button onClick={submitSitting}>Submit</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={surveyOpen}>
          <DialogTitle>Module Feedback</DialogTitle>
          <DialogContent>
            Fantastic work {toTitleCase(JSON.parse(user).student_givenname || "")}!<br />
            You are a champion for finishing this module! We would love to know how you felt about the module.<br />
            {
              questions.map((q, i) => {
                if (q.responseType === "Radio") {
                  return (
                    <FormControl>
                      <FormLabel>{q.content}</FormLabel>
                      <RadioGroup
                        name="radio-buttons-group"
                        key={i}
                        row
                        value={ratings[i]}
                        onChange={changeRatings(i)}
                      >
                        {
                          q.responseOptions.split("[split]").map(r => {
                            return (<FormControlLabel value={r} control={<Radio />} label={r} />)
                          })
                        }
                      </RadioGroup>
                    </FormControl>
                  )
                }
              })
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelSurvey}>Cancel</Button>
            <Button onClick={submitSurvey}>Submit</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={moduleSettings.openDiag}>
          <DialogTitle>Finish Diagnostic</DialogTitle>
          <DialogContent>
            There are no more questions for you to complete. Please submit your diagnostic test.
          </DialogContent>
          <DialogActions>
            <Button onClick={submitSitting}>Submit</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default Question;
