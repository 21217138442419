import {
  Container,
  IconButton,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Autocomplete,
  TextField,
  OutlinedInput,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import { GET_ALLOCATED_STUDENTS_BY_TEACHER, GET_ALLOCATED_TRAININGS_BY_COURSE, GET_TEACHER_CLASSES } from "../graphql/queries";
import { ADD_TRAINING_TO_CLASS, SET_CURRENT_WEEK, TOGGLE_RAISE_HAND } from "../graphql/mutations";
import { AuthenticatedContext, StudentMonitoringContext } from "../App";
import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoIcon from '@mui/icons-material/Info';
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded';
import { add, getTime } from "date-fns";

const ClassManagementTeacher = () => {
  const { user } = useContext(AuthenticatedContext);
  const { setStudentMonitoringData } = useContext(StudentMonitoringContext)
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);
  const [openInfo, setOpenInfo] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [trainingClasses, setTrainingClasses] = useState([]);
  const [trainingDialogOpen, setTrainingDialogOpen] = useState(false);
  const [trainings, setTrainings] = useState([]);
  const [training, setTraining] = useState(null);
  const [order, setOrder] = useState(0);
  const [week, setWeek] = useState(0);

  const [toggleRaiseHand, { loading: toggleRaiseHandLoading }] = useMutation(TOGGLE_RAISE_HAND, {
    onError: (error) => {
      console.log(`toggleRaiseHand Error ${error}`);
    },
    refetchQueries: [{ 
      query: GET_ALLOCATED_STUDENTS_BY_TEACHER,
      variables: { teacher_id: Number(JSON.parse(user).staff_id) }
    }, 'getAllocatedStudentsByTeacher'],
    fetchPolicy: "no-cache",
  });

  const [addTrainingToClass, { loading: addTrainingLoading }] = useMutation(ADD_TRAINING_TO_CLASS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ addTrainingToClass }) => {
      if (addTrainingToClass > 0) {
        enqueueSnackbar(`${addTrainingToClass} student(s) were assigned the training module.`, {
          variant: 'success'
        });
        setTraining(null);
        setOrder(0);
        setTrainingDialogOpen(false);
      } else {
        enqueueSnackbar("Failed to assign the training module to any students.", {
          variant: 'error'
        });
      }
    }
  });

  const [setCurrentWeek, { loading: weekLoading }] = useMutation(SET_CURRENT_WEEK, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ setCurrentWeek }) => {
      if (setCurrentWeek) {
        enqueueSnackbar("Week successfully changed.", {
          variant: 'success'
        });
      }
    }
  })

  const [getTeacherClasses] = useLazyQuery(GET_TEACHER_CLASSES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getTeacherClasses }) => {
      if (getTeacherClasses?.length > 0) {
        setTrainingClasses(getTeacherClasses);
        if (Number(sessionStorage.getItem("class"))) {
          setSelectedClass(getTeacherClasses.find(c => c.id === Number(sessionStorage.getItem("class"))));
        }
      }
    }
  });

  const [getAllocatedTrainingsByCourse] = useLazyQuery(GET_ALLOCATED_TRAININGS_BY_COURSE, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAllocatedTrainingsByCourse }) => {
      if (getAllocatedTrainingsByCourse?.length > 0) {
        setTrainings(getAllocatedTrainingsByCourse);
      }
    }
  });

  const [getAllocatedStudentsByTeacher, { data: getAllocatedStudentsByTeacherData, loading: getAllocatedStudentsByTeacherLoading }] = useLazyQuery(GET_ALLOCATED_STUDENTS_BY_TEACHER, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAllocatedStudentsByTeacher }) => {
      const newRows = getAllocatedStudentsByTeacher.map(i => {
        return {
          id: i.id,
          name: i.givenname + " " + i.surname,
          username: i.login,
          raisehand: i.raiseHand,
          onschedule: i.Assessments?.reduce((acc, curr) => {
            if (curr) {
              return !curr.isCompleted ? getTime(curr?.dueDate) <= Date.now() ? acc + 1 : acc : acc
            }
          }, 0),
          performance: i.Assessments?.reduce((acc, curr) => {
            if (curr.Sittings) {
              return ((curr.Sittings[0]?.score.split('/')[0] / curr.Sittings[0]?.score.split('/')[1]) * 100).toFixed(0) < 60 ? acc + 1 : acc
            }
          }, 0),
          numOfModule: i.Trainings?.length,
          numOfFinishedModule: i.Trainings?.reduce((acc, curr) => {
            if (curr.assessmentId) {
              const assessment = i.Assessments?.find(a => a.id === curr.assessmentId);
              if (assessment.isCompleted) return acc + 1;
            } else {
              if (curr.isCompleted) return acc + 1;
            }            
            return acc;
          }, 0),
          numOfUnfinishedModule:i.Trainings?.reduce((acc, curr) => {
            if (curr.assessmentId) {
              const assessment = i.Assessments?.find(a => a.id === curr.assessmentId);
              if (!(assessment.isCompleted)) return acc + 1;
            } else {
              if (!(curr.isCompleted)) return acc + 1;
            }            
            return acc;
          }, 0)
        }
      });
      sessionStorage.setItem("class", selectedClass?.id);
      setRows(newRows);
    },
    onError: error => enqueueSnackbar(`${error}`, { variant: "error" })
  });

  useEffect(() => {
    if (user) {
      getTeacherClasses({ variables: { teacher_id: Number(JSON.parse(user).staff_id) } });
    }
  }, [user]);

  useEffect(() => {
    if (selectedClass) {
      getAllocatedStudentsByTeacher({ variables: { teacher_id: Number(JSON.parse(user).staff_id), class_id: selectedClass.id }});
      getAllocatedTrainingsByCourse({ variables: { course_id: selectedClass.courseId }});
      setWeek(selectedClass.currentWeek);
    }
  }, [selectedClass]);

  const viewStudentProgress = (params) => {
    if (getAllocatedStudentsByTeacherData) {
      const findStudentData = getAllocatedStudentsByTeacherData.getAllocatedStudentsByTeacher.find(i => i.id === params.row.id)
      if (!findStudentData) return console.log('Can not find student')
      setStudentMonitoringData(JSON.parse(JSON.stringify(findStudentData)))
      history.push(`/classmanagementteacher/${params.row.id}`)
    }
  }

  const handleRaiseHandClick = (params) => toggleRaiseHand({ variables: { student_id: params?.id }});

  const createTraining = () => {
    if (training && order) {
      addTrainingToClass({
        variables: {
          class_id: selectedClass.id,
          training_id: training.id,
          order
        }
      })
    } else {
      if (!training && !order) {
        enqueueSnackbar("Please select a training module and week to assign.", {
          variant: "error"
        });
      } else if (!training) {
        enqueueSnackbar("Please select a training module to assign.", {
          variant: "error"
        });
      } else {
        enqueueSnackbar("Please select a week to assign.", {
          variant: "error"
        });
      }
    }
  }
  
  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true,
        headerName: "Actions",
        width: 100,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            color="primary"
            onClick={() => viewStudentProgress(params)}
          />,
        ],
      },
      {
        field: "username",
        headerName: "Student Name",
        width: 140,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "name",
        headerName: "Student Name",
        width: 200,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "raisehand",
        headerName: "Raise Hand",
        width: 140,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          if (params.row.raisehand) {
            return <IconButton size="small" onClick={() => handleRaiseHandClick(params)} color="primary" aria-label="add to shopping cart">
                <PanToolRoundedIcon fontSize="inherit" />
              </IconButton>
          } else return ""
        },
        filterable: false
      },
      {
        field: "onschedule",
        headerName: "On Schedule",
        width: 140,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          if (params.row.onschedule >= 2) {
            return <Box sx={{ height: 15, width: 15, background: "crimson", borderRadius: 2 }} />;
          } else if (params.row.onschedule === 1) {
            return <Box sx={{ height: 15, width: 15, background: "orange", borderRadius: 2 }} />;
          } else {
            return <Box sx={{ height: 15, width: 15, background: "limegreen", borderRadius: 2 }} />;
          }
        },
        filterable: false,
        hide: true
      },
      {
        field: "performance",
        headerName: "Performance",
        width: 140,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          if (params.row.performance >= 2) {
            return <Box sx={{ height: 15, width: 15, background: "crimson", borderRadius: 2 }} />;
          } else if (params.row.performance === 1) {
            return <Box sx={{ height: 15, width: 15, background: "orange", borderRadius: 2 }} />;
          } else {
            return <Box sx={{ height: 15, width: 15, background: "limegreen", borderRadius: 2 }} />;
          }
        },
        filterable: false
      },
      // {
      //   field: "isAllocated",
      //   headerName: "Is Allocated",
      //   width: classMgmtPref?.widths?.isAllocated,
      //   headerAlign: "center",
      //   align: "center",
      //   hide: !classMgmtPref?.visible?.isAllocated,
      //   renderCell: (params) => {
      //     if (params.row.isAllocated) {
      //       return <CheckIcon color="primary" />;
      //     } else {
      //       return <CloseIcon color="primary" />;
      //     }
      //   },
      // },
      {
        field: "numOfModule",
        headerName: "# of Modules",
        width: 120,
        headerAlign: "center",
        align: "center",
        type: "number"
      },
      {
        field: "numOfFinishedModule",
        headerName: "# of Finished Modules",
        width: 180,
        headerAlign: "center",
        align: "center",
        type: "number"
      },
      {
        field: "numOfUnfinishedModule",
        headerName: "# of Unfinished Modules",
        width: 200,
        headerAlign: "center",
        align: "center",
        type: "number"
      },
    ],
    [viewStudentProgress, handleRaiseHandClick]
  );

  return (
    <>
      <Dialog open={openInfo}>
        <DialogTitle align="center">Interpreting the Statuses</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Raise Hand</Typography>
          <Typography variant="body2">If a student requires assistance with something, they will click the Raise Hand button. This will show a 
            <IconButton size="small" color="primary" aria-label="add to shopping cart">
              <PanToolRoundedIcon fontSize="inherit" />
            </IconButton>
          </Typography>
          <Typography variant="h6">On Schedule</Typography>
          <Typography variant="body2">Shows whether the student is keeping up with their training:<br />
            <Box sx={{ height: 15, width: 15, background: "crimson", borderRadius: 2 , display: "inline-flex"}} /> 2 or more training modules are overdue<br />
            <Box sx={{ height: 15, width: 15, background: "orange", borderRadius: 2 , display: "inline-flex"}} /> 1 training module is overdue<br />
            <Box sx={{ height: 15, width: 15, background: "limegreen", borderRadius: 2 , display: "inline-flex"}} /> No training modules are overdue
          </Typography>
          <Typography variant="h6">Performance</Typography>
          <Typography variant="body2">Shows whether the student is performing well in their training assessments:<br />
            <Box sx={{ height: 15, width: 15, background: "crimson", borderRadius: 2 , display: "inline-flex"}} /> 2 or more training assessments have a score under 60%<br />
            <Box sx={{ height: 15, width: 15, background: "orange", borderRadius: 2 , display: "inline-flex"}} /> 1 training assessment has a score under 60%<br />
            <Box sx={{ height: 15, width: 15, background: "limegreen", borderRadius: 2 , display: "inline-flex"}} /> No training assessments have a score under 60%
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Box />
          <Button autoFocus onClick={() => setOpenInfo(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        open={trainingDialogOpen} onClose={() => setTrainingDialogOpen(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle align="center">Add Training</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              mb: 3,
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <Autocomplete
              name="training"
              value={training}
              options={trainings}
              onChange={(e, options) => setTraining(options)}
              getOptionLabel={(option) => 
                option.id ? `${option.name}`: ""
              }
              renderOption={(props, option) => 
                <li {...props} key={option.id}>
                  {option.id ? `${option.name}`: ""}
                </li>
              }
              sx={{ width: "100%", pr: 5 }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <>
                  <body1>Select Training</body1>
                  <TextField
                    {...params}
                    placeholder="Select Training"
                  />
                </>
              )}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                flexDirection: "column"
              }}
            >
              <body1>Week</body1>
              <TextField
                value={order}
                onChange={e => setOrder(Number(e.target.value))}
                sx={{ width: "100%" }}
              />
            </Box>
            
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={() => setTrainingDialogOpen(false)}>Close</Button>
          <Button onClick={() => createTraining()}>Add Training</Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
        <Paper elevation={0} sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              mb: 3,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
              Class Management
            </Typography>
            {/* {getAllocatedStudentsByTeacherData && (<>
              <IconButton color="primary" onClick={() => setOpenInfo(true)}><InfoIcon /></IconButton>
              <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
                {`Total Student Number: ${getAllocatedStudentsByTeacherData?.getAllocatedStudentsByTeacher?.length}`}
              </Typography>
            </>)} */}
          </Box>
          <Box
            sx={{
              display: "flex",
              mb: 3,
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <Autocomplete
              name="trainingClass"
              value={selectedClass}
              options={trainingClasses}
              onChange={(e, options) => setSelectedClass(options)}
              getOptionLabel={(option) => 
                option.id ? `${option.name}`: ""
              }
              renderOption={(props, option) => 
                <li {...props} key={option.id}>
                  {option.id ? `${option.name}`: ""}
                </li>
              }
              sx={{ width: "100%" }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Select Class"
                  placeholder="Select Class"
                />
              )}
            />
          </Box>
          { selectedClass && (
              <Box
                sx={{
                  display: "flex",
                  mb: 3,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      mr: 3
                    }}
                  >
                    <Typography variant="body2" color="primary">Current Week: </Typography>
                    <TextField
                      value={week}
                      size="small"
                      onChange={e => setWeek(Number(e.target.value))}
                      sx={{ width: "100%" }}
                    />
                  </Box>
                  <Button sx={{ mt: 2 }} variant="contained" onClick={() => setCurrentWeek({ variables: { class_id: selectedClass.id, week } })}>Set Current Week</Button>
                </Box>
                <Button sx={{ mt: 2 }} variant="contained" onClick={() => setTrainingDialogOpen(true)}>Assign Training to Class</Button>
              </Box>
          )}
          <DataGridPro
            getRowId={(row) => row.id}
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableSelectionOnClick
            autoHeight
            loading={getAllocatedStudentsByTeacherLoading || toggleRaiseHandLoading}
            pagination={true}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
            pinnedColumns={{ left: ["actions"] }}
          />
        </Paper>
      </Container>
    </>
  );
};

export default ClassManagementTeacher;