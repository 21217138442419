import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Registrations from "../components/Registrations";
import Sittings from "../components/Sittings";
import ModuleUpdateDetails from "../components/ModuleUpdateDetails";

const TrainingDetailManagement = () => {
  const { id } = useParams();
  const history = useHistory();
  console.log(history.location.state?.data);

  return (
    <Box
    sx={{backgroundColor: "white"}}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="test-details-1"
        >
          <Typography>Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ModuleUpdateDetails moduleId={Number(id)} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="training-sittings-1"
        >
          <Typography>Student Training Sittings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Sittings moduleType="Exercise" moduleId={Number(id)} />
        </AccordionDetails>
      </Accordion>
      {
        history.location.state?.data.assessmentId && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              id="assessment-sittings-1"
            >
              <Typography>Student Assessment Sittings</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Sittings moduleType="Assessment" moduleId={Number(id)} />
            </AccordionDetails>
          </Accordion>
        )
      }
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="test-enrolment-1"
        >
          <Typography>Registrations</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Registrations moduleType="Exercise" moduleId={Number(id)} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TrainingDetailManagement;
