import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import logo from "../assets/SolverEDGE-Main.png";
import nscclogo from "../assets/NSCC_logo.png";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AuthenticatedContext } from "../App";
import jwt_decode from "jwt-decode";
import { LOGIN_STAFF, LOGIN_STUDENT } from "../graphql/mutations";

const schema = yup.object({
  username: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

export default function SignIn() {
  const isStudent = new RegExp('^\\d{8}$');
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { loginSuccess, logoutSuccess } = useContext(AuthenticatedContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const [loginStaff] = useMutation(LOGIN_STAFF, {
    onCompleted: ({ staff_login }) => {
      if (staff_login.status_id === 1) {
        const decoded = jwt_decode(staff_login.token);
        loginSuccess(decoded.role_id.name, decoded, staff_login.token);
        sessionStorage.setItem("role", decoded.role_id.name);
        sessionStorage.setItem("user", JSON.stringify(decoded));
        sessionStorage.setItem("auth", "true");
        sessionStorage.setItem("token", staff_login.token);
        history.push("/dashboard");
      } else {
        enqueueSnackbar("Incorrect username or password. Please try again.", {
          variant: "error",
        })
      }
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, {
        variant: "error",
      });
      logoutSuccess();
      sessionStorage.removeItem("role");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("auth");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("studentMonitoring");
      history.push("/");
    },
    fetchPolicy: "network-only",
  });

  const [loginStudent] = useMutation(LOGIN_STUDENT, {
    onCompleted: ({ student_login }) => {
      if (student_login.status_id === 1) {
        const decoded = jwt_decode(student_login.token);
        loginSuccess("Student", decoded, student_login.token);
        sessionStorage.setItem("role", "Student");
        sessionStorage.setItem("user", JSON.stringify(decoded));
        sessionStorage.setItem("auth", "true");
        sessionStorage.setItem("token", student_login.token);
        history.push("/dashboard");
      } else {
        enqueueSnackbar("Incorrect username or password. Please try again.", {
          variant: "error",
        })
      }
      
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, {
        variant: "error",
      });
      logoutSuccess();
      sessionStorage.removeItem("role");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("auth");
      sessionStorage.removeItem("token");
      history.push("/");
    },
    fetchPolicy: "network-only",
  });

  const onSubmit = ({ username, password }) => {
    if (isStudent.test(username)) {
      loginStudent({ variables: { student_login: username, student_password: password } });
    } else {
      loginStaff({ variables: { staff_login: username, staff_password: password } });
    }
  };

  React.useEffect(() => {
    sessionStorage.removeItem("placementsDone");
  }, []);

  return (
    <Container
      maxWidth="100vw"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CssBaseline />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          overflow: "auto",
          minHeight: "93vh",
          maxWidth: "50vw",
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: "20vh",
            maxWidth: "50vw",
          }}
        >
          <img
            src={logo}
            alt="SolverEDGE Logo"
            style={{ height: "250px", marginBottom: "4px" }}
          />
          <img
            src={nscclogo}
            alt="NSCC Logo"
            style={{ height: "150px", marginBottom: "4px" }}
          />
        </Stack>
        <Typography
          textAlign="center"
          gutterBottom
          variant="h6"
          sx={{ mt: 1, mb: 1 }}
        >
          SolverEdge Problem Solving Platform
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 3, maxWidth: "40vh" }}
        >
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={errors.username ? true : false}
                sx={{ mb: 1 }}
              >
                <InputLabel>User Name</InputLabel>
                <OutlinedInput {...field} label="User Name" autoFocus />
                <FormHelperText sx={{ color: "primary.main" }}>
                  {errors.username?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={errors.password ? true : false}
              >
                <InputLabel>Password</InputLabel>
                <OutlinedInput {...field} label="Password" type="password" />
                <FormHelperText sx={{ color: "primary.main" }}>
                  {errors.password?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Stack>
    </Container>
  );
}
