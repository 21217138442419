import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  Cell,
  ReferenceDot
} from "recharts";
import React, { useCallback, useEffect, useMemo, useState } from "react";

const getPath = (x, y) => {
  return `M${x - 7.5},${y - 14}H${x + 7.5}L${x},${y + 1}Z`;
};

const TriangleBar = (props) => {
  const { cx, cy } = props;

  return <path d={getPath(cx, cy)} stroke="#000000" fill="#000000" />;
};

const BarGraph = ({ data, student_name }) => {
  const bars = data.map(s => ({
    name: s.name,
    50: 50,
    25: 25,
    15: 15,
    10: 10,
  }));
  let highlights = [[], [], [], []];
  const arrows = data.map((s, i) => {
    if (s.score < 50) {
      highlights[0].push(i);
    } else if (s.score < 75) {
      highlights[1].push(i);
    } else if (s.score < 90) {
      highlights[2].push(i);
    } else {
      highlights[3].push(i);
    }

    // const gap = data.length === 1 ? 0 : 2.4 - (data.length * 0.3);
    // // 3: 1.5, 4: 1.2,
    // const y = (i * (100/data.length)) + 18 / data.length + gap;
    const gap = data.length === 1 ? -5 : 0.9;
    const y = (i * (100 / data.length)) + 18 / data.length + gap;

    return (
      <ReferenceDot
        x={s.score}
        y={y}
        yAxisId="fake"
        stroke="green"
        strokeWidth={5}
        isFront={true}
        shape={<TriangleBar />}
      />
    )
  });

  const CustomText = (props) => {
    const { x, y, stroke } = props;
    let value;
    let value2;
    let strand = data.find(s => s.name === props.name);
    
    if (strand.score < 50) {
      value = `${student_name}'s ${strand.name} ability was in the lowest 50% of students who sat the test.`;
      value2 = "Half of all students who sat the test performed in this range.";
    } else if (strand.score < 75) {
      if (!strand.isDefault) {
        value = `${student_name}'s ${strand.name} ability performed as well as, or better than, 50% of students who sat`;
        value2 = "the test.";
      } else {
        value = `There was not enough data to determine ${student_name}'s ${strand.name} ability. As a result`;
        value2 = `${student_name}'s ability was assumed to be average.`;
      }
      
    } else if (strand.score < 90) {
      value = `${student_name}'s ${strand.name} ability performed in the top 25% of students who sat the test.`
    } else {
      value = `${student_name}'s ${strand.name} ability performed in the top 10% of students who sat the test.`
    }
    
    const newy = data.length === 1 ? y + 82 : y + 90;
    return (
      <>
        <text x={x + 5} y={newy} fill={stroke} fontSize={13} textAnchor="left">
          {value}
        </text>
        <text x={x + 5} y={newy + 13} fill={stroke} fontSize={13} textAnchor="left">
          {value2}
        </text>
      </>
    );
  };

  return (
    <BarChart
      width={1000}
      height={(70 * bars.length) + 70 * (bars.length - 1) + 70}
      data={bars}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 20
      }}
      layout="vertical"
      barCategoryGap={25}
    >
      <XAxis type="number" hide />
      <YAxis type="category" dataKey="name" width={200} />
      <YAxis yAxisId="fake" dataKey="unused" domain={[0, 100]} type="number" hide />
      <Bar
        isAnimationActive={false}
        dataKey="50"
        stackId="a"
        fill="#bebebe"
        barSize={70}
        label={
          <CustomText
            style={{
              fontSize: 12,
              fontFamily: "sans-serif"
            }}
          />
        }
      >
        <LabelList
          dataKey="50"
          fill="#000000"
          position="center"
          formatter={(value) => {
            return "50% of students who sat the test";
          }}
          style={{
            fontSize: 12
          }}
        />
        {data.map((d, i) => (
          <Cell
            stroke="#000000"
            strokeWidth={1}
            fill={highlights[0].includes(i) ? "#ffffff" : "#bebebe"}
          />
        ))}
      </Bar>
      <Bar 
        isAnimationActive={false}
        dataKey="25" 
        stackId="a" 
        fill="#bebebe"
        barSize={20}
      >
        <LabelList
          dataKey="25"
          fill="#000000"
          position="center"
          formatter={(value) => {
            return "25% of students who sat the test";
          }}
          style={{ fontSize: 12 }}
        />
        {data.map((d, i) => (
          <Cell
            stroke="#000000"
            strokeWidth={1}
            fill={highlights[1].includes(i) ? "#ffffff" : "#bebebe"}
          />
        ))}
      </Bar>
      <Bar 
        isAnimationActive={false}
        dataKey="15" 
        stackId="a" 
        fill="#bebebe"
        barSize={20}
      >
        <LabelList
          dataKey="15"
          fill="#000000"
          position="center"
          formatter={(value) => {
            return "15% of students who sat the test";
          }}
          style={{ fontSize: 12 }}
        />
        {data.map((d, i) => (
          <Cell
            stroke="#000000"
            strokeWidth={1}
            fill={highlights[2].includes(i) ? "#ffffff" : "#bebebe"}
          />
        ))}
      </Bar>
      <Bar 
        isAnimationActive={false}
        dataKey="10" 
        stackId="a" 
        fill="#bebebe"
        barSize={60}
      >
        <LabelList
          dataKey="10"
          fill="#000000"
          position="center"
          formatter={(value) => {
            return "10% of students who sat the test";
          }}
          style={{ fontSize: 12 }}
        />
        {data.map((d, i) => (
          <Cell
            stroke="#000000"
            strokeWidth={1}
            fill={highlights[3].includes(i) ? "#ffffff" : "#bebebe"}
          />
        ))}
      </Bar>
      {arrows}
    </BarChart>
  );
};

export default BarGraph;
