import React from "react";
import {
  Badge,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { ModuleContext } from "../App";
import Flag from "@mui/icons-material/Flag";
import LockIcon from '@mui/icons-material/Lock';
import { Box } from "@mui/system";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TestReviewSection = () => {
  const { moduleSettings, goToQuestion } = React.useContext(ModuleContext);

  const getButtons = () => {
    if (moduleSettings.reviews?.length > 0) {
      if (moduleSettings.moduleType === "Assessment") {
        return moduleSettings.reviews.map((r) => {
          let answer = moduleSettings.answers?.find(
            (a) => a.questionId === r.questionId
            );

          let color, background

          if (answer) {
            if (!answer.isCorrect && answer.attempt === 1) {
              color = "#fccc0c";
              background = "darkgoldenrod"
            } else if (answer.isCorrect && answer.attempt > 1) {
              color = "#f76911";
              background = "#b54e0e"
            } else if (!answer.isCorrect && answer.attempt > 1) {
              color = "#d32f2f";
              background = "#821e1e"
            } else {
              color = "#21ba45"
              background = "darkgreen"
            }
          }

          // if (disabled) {
          //   return (
          //     <Badge
          //       key={`${r.buttonNumber}-badge`}
          //       badgeContent={
          //         <LockIcon sx={{ color: "white", fontSize: "small" }} />
          //       }
          //       sx={{
          //         "& .MuiBadge-badge": {
          //           backgroundColor: "#808080",
          //         },
          //         ml: 1,
          //         mr: 1,
          //       }}
          //     >
          //       <Button
          //         variant="contained"
          //         key={r.buttonNumber}
          //         // color={color}
          //         disabled
          //         sx={{
          //           "&:disabled": {
          //             backgroundColor: "#21ba45",
          //             color: "white",
          //           },
          //           mb: 2,
          //         }}
          //       >
          //         {r.buttonNumber}
          //       </Button>
          //     </Badge>
          //   );
          // } else {
          return (
            <Button
              variant="contained"
              key={r.buttonNumber}
              onClick={() => goToQuestion(r.buttonNumber)}
              sx={{ ml: 1, mr: 1, mb: 2, background: `${color}`, "&:hover":  { backgroundColor: `${background}`, color: "white" }}}
            >
              {r.buttonNumber}
            </Button>
          )
          // }
        })
      }
      if (moduleSettings.moduleType === "Training") {
        let sections = [];
        for (let i = 0; i < moduleSettings.totalParts; i++) {
          sections.push(i+1);
        }
        console.log(sections);
        return (
          <>
            { sections.map(s => {
              const sectionReviews = moduleSettings.reviews.filter(r => r.sectionNumber === s);
              return (
                <>
                  <Typography>{sectionReviews[0]?.section}</Typography>
                  {
                    sectionReviews.map((r) => {
                      let answer = moduleSettings.answers?.find(
                        (a) => a.questionId === r.questionId
                      );
                      let colour = "success";
                      
                      if (!answer.isCorrect) {
                        colour = "error";
                      }
                      
                      return (
                        <Button
                          variant="contained"
                          key={r.buttonNumber}
                          onClick={() => goToQuestion(r.buttonNumber)}
                          sx={{ ml: 1, mr: 1, mb: 2 }}
                          color={colour}
                        >
                          {r.buttonNumber}
                        </Button>
                      );
                    })
                  }
                </>
              )
            }) }
          </>
        );
      }
      
      return moduleSettings.reviews.map((r) => {
        let answer = moduleSettings.answers?.find(
          (a) => a.questionId === r.questionId
        );
        let colour = "success";
        let disabled = true;
        
        if (!answer.isCorrect) {
          colour = "error";
          disabled = false;
        }
        if (disabled) {
          return (
            <Badge
              key={`${r.buttonNumber}-badge`}
              badgeContent={
                <LockIcon sx={{ color: "white", fontSize: "small" }} />
              }
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "#808080",
                },
                ml: 1,
                mr: 1,
              }}
            >
              <Button
                variant="contained"
                key={r.buttonNumber}
                color={colour}
                disabled
                sx={{
                  "&:disabled": {
                    backgroundColor: "#21ba45",
                    color: "white",
                  },
                  mb: 2,
                }}
              >
                {r.buttonNumber}
              </Button>
            </Badge>
          );
        } else {
          return (
            <Button
              variant="contained"
              key={r.buttonNumber}
              onClick={() => goToQuestion(r.buttonNumber)}
              sx={{ ml: 1, mr: 1, mb: 2 }}
              color={colour}
            >
              {r.buttonNumber}
            </Button>
          );
        }
      });
    } else {
      if (moduleSettings.selectedModule.isAdaptive) {
        return moduleSettings.route.map((r, i) => {
          let answer = moduleSettings.answers.find(
            (a) => a.questionId === r.id
          );
          let colour = "#9e9e9e";
          let disabled = true;
          let currentNumber = i + 1;
          if (moduleSettings.numStart > 0) {
            if (currentNumber > moduleSettings.totalQuestionNumber)
              disabled = true;
          }
          if (answer) {
            disabled = false;
            if (answer.chosen) {
              colour = "#fdd835";
            }
          }
          return (
            <Button
              variant="contained"
              key={currentNumber}
              disabled={disabled}
              onClick={() => goToQuestion(currentNumber)}
              sx={{
                "&:hover": {
                  backgroundColor: "#d7d7d7",
                },
                backgroundColor: colour,
                ml: 1,
                mr: 1,
                mb: 2,
              }}
            >
              {moduleSettings.flags.includes(r.id) === true && <Flag />}
              {currentNumber}
            </Button>
          );
        });
      } else {
        return moduleSettings.questions.map((q, i) => {
          let answer = moduleSettings.answers.find(
            (a) => a.questionId === q.id
          );
          let colour = "#9e9e9e";
          let disabled = false;
          let currentNumber = i + 1;
          if (answer) {
            if (answer.chosen) {
              colour = "#fdd835";
            }
          }
          return (
            <Button
              variant="contained"
              key={currentNumber}
              disabled={disabled}
              onClick={() => goToQuestion(currentNumber)}
              sx={{
                "&:hover": {
                  backgroundColor: "#d7d7d7",
                },
                backgroundColor: colour,
                ml: 1,
                mr: 1,
                mb: 2,
              }}
            >
              {moduleSettings.flags.includes(q.id) === true && <Flag />}
              {currentNumber}
            </Button>
          );
        });
      }
    }
  };

  const getText = () => {
    if (moduleSettings.reviews?.length > 0) {
      if (moduleSettings.moduleType === "Assessment") {
        return (
          <Box sx={{ backgroundColor: "#eeeeee", margin: 2, padding: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={0.5} sx={{ ml: 1.2, mt: 1.6 }}>
                <InfoOutlinedIcon fontSize={"large"} />
              </Grid>
              <Grid item xs={11.2} sx={{ mb: 1 }}>
                View the questions that you have answered incorrectly.
                <Divider sx={{ mt: 1, mb: 1 }} />
                The colour coded boxes labelled with each test question number indicates the status of each test question.
              </Grid>

              <Grid item xs={0.5} sx={{ ml: 1.2}} />
              <Grid
                item
                xs={1}
                sx={{ mt: 1.2, ml: 1, mr: 1, height: "42px", borderRadius: 1, color: "white", backgroundColor: "#21ba45", textAlign: "center" }}
              >
                Green
              </Grid>
              <Grid item xs={1}>1st attempt correct answer</Grid>
              <Grid item xs={0.4} />

              <Grid
                item
                xs={1}
                sx={{ mt: 1.2, mr: 1, height: "42px", borderRadius: 1, color: "white", backgroundColor: "#fccc0c", textAlign: "center" }}
              >
                Yellow
              </Grid>
              <Grid item xs={1.2}>1st attempt incorrect answer</Grid>
              <Grid item xs={0.4} />

              <Grid
                item
                xs={1}
                sx={{ mt: 1.2, mr: 1, height: "42px", borderRadius: 1, color: "white", backgroundColor: "#f76911", textAlign: "center" }}
              >
                Orange
              </Grid>
              <Grid item xs={1}>2nd attempt correct answer</Grid>
              <Grid item xs={0.4} />

              <Grid
                item
                xs={1}
                sx={{ mt: 1.2, mr: 1, height: "42px", borderRadius: 1, color: "white", backgroundColor: "#d32f2f", textAlign: "center" }}
              >
                Red
              </Grid>
              <Grid item xs={1.2}>2nd attempt incorrect answer</Grid>
            </Grid>
          </Box>
        )
      }
      return (
        <Box sx={{ backgroundColor: "#eeeeee", margin: 2, padding: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <InfoOutlinedIcon fontSize={"large"} />
            </Grid>
            <Grid item xs={11} sx={{ mb: 1.5 }}>
              View the questions that you have answered incorrectly.
              <Divider sx={{ mt: 1, mb: 1 }} />
              The colour coded boxes labelled with each test question number
              indicates the status of each test question.
            </Grid>
            <Grid item xs={1} />
            <Grid
              item
              xs={2}
              sx={{
                ml: 1,
                mr: 1,
                p: 1,
                color: "white",
                backgroundColor: "#21ba45",
                textAlign: "center",
              }}
            >
              Green
            </Grid>
            <Grid item xs={2}>
              Correct answer
            </Grid>
            <Grid item xs={1} />
            <Grid
              item
              xs={2}
              sx={{
                ml: 1,
                mr: 1,
                p: 1,
                color: "white",
                backgroundColor: "#d32f2f",
                textAlign: "center",
              }}
            >
              Red
            </Grid>
            <Grid item xs={2}>
              Incorrect answer
            </Grid>
          </Grid>
        </Box>
      );
    } else {
      return (
        <Box sx={{ backgroundColor: "#eeeeee", margin: 2, padding: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <InfoOutlinedIcon fontSize={"large"} />
            </Grid>
            <Grid item xs={11}>
              To check your answers, click a question number below. If you are
              ready to submit the ..., click 'Finish ...'.
              <Divider sx={{ mt: 1, mb: 1 }} />
              The colour coded boxes labelled with each test question number
              indicates the status of each test question.
            </Grid>
            <Grid item xs={1} />
            <Grid
              item
              xs={2}
              sx={{
                ml: 1,
                color: "black",
                backgroundColor: "#9e9e9e",
                textAlign: "center",
              }}
            >
              Grey
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">
                Not answered / Skipped question
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid
              item
              xs={2}
              sx={{
                ml: 1,
                color: "black",
                backgroundColor: "#fdd835",
                textAlign: "center",
              }}
            >
              Yellow
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">Answered question</Typography>
            </Grid>
          </Grid>
        </Box>
      );
    }
  };

  return (
    <Paper elevation={0} sx={{ ml: 2, mr: 2, mt: 2, p: 1 }}>
      <div>{getText()}</div>
      <br />
      <Box sx={{ px: 1 }}>{getButtons()}</Box>
    </Paper>
  );
};

export default TestReviewSection;
