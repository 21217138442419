import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Registrations from "../components/Registrations";
import Sittings from "../components/Sittings";
import ModuleUpdateDetails from "../components/ModuleUpdateDetails";

const TestDetailManagement = () => {
  const { moduleTypeId, id } = useParams();

  const moduleTypes = {
    1: "Placement",
    2: "Progression",
    3: "Exercise",
  };

  return (
    <Box sx={{backgroundColor: "white"}}>
      { (moduleTypeId && id) && (
        <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              id="test-details-1"
            >
              <Typography>Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ModuleUpdateDetails moduleId={Number(id)} />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              id="test-sittings-1"
            >
              <Typography>Student Sittings</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Sittings moduleType={moduleTypes[`${moduleTypeId}`]} moduleId={Number(id)} />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              id="test-enrolment-1"
            >
              <Typography>Registrations</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Registrations moduleType={moduleTypes[`${moduleTypeId}`]} moduleId={Number(id)} />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Box>
  );
};

export default TestDetailManagement;
