import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tabs,
  Tab,
  Box,
  Typography,
  Stack,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Select,
  MenuItem,
  Autocomplete,
  TextField
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { DataGridPro, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { PreferenceContext } from "../App";
import { useSnackbar } from "notistack";
import { GET_ALLOCATED_TRAININGS_BY_COURSE, GET_CENTRES, GET_COURSE_CURRICULA, GET_COURSE_REGISTRATIONS, GET_CURRICULUM_TRAININGS, GET_EASY_COURSES, GET_EASY_TERMS, GET_GRADES, GET_NSDC_GRADES, GET_PROGRAM_REGISTRATIONS, GET_REGIONS, GET_SITTINGS, GET_STUDENT_SITTINGS } from "../graphql/queries";
import { CREATE_COURSE_CURRICULUM, CREATE_COURSE_REGISTRATION, CREATE_PROGRAM_REGISTRATION, DELETE_COURSE_CURRICULUM, DELETE_COURSE_REGISTRATION, DELETE_PROGRAM_REGISTRATION, DELETE_SITTING, MAP_CURRICULUM_TRAINING } from "../graphql/mutations";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import CurriculumWeek from "../components/CurriculumWeek";

const Curriculum = () => {
  const { id } = useParams();
  const { preferences, setPreferences, defaults } = React.useContext(PreferenceContext);
  // let programPref = {...preferences.progReg};
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  console.log(history.location.state?.data);
  const [selectedTrainings, setSelectedTrainings] = useState([]);
  // const [openCC, setOpenCC] = useState(false);
  const [unselectedTrainings, setUnselectedTrainings] = useState([]);
  const [trainingList, setTrainingList] = useState([]);
  // const [openDel, setOpenDel] = useState(false);
  // const [chosenCurriculum, setChosenCurriculum] = useState(null);
  const sortTraining = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }

  useEffect(() => {
    console.log(unselectedTrainings);
  }, [unselectedTrainings]);

  useEffect(() => {
    let pickedTrainings = [];
    selectedTrainings.map(t => pickedTrainings.push(...t));
    setUnselectedTrainings(trainingList.filter(t => !pickedTrainings.find(ct => ct.trainingId === t.id)));
  }, [selectedTrainings]);

  const [getCurriculumTrainings, { regLoading, regError, regData }] = useLazyQuery(GET_CURRICULUM_TRAININGS, {
    onCompleted: ({ getCurriculumTrainings }) => {
      let trainingArray = [];
      for (let i = 0; i < history.location.state?.data.numWeeks; i++) {
        trainingArray.push(getCurriculumTrainings.filter(c => c.order === i).map(item => {
          const copyItem = {...item}
          if (copyItem.curriculumId) delete copyItem.curriculumId;
          if (copyItem.__typename) delete copyItem.__typename;
          if (copyItem.order !== null) delete copyItem.order;
          return copyItem;
        }));
      }
      setSelectedTrainings([...trainingArray]);
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [getAllocatedTrainingsByCourse, { trainLoading, trainError, trainData }] = useLazyQuery(GET_ALLOCATED_TRAININGS_BY_COURSE, {
    onCompleted: ({ getAllocatedTrainingsByCourse }) => {
      console.log(getAllocatedTrainingsByCourse);
      const toSort = [...getAllocatedTrainingsByCourse].sort(sortTraining);
      setTrainingList([...toSort]);
      getCurriculumTrainings({
        variables: {
          curriculumId: Number(id)
        }
      });
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [mapCurriculumTraining] = useMutation(MAP_CURRICULUM_TRAINING, {
    onCompleted: ({ mapCurriculumTraining }) => {
      if (mapCurriculumTraining) {
        enqueueSnackbar(
          `Curriculum successfully updated`,
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(`There was a problem with updating the curriculum, please try again.`,
          {
            variant: "warning",
          }
        );
      }
    },
    onError: (error) => {
      console.log(error);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getAllocatedTrainingsByCourse({
      variables: {
        course_id: history.location.state?.data.courseId
      }
    })
  }, [id]);

  function setWeekTrainings(index, trainings) {
    const newSelectedTrainings = [...selectedTrainings];
    newSelectedTrainings.splice(index, 1, trainings);
    setSelectedTrainings([...newSelectedTrainings]);
    mapCurriculumTraining({
      variables: {
        curriculumId: Number(id),
        trainings,
        order: index
      }
    });
  };

  return (
    <>
      <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
        <Paper elevation={0} sx={{ p: 3, minHeight: "100%" }}>
            <Typography
              color="primary"
              gutterBottom
              variant="h6"
              sx={{ mb: 2 }}
            >
              {history.location.state?.data.Course.name} {history.location.state?.data.Grade.name} Curriculum
            </Typography>
            {
              selectedTrainings.length > 0 && selectedTrainings.map((t, i) => {
                console.log(t);
                return (
                  <CurriculumWeek index={i} weekTrainings={t} unselectedTrainings={unselectedTrainings} setWeekTrainings={setWeekTrainings} />
                )
              })
            }
        </Paper>
      </Container>
    </>
  );
};

export default Curriculum;
