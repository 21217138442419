import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Radar,
  RadialBarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  RadialBar,
} from "recharts";

const RadarGraph = ({ circleSize, data, fill, type }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6" component="div" textAlign="center">
        { type }
      </Typography>
      <RadialBarChart
        width={circleSize}
        height={circleSize}
        cx={circleSize / 2}
        cy={circleSize / 2}
        innerRadius={circleSize * 0.3}
        outerRadius={circleSize * 0.6}
        barSize={20}
        data={data}
        startAngle={90}
        endAngle={-270}
      >
        <PolarAngleAxis
          type="number"
          domain={[0, 100]}
          angleAxisId={0}
          tick={false}
        />
        <RadialBar
          background
          clockWise
          dataKey="value"
          cornerRadius={circleSize / 2}
          fill={fill}
        />
        <text
          x={circleSize / 2}
          y={circleSize / 2}
          textAnchor="middle"
          dominantBaseline="middle"
          className="progress-label"
          fontSize={35}
        >
          {data[0]?.score}/{data[0]?.max}
        </text>
      </RadialBarChart>
    </Stack>
  )
};

export default RadarGraph;
