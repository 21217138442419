import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StudentUpdateDetails from "../components/StudentUpdateDetails";
import TestRegistrations from "../components/TestRegistrations";
import TrainingRegistrations from "../components/TrainingRegistrations";
import StudentSittings from "../components/StudentSittings";

const StudentDetailManagement = () => {
  const { id } = useParams();

  return (
    <Box
    sx={{backgroundColor: "white"}}>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="student-details-1"
        >
          <Typography>Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StudentUpdateDetails />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="student-placements-1"
        >
          <Typography>Registered Tests</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TestRegistrations student_id={Number(id)} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="student-modules-1"
        >
          <Typography>Sittings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StudentSittings student_id={Number(id)} />
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id="student-enrolment-1"
        >
          <Typography>Enrolment</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TrainingRegistrations student_id={Number(id)} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default StudentDetailManagement;
