import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { format } from "date-fns";


const ProgressReport = () => {
  const history = useHistory();

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
            Progress Report
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default ProgressReport;
